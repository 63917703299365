/* eslint-disable max-lines*/
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ palette, typography, spacing, breakpoints }) => ({
  root: {
    position: 'relative',
    margin: 'auto',
    zIndex: 1,
    textAlign: 'center',
    '& .DateInput:focus-visible': {
      outline: 'transparent !important',
      border: 'none !important'
    },
    '& .DateInput_input__disabled': {
      fontStyle: 'normal'
    },
    '& .DateInput_input:focus-visible': {
      outline: 'transparent !important',
      border: 'none !important'
    },
    '& .CalendarDay__default': {
      background: 'transparent !important',
      color: `${palette.mode === 'light' ? palette.text.primary : palette.calendar.dateText} !important`
    },
    '& .CalendarDay__default:hover': {
      background: `${palette.secondary.light} !important`
    },
    '& .CalendarDay__hovered_span': {
      background: `${palette.secondary.light} !important`
    },
    '& .CalendarDay__hovered_span:hover': {
      background: `${palette.secondary.light} !important`
    },
    '& .CalendarDay__selected_span': {
      background: `${palette.secondary.light} !important`
    },
    '& .CalendarDay__selected_span:active': {
      background: `${palette.secondary.light} !important`
    },
    '& .CalendarDay__selected_span:hover': {
      background: `${palette.secondary.light} !important`
    },
    '& .CalendarDay__selected:hover': {
      background: `${palette.secondary.dark} !important`
    },
    '& .CalendarDay__selected': {
      color: `${(palette.calendar || palette.primary).contrastText} !important`,
      background: `${(palette.calendar || palette.primary).main} !important`,
      border: `1px double ${palette.common.white} !important`
    },
    '& .CalendarDay__blocked_calendar': {
      background: 'transparent !important',
      color: `${palette.text.secondary} !important`,
      border: 'none !important',
      fontWeight: 'normal !important'
    },
    '& .CalendarDay__blocked_out_of_range:hover': {
      background: 'transparent !important'
    },
    '& .CalendarDay__blocked_out_of_range': {
      background: 'transparent !important',
      color: `${palette.mode === 'light' ? palette.text.secondary : palette.calendar.blockedText} !important`,
      fontWeight: 'normal !important'
    },
    '& .DayPicker': {
      [breakpoints.down('sm')]: {
        position: 'absolute !important'
      }
    },
    '& .DayPicker__horizontal': {
      position: 'absolute !important'
    },
    '& .DateInput': {
      width: '90px'
    },
    '& .DateInput_input': {
      color: `${palette.mode === 'light' ? palette.text.primary : palette.calendar.dateText} !important`,
      fontSize: typography.body2.fontSize,
      fontWeight: 400,
      padding: spacing(0),
      borderBottom: 'none !important',
      textAlign: 'center !important',
      [breakpoints.down('sm')]: {
        padding: `${spacing(1.25)} ${spacing(0)}`,
        fontSize: '1.05rem'
      }
    },
    '& .DateInput_fang': {
      top: '50px !important',
      [breakpoints.down('sm')]: {
        top: '55px !important'
      }
    },
    '& .DateInput_input__focused': {
      borderBottom: `2px solid ${palette.primary.main} !important`,
      caretColor: 'transparent'
    },
    '& .DateRangePickerInput_calendarIcon': {
      margin: 0,
      padding: '5px 0px'
    },
    '& .DateRangePickerInput_clearDates': {
      margin: 0,
      padding: '10px 8px 12px 8px'
    },
    '& .DateRangePickerInput_clearDates_default:hover': {
      background: 'transparent'
    },
    '& .DayPicker_transitionContainer__vertical': {
      [breakpoints.down('sm')]: {
        minHeight: spacing(75)
      }
    },
    '& .DayPicker_transitionContainer__horizontal': {
      [breakpoints.down('sm')]: {
        minHeight: spacing(44)
      }
    },
    '& .DayPicker_weekHeader': {
      top: '50px',
      [breakpoints.down('sm')]: {
        top: spacing(7)
      }
    },
    '& .CalendarMonth_caption': {
      [breakpoints.down('sm')]: {
        paddingTop: `${spacing(2)} !important`,
        paddingBottom: `${spacing(4)} !important`
      }
    },
    '& .CalendarMonth_table': {
      [breakpoints.down('sm')]: {
        marginTop: spacing(1.5)
      }
    },
    '& .DayPickerNavigation__verticalDefault': {
      [breakpoints.down('sm')]: {
        width: '50%',
        height: spacing(4.5),
        margin: spacing(1)
      }
    },
    '& .DayPickerNavigation_svg__vertical': {
      [breakpoints.down('sm')]: {
        height: spacing(3),
        width: spacing(3)
      }
    },
    '& .DateRangePickerInput_arrow': {
      marginLeft: 0
    },
    '& .DayPickerKeyboardShortcuts_show__topRight': {
      borderRightColor: `${palette.secondary.main} !important`
    },
    '& .DayPickerKeyboardShortcuts_show__bottomRight::before': {
      borderTop: '33px solid transparent'
    },
    '@media (min-width:960px) and (max-width:1250px)': {
      '& .DateInput': {
        width: '75px'
      },
      '& .DateInput_input': {
        fontSize: typography.caption.fontSize,
        fontWeight: 500,
        padding: spacing(0)
      },
      '& .DateRangePickerInput_calendarIcon': {
        margin: 0,
        padding: '5px 0px',
        '& svg': {
          fontSize: spacing(2.5)
        }
      },
      '& .DateRangePickerInput_arrow': {
        marginLeft: 0
      },
      '& .DateRangePickerInput_arrow_svg': {
        height: spacing(2),
        width: spacing(2)
      },
      '& .DateRangePickerInput__showClearDates': {
        paddingRight: spacing(2)
      },
      '& .DateRangePickerInput_clearDates': {
        padding: '10px 5px 12px 5px'
      },
      '& .DateRangePickerInput_clearDates_svg': {
        height: spacing(1.25),
        width: spacing(1.25)
      }
    },
    '@media (min-width:1250px) and (max-width:1375px)': {
      '& .DateInput_input': {
        fontSize: typography.caption.fontSize,
        fontWeight: 500,
        padding: spacing(0)
      },
      '& .DateRangePickerInput_calendarIcon': {
        margin: 0,
        padding: '5px 0px',
        '& svg': {
          fontSize: spacing(2.5)
        }
      },
      '& .DateRangePickerInput_arrow': {
        marginLeft: 0
      },
      '& .DateRangePickerInput_arrow_svg': {
        height: spacing(2),
        width: spacing(2)
      },
      '& .DateRangePickerInput__showClearDates': {
        paddingRight: spacing(2)
      },
      '& .DateRangePickerInput_clearDates': {
        padding: '10px 10px 12px 10px'
      }
    }
  },
  fullScreenCalendarForMobile: {
    [breakpoints.down('sm')]: {
      '& .DayPickerNavigation__horizontal_2': {
        display: 'none'
      },
      '& .DayPicker_transitionContainer__horizontal_2': {
        width: 'unset !important'
      },
      '& .CalendarMonthGrid__horizontal_2': {
        display: 'flex',
        flexDirection: 'column',
        width: '100% !important',
        left: 'unset'
      },
      '& .CalendarMonthGrid_month__horizontal_1': {
        height: 305
      },
      '& .DayPicker_weekHeaders__horizontal': {
        margin: '0 !important'
      },
      '& .DayPicker_weekHeaders__horizontal_2': {
        '& div': {
          padding: '0 0 0 15px !important',
          width: '100%',
          left: '0 !important',

          '& li': {
            width: 'calc((100% - 16px) / 7) !important'
          }
        }
      },
      '& .DayPicker__withBorder_3': {
        width: '99% !important',
        boxShadow: 'unset',
        paddingBottom: '0 !important',

        '& > div > div': {
          width: 'unset !important'
        }
      },
      '& .CalendarMonth_table': {
        width: '100%'
      },
      '& .CalendarMonth_caption': {
        paddingTop: '25px !important'
      }
    }
  },
  hideEndDate: {
    '& .DateRangePickerInput': {
      '& .DateInput_1:nth-child(2)': {
        display: 'none !important'
      }
    },
    '& .DateRangePickerInput_arrow': {
      display: 'none'
    }
  },
  hideCalendarMonthChange: {
    '& .DayPickerNavigation_button__horizontalDefault': {
      display: 'none'
    }
  },
  infoButton: {
    padding: `${spacing(2)} ${spacing(4)}`,
    marginTop: `${spacing(-4)}`
  },
  infoDates: {
    padding: `${spacing(2)}`,
    marginTop: `${spacing(-2)}`
  },
  selectedDates: {
    color: 'black',
    fontSize: typography.body2.fontSize
  },
  selectedDatesCaption: {
    fontSize: typography.caption.fontSize
  },
  captionContainer: {
    [breakpoints.down('sm')]: {
      marginTop: spacing(-2)
    }
  },
  hideContainer: {
    display: 'none'
  }
});
