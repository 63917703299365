/* eslint-disable no-nested-ternary,max-lines */
import React, { Fragment, memo } from 'react';
// eslint-disable-next-line import/no-unresolved
// import { assetsBaseUrl } from 'ENV';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import getAssetsLink from '../../../lib/getAssetsLink';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { formatCurrency } from '../../../lib/currency';
import classNames from 'classnames';
import EmbedSocialReviews from '../EmbedSocialReviews';
import Button from '@mui/material/Button';
import { Text } from 'react-localize';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import CircleIcon from '@mui/icons-material/Lens';
import styles from './styles';
import { INCLUDED_CITIES_FOR_DISPLAY_PRICE_CAMPAIGN,
  INCLUDED_PORTS_FOR_DISPLAY_PRICE_CAMPAIGN,
  TOURS_FOR_CURRENTLY_VIEWING } from '../../../lib/shared/enums';

const BANNER_TYPE = {
  EXCLUSIVE_DEAL: 'exclusiveDeal',
  LIKELY_TO_SELL_OUT: 'likelyToSellOut',
  COUPON_SAVINGS: 'couponSavings',
  PROMO_CODE_ELIGIBLE: 'promoCodeEligible',
  RECENT_BOOKINGS: 'recentBookings',
  SAVE_PERCENTAGE: 'savePercentage',
  DOUBLE_REWARDS: 'rewardPoints2x',
  CURRENTLY_VIEWING: 'currentlyViewing'
};

const BANNER_LIST = {
  [BANNER_TYPE.EXCLUSIVE_DEAL]: {
    className: 'product-exclusive',
    label: 'exclusiveDeal',
    backgroundColor: '#177998',
    color: '#fff'
  },
  [BANNER_TYPE.LIKELY_TO_SELL_OUT]: {
    className: 'product-sellout',
    label: 'likelyToSellOut',
    backgroundColor: '#d1372f',
    color: '#fff'
  },
  [BANNER_TYPE.COUPON_SAVINGS]: {
    className: 'product-coupon',
    label: 'promoCodeEligible',
    backgroundColor: '#177998',
    color: '#fff'
  },
  [BANNER_TYPE.DOUBLE_REWARDS]: {
    label: 'rewardPoints2x',
    backgroundColor: '#5F42B7',
    color: '#fff'
  },
  [BANNER_TYPE.PROMO_CODE_ELIGIBLE]: {
    label: 'promoCodeEligible',
    backgroundColor: '#5F42B7',
    color: '#fff'
  },
  [BANNER_TYPE.SAVE_PERCENTAGE]: {
    className: 'product-package',
    label: 'savePercentage',
    backgroundColor: 'rgb(126, 189, 50, 0.5)',
    color: '#253d09',
    iconUrl: '/images/save-percentage-icon.png',
    borderColor: 'rgb(126, 189, 50, 1.0)'
  },
  [BANNER_TYPE.RECENT_BOOKINGS]: {
    label: 'noOfRecentBookings',
    backgroundColor: 'rgb(255, 190, 46, 0.5)',
    color: '#714e00',
    IconComponent: FlashOnIcon,
    borderColor: 'rgb(255, 190, 46, 1.0)'
  },
  [BANNER_TYPE.CURRENTLY_VIEWING]: {
    label: 'currentlyViewing',
    backgroundColor: '#dee3f2',
    color: '#000000'
  }
};

const getPermalink = ({
  crossSellPermalinks, siteId, _permalink, chatkey, allowFilterDatesWithPermalink, startDate, endDate, currentLocale,
  isAdTile, adTileLink }) => {
  if (isAdTile) {
    return adTileLink;
  }
  let basePermalink = (crossSellPermalinks || {})[siteId] || _permalink;
  if (currentLocale && currentLocale !== 'en') {
    basePermalink = basePermalink.replace('.com', `.com/${currentLocale}`);
  }
  const queryParams = [];

  if (!basePermalink) {
    return '';
  }

  if (chatkey) {
    queryParams.push(`chatkey=${chatkey}`);
  }
  if (allowFilterDatesWithPermalink && startDate) {
    queryParams.push(`date=${startDate}`);
  }
  if (allowFilterDatesWithPermalink && startDate && endDate) {
    queryParams.push(`endDate=${endDate}`);
  }

  const queryString = queryParams.join('&');
  const hasQuerySeparator = basePermalink.includes('?');

  return `${basePermalink}${(!hasQuerySeparator && queryString) ? '?' : ''}${queryString}`;
};

const CITY_CRUISES = 'City Cruises';

const ResultItem = ({
  classes,
  images,
  tiles,
  title,
  ctaButtonContent,
  tileTitle,
  objectID,
  displayPrice,
  defaultCurrency,
  permalink: _permalink,
  city,
  displayLabel,
  crossSellPermalinks,
  siteId,
  tbdPrice,
  tealiumTrack,
  numberOfRecentBookings,
  availabilityPct,
  recentBookingsThreshold,
  sellOutThreshold,
  discountedPrice,
  tourCurrencies,
  propBookingType,
  showPriceWithFees: _showPriceWithFees,
  discountedPriceWithFees,
  displayPriceWithFees,
  isMobileView,
  __queryID,
  __position,
  searchIndex,
  sendAlgoliaEvent,
  setAlgoliaSearchInfo,
  searchExperienceV3IsEnabled,
  embedReviewId,
  containsSpecialCoupon,
  durationTiming,
  webCategoriesMain,
  exclusiveDeal,
  upToSavePercentage,
  toShowPriceCents,
  chatkey,
  containsDoubleRewards,
  tagDescription,
  tagColor,
  overrideDefaultTileTag,
  specialCouponBannerText,
  tagTextColor,
  enableDarkThemeTiles: enableDarkTheme,
  enableMobileTileVariant2,
  enableMobileTileVariant3,
  isXsView,
  exclusiveDealBannerText,
  cityForNoResult,
  hasSearchTerm,
  noInventoryFlag,
  searchWithQueryAndRange,
  hasSearchResults,
  showNoResult,
  shouldShowFeeText,
  justFeePercentage,
  isPriceInclusiveOfFees,
  allowFilterDatesWithPermalink,
  startDate,
  endDate,
  showCurrentlyViewing,
  currentLocale,
  localeTitles,
  localeTileTitles,
  isAdTile,
  adTileTitle,
  adTileSubtext,
  adTileMobileText,
  adTileButtonCtaText,
  adTileImage,
  adTileLink,
  bookingTypeId,
  propertyId,
  brandTag,
  isFeaturedTile,
  isDisplayPriceWithFeesCampaignActive,
  itemIdx
}, { localize }) => {
  const assetsBaseUrl = process.env.ASSETS_BASE_URL;
  const currency = (tourCurrencies || {})[propBookingType] || defaultCurrency;
  let label = Array.isArray(displayLabel) ? displayLabel[0] : '';
  const img = isAdTile ? getAssetsLink(adTileImage) :
    ((Array.isArray(tiles) && tiles[0] && getAssetsLink(tiles[0])) ||
          (Array.isArray(images) && images[0] && getAssetsLink(images[0])));
  const permalink = getPermalink({
    crossSellPermalinks,
    siteId,
    _permalink,
    chatkey,
    allowFilterDatesWithPermalink,
    startDate,
    endDate,
    currentLocale,
    isAdTile,
    adTileLink
  });

  let showAllPriceForCampaign = isDisplayPriceWithFeesCampaignActive
    && (INCLUDED_PORTS_FOR_DISPLAY_PRICE_CAMPAIGN || []).some(url => (permalink || '').match(url));

  if (!showAllPriceForCampaign) {
    showAllPriceForCampaign = isDisplayPriceWithFeesCampaignActive
    && (brandTag || '').includes(CITY_CRUISES) && INCLUDED_CITIES_FOR_DISPLAY_PRICE_CAMPAIGN.includes(city);
  }

  const showPriceWithFees = showAllPriceForCampaign || _showPriceWithFees;

  const priceRounded = toShowPriceCents ? (showPriceWithFees ? (displayPriceWithFees || displayPrice) : displayPrice)
    : Math.ceil(showPriceWithFees ? (displayPriceWithFees || displayPrice) : displayPrice);
  const hidePrice = !tbdPrice && !priceRounded;
  const totalDiscountedPrice = showPriceWithFees ? (discountedPriceWithFees || discountedPrice) : discountedPrice;
  const isDiscounted = totalDiscountedPrice > 0;
  if (label === 'saveNow' && !isDiscounted) {
    label = displayLabel[1] || '';
  }
  const shouldHideCurrency = !['CAD', 'AUD'].includes(currency) && searchExperienceV3IsEnabled;
  const finalPrice = tbdPrice ?
    tbdPrice
    : formatCurrency(
      priceRounded,
      {
        currency,
        precision: toShowPriceCents ? undefined : 0,
        forceShowPrefix: !searchExperienceV3IsEnabled,
        forceHidePrefix: shouldHideCurrency
      }
    );
  const finalDiscountedPrice = formatCurrency(
    Math.ceil(totalDiscountedPrice),
    {
      currency,
      precision: toShowPriceCents ? undefined : 0,
      forceHidePrefix: true
    }
  );
  const likelyToSellOut = !!availabilityPct
      && !!sellOutThreshold
      && !isNaN(availabilityPct)
      && !isNaN(sellOutThreshold)
      && availabilityPct <= sellOutThreshold;
  const sellingFast = !likelyToSellOut
      && !!numberOfRecentBookings
      && !!recentBookingsThreshold
      && !isNaN(numberOfRecentBookings)
      && !isNaN(recentBookingsThreshold)
      && numberOfRecentBookings >= recentBookingsThreshold;

  const renderTitle = (localeTileTitles || {})[currentLocale] ||
      (localeTitles || {})[currentLocale] || tileTitle || title || objectID;

  const consistentTitle = (localeTileTitles || {}).en ||
  (localeTitles || {}).en || tileTitle || title || objectID;

  const onClickCard = () => {
    if (searchIndex && __queryID && __position && objectID) {
      setAlgoliaSearchInfo({ index: searchIndex, queryID: __queryID, position: __position, objectID });
      sendAlgoliaEvent({
        eventName: 'Product Clicked',
        index: searchIndex,
        queryID: __queryID,
        objectID,
        position: __position,
        eventType: 'clickedObjectIDsAfterSearch'
      });
    }
    if (label === 'ad') {
      tealiumTrack({
        name: 'search',
        category: 'search',
        action: 'search_product',
        label: `${ctaButtonContent} + Ad`
      });
    } else if (label !== 'ad') {
      const { id: bannerId } = (AVAILABLE_BANNER_CONFIG || []).find(({ active }) => !!active) || {};
      let bannerLabel = '';
      if (bannerId) {
        bannerLabel = (BANNER_LIST[bannerId] || {}).label;
      }
      const eventAction = (!cityForNoResult || (noInventoryFlag && cityForNoResult)) ? 'search_trending_near_you' :
        (hasSearchTerm && !noInventoryFlag && !searchWithQueryAndRange ?
          'search_trending_in_city' : 'search_flexible_dates');
      tealiumTrack({
        name: 'search',
        category: 'search',
        action: (!hasSearchResults || showNoResult) ? eventAction : 'search_product',
        label: `${renderTitle} ${city}${
          label ? ` + ${localize(`searchResultLabels.${label}`)}` : ''}${
          bannerLabel ? ` + ${localize(bannerLabel)}` : ''}${
          likelyToSellOut ? ` + ${localize('likelyToSellOut')}` : ''}${
          sellingFast ? ` + ${localize('noOfRecentBookings', [numberOfRecentBookings])}` : ''}`,
        event_value: tbdPrice || priceRounded,
        currency
      });
    }
  };

  const AVAILABLE_BANNER_CONFIG = [
    {
      id: BANNER_TYPE.PROMO_CODE_ELIGIBLE,
      active: containsSpecialCoupon
    },
    {
      id: BANNER_TYPE.EXCLUSIVE_DEAL,
      active: exclusiveDeal
    },
    {
      id: BANNER_TYPE.LIKELY_TO_SELL_OUT,
      active: likelyToSellOut
    },
    {
      id: BANNER_TYPE.DOUBLE_REWARDS,
      active: containsDoubleRewards
    },
    {
      id: BANNER_TYPE.CURRENTLY_VIEWING,
      active: (TOURS_FOR_CURRENTLY_VIEWING || []).includes(propBookingType) && showCurrentlyViewing
    }
  ];

  const getTypographyColor = ({ mainWebCategory = false } = {}) => {
    if (enableDarkTheme) {
      return 'white';
    }
    if (mainWebCategory) {
      return 'textSecondary';
    }
    return 'textPrimary';
  };

  const getBannerTitle = ({ bannerId, bannerLabel, allowOverwriteDefaults }) => {
    if (overrideDefaultTileTag && tagDescription && allowOverwriteDefaults) {
      return tagDescription;
    }
    if (bannerId === BANNER_TYPE.CURRENTLY_VIEWING) {
      return localize(bannerLabel);
    }
    if (bannerId === BANNER_TYPE.EXCLUSIVE_DEAL) {
      return exclusiveDealBannerText || localize(bannerLabel);
    }
    if (bannerId === BANNER_TYPE.LIKELY_TO_SELL_OUT) {
      return localize(bannerLabel);
    }
    if (bannerId === BANNER_TYPE.COUPON_SAVINGS || bannerId === BANNER_TYPE.PROMO_CODE_ELIGIBLE) {
      return localize(bannerLabel);
    }
    if (bannerId === BANNER_TYPE.SAVE_PERCENTAGE) {
      return localize(bannerLabel, [upToSavePercentage]);
    }
    if (bannerId === BANNER_TYPE.RECENT_BOOKINGS) {
      return localize(bannerLabel, [numberOfRecentBookings]);
    }
    if (bannerId === BANNER_TYPE.DOUBLE_REWARDS) {
      return localize(bannerLabel);
    }
    return null;
  };

  const renderedBanners = new Set();
  const renderBanner = ({ fontSize } = {}) => {
    if (renderedBanners.size >= 2) {
      return null;
    }
    const { id: bannerId } = (AVAILABLE_BANNER_CONFIG || []).find(({ active, id }) =>
      !!active && !renderedBanners.has(id)) || {};

    if (!bannerId) {
      return null;
    }

    const allowOverwriteDefaults = bannerId === BANNER_TYPE.PROMO_CODE_ELIGIBLE;
    const notFirstBanner = renderedBanners.size > 0;

    const {
      label: bannerLabel,
      IconComponent,
      iconUrl,
      backgroundColor,
      borderColor,
      color,
      transform,
      className
    } = BANNER_LIST[bannerId] || {};

    const getBannerTextColor = () => (
      (tagTextColor && allowOverwriteDefaults) ? tagTextColor : color);

    const getBannerBackground = () => ({
      backgroundColor: (overrideDefaultTileTag && allowOverwriteDefaults) ? tagColor : backgroundColor
    });

    renderedBanners.add(bannerId);

    return (
      <div className={classNames({
        [classes.notFirstBanner]: notFirstBanner,
        [classes.tagContainer]: true
      })}>
        <div className={classNames({
          [classes.bannerBox]: true,
          [className]: !!className && !overrideDefaultTileTag
        })} style={{
          ...getBannerBackground(),
          ...borderColor && { border: `1px solid ${borderColor}` }
        }}>
          {IconComponent && <IconComponent fontSize={'small'} />}
          {iconUrl && <img alt={'banner-icon'} src={`${assetsBaseUrl}${iconUrl}`} style={{ transform }}
            className={classes.bannerIcon} />}
          <Typography variant={'caption'} noWrap
            style={{ color: getBannerTextColor(),
              ...(fontSize && { fontSize }) }}>
            {getBannerTitle({ bannerId, bannerLabel, allowOverwriteDefaults })}
          </Typography>
        </div>
      </div>
    );
  };

  const renderMainWebCategory = () => {
    if (!webCategoriesMain) {
      return null;
    }

    return <div className={classes.subCategoryContainer}>
      <div className={classes.subCategory}>
        {!enableDarkTheme ?
          <CircleIcon/> :
          <CircleIcon style={{ color: 'white' }}/>
        }
        <Typography className={'mainWebCategoryTile'} variant={'caption'}
          color={getTypographyColor({ mainWebCategory: true })} noWrap>
          {webCategoriesMain}
        </Typography>
      </div>
    </div>;
  };

  const renderDurationTiming = (
    <div className={classes.timingBox}>
      {!enableDarkTheme ?
        <ScheduleIcon fontSize={'small'} /> :
        <ScheduleIcon fontSize={'small'} style={{ color: 'white' }} />
      }
      <Typography variant={'caption'} color={getTypographyColor()} noWrap>
        {durationTiming}
      </Typography>
    </div>
  );

  const renderIncludedFeesText = ({ marginTop = 0 } = {}) => {
    const feeToUse = Math.round(justFeePercentage);

    if (!isPriceInclusiveOfFees && (!shouldShowFeeText || !feeToUse) && !showAllPriceForCampaign) {
      return null;
    }
    const textToUse = showAllPriceForCampaign ? 'feesIncInPrice' :
      isPriceInclusiveOfFees ? 'feesIncludedTxt' : 'feesAddedTxt';
    return <div className={classes.taxIncludedBox} style={{ marginTop: `${marginTop}px` }}>
      <Typography
        variant="caption"
        className={classNames(classes.taxIncludedText, { 'text-large': shouldShowFeeText })}
        color="textSecondary"> <Text
          message={textToUse}
          {...shouldShowFeeText && !showAllPriceForCampaign && { values: [Math.round(justFeePercentage)] }}
        /> </Typography>
    </div>;
  };

  const renderPrice = ({ variant2 = false } = {}) => (
    <div className={classes.priceBox}>
      <Typography variant={'caption'} color={getTypographyColor()}
        inline={'true'} className={
          isDiscounted ?
            classNames({
              [classes.discountedFromV2]: !enableDarkTheme,
              [classes.discountedFromV2Dark]: enableDarkTheme
            }) : classes.fromV2}
        style={variant2 ? { fontSize: '10px' } : {}}
      >
        {localize('from')}
      </Typography>
      {tbdPrice ? (
        <Fragment>
          {isDiscounted && (
            <Typography variant={'inherit'} color={getTypographyColor()} inline={'true'}
              className={classNames(
                'discountPrice',
                !enableDarkTheme ? classes.discountV2 : classes.discountV2Dark)}
              style={variant2 ? { fontSize: '16px' } : {}}
            >
              {finalDiscountedPrice}
            </Typography>
          )}
          <Typography
            variant={'inherit'}
            color={'textPrimary'}
            inline={'true'}
            className={!enableDarkTheme ?
              classes.priceV2 : classes.priceV2Dark}
            style={variant2 ? { fontSize: '14px' } : {}}>
            {finalPrice}
          </Typography>
        </Fragment>
      ) : <Fragment>
        {!shouldHideCurrency &&
              <Typography variant={'inherit'} color={'textPrimary'} inline={'true'}
                className={isDiscounted ?
                  classNames({ [classes.discountedPrefixV2]: !enableDarkTheme,
                    [classes.discountedPrefixV2Dark]: enableDarkTheme }) :
                  classNames({ [classes.priceV2]: !enableDarkTheme,
                    [classes.priceV2Dark]: enableDarkTheme })}
                style={variant2 ? { fontSize: '14px' } : {}}
              >
                {finalPrice.slice(0, 2)}
              </Typography>
        }
        {isDiscounted && (
          <Typography variant={'inherit'} inline={'true'} className={classNames('discountPrice', !enableDarkTheme ?
            classes.discountV2 : classes.discountV2Dark)}
          style={variant2 ? { fontSize: '16px' } : {}}
          >
            {finalDiscountedPrice}
          </Typography>
        )}
        <Typography variant={'inherit'} color={'textPrimary'} inline={'true'}
          className={isDiscounted ?
            classNames({ [classes.discountedV2]: !enableDarkTheme, [classes.discountedV2Dark]: enableDarkTheme }) :
            classNames({ [classes.priceV2]: !enableDarkTheme, [classes.priceV2Dark]: enableDarkTheme })
          }
          style={variant2 ? { fontSize: '14px' } : {}}
        >
          {shouldHideCurrency ? finalPrice : finalPrice.slice(4)}
        </Typography>
      </Fragment>
      }
    </div>
  );

  const renderVariant3Price = ({ variant2 = true, fromOnTop = true } = {}) => (
    <div className={classNames(classes.priceBox, {
      ['fromOnTop']: fromOnTop
    })}>
      {!isDiscounted && <Typography variant={'caption'} color={getTypographyColor()}
        inline={'true'} className={
          isDiscounted ?
            classNames({
              [classes.discountedFromV2]: !enableDarkTheme,
              [classes.discountedFromV2Dark]: enableDarkTheme
            }) : classes.fromV2}
        style={variant2 ? { fontSize: '10px' } : {}}
      >
        {localize('from')}
      </Typography>}
      {tbdPrice ? (
        <Fragment>
          <Typography
            variant={'inherit'}
            color={'textPrimary'}
            inline={'true'}
            className={classNames(
              {
                [classes.priceV2]: !enableDarkTheme,
                [classes.priceV2Dark]: enableDarkTheme,
                ['fromOnTop']: fromOnTop
              })}
            style={variant2 ? { fontSize: '14px' } : {}}>
            {finalPrice}
          </Typography>
          {isDiscounted && (
            <Typography variant={'inherit'} color={getTypographyColor()} inline={'true'}
              className={classNames(
                'discountPrice',
                !enableDarkTheme ? classes.discountV2 : classes.discountV2Dark)}
              style={variant2 ? { fontSize: '16px' } : {}}
            >
              {finalDiscountedPrice}
            </Typography>
          )}
        </Fragment>
      ) : <Fragment>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          {!shouldHideCurrency &&
                <Typography variant={'inherit'} color={'textPrimary'} inline={'true'}
                  className={isDiscounted ?
                    classNames({ [classes.discountedPrefixV2]: !enableDarkTheme,
                      [classes.discountedPrefixV2Dark]: enableDarkTheme }, 'variant3') :
                    classNames({ [classes.priceV2]: !enableDarkTheme,
                      [classes.priceV2Dark]: enableDarkTheme,
                      ['fromOnTop']: fromOnTop }, 'variant3')}
                  style={variant2 ? { fontSize: '14px', marginRight: '3px' } : {}}
                >
                  {`${finalPrice.slice(0, 2)} `}
                </Typography>
          }
          <Typography variant={'inherit'} color={'textPrimary'} inline={'true'}
            className={isDiscounted ?
              classNames({ [classes.discountedV2]: !enableDarkTheme,
                [classes.discountedV2Dark]: enableDarkTheme }, 'variant3') :
              classNames({ [classes.priceV2]: !enableDarkTheme, [classes.priceV2Dark]: enableDarkTheme,
                ['fromOnTop']: fromOnTop }, 'variant3')
            }
            style={variant2 && isDiscounted ? { fontSize: '12px' } : { fontSize: '14px' }}
          >
            {shouldHideCurrency ? finalPrice : finalPrice.slice(4)}
          </Typography>
        </div>
        {isDiscounted && (
          <Typography variant={'inherit'} inline={'true'} className={classNames('discountPrice', !enableDarkTheme ?
            classes.discountV2 : classes.discountV2Dark)}
          style={variant2 ? { fontSize: '14px' } : {}}
          >
            <span className={classes.fromVariant3}>from</span> {finalDiscountedPrice}
          </Typography>
        )}
      </Fragment>
      }
    </div>
  );

  const mobileAdTile = () => <Fragment>
    <div className={classes.mobileAdTile}>
      <img alt={renderTitle} src={img} className={classes.mobileAdImage}/>
      <Grid container
        direction="column"
        wrap={'nowrap'}
        spacing={4}
        alignItems={'center'}
        marginTop={'0px'}
        justifyContent={'space-between'}>
        <Grid item>
          <Typography variant={'h6'}
            className={classNames(classes.adTileMobileText)}
            gutterBottom>
            {adTileMobileText}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            className={classNames(classes.adTileMobileLinkButton)}>
            <Text message={adTileButtonCtaText}/>
          </Button>
        </Grid>
      </Grid>
    </div>
  </Fragment>
;

  const primaryAdTile = () =>
    <Fragment>
      <div className={classes.mediaV2}>
        <img alt={renderTitle} src={img}/>
      </div>
      <CardContent className={!enableDarkTheme ? classes.content : classes.contentDark}>
        <Grid container direction={'column'} justifyContent={'flex-end'}>
          <Grid item>
            <Typography variant={'h6'}
              gutterBottom className={classNames(classes.adTileTitle)}>
              {adTileTitle}
            </Typography>
            <Typography variant={'body2'} color={getTypographyColor()}
              className={classNames(classes.adTileSubtext)}>
              {adTileSubtext}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              className={classNames(classes.adTileDesktopLinkButton)}>
              <Text message={adTileButtonCtaText}/>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>;

  const renderImageBanner = alignLeft => {
    const tagColorToUse = isFeaturedTile ? '#FFC055' : tagColor;
    const tagTextColorToUse = isFeaturedTile ? 'black' : tagTextColor;
    const bannerText = isFeaturedTile ? 'Hot' : specialCouponBannerText;
    return <div
      className={classNames(classes.promoText, { alignLeft })}
      style={{
        backgroundColor: tagColorToUse,
        ...tagTextColor && { color: tagTextColorToUse }
      }}>
      {bannerText}
    </div>;
  };

  const renderAdTile = () => (isXsView ? mobileAdTile() : primaryAdTile());

  const primaryTile = () => (<Fragment>
    <div className={classes.mediaV2}>
      {!!(specialCouponBannerText || isFeaturedTile) && renderImageBanner()}
      <img alt={renderTitle} src={img}/>
    </div>
    <CardContent className={!enableDarkTheme ? classes.content : classes.contentDark}>
      <Typography variant={'h6'} color={getTypographyColor()}
        gutterBottom className={classNames('tileTitleStyling',
          classes.eventNameV2)}>
        {renderTitle}
      </Typography>
      <Typography variant={'body2'} color={getTypographyColor()}
        noWrap className={classNames('cityResultTile',
          classes.locationV2)}>
        {city}
        {renderMainWebCategory()}
      </Typography>
      <div className={classes.bannerPriceBox}>
        <Grid
          wrap={'nowrap'}
          container
          spacing={0}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid item>
            <div className={classes.bannersContainer}>
              {renderBanner()}
              {renderBanner()}
            </div>
          </Grid>
          <Grid item>
            {!hidePrice && embedReviewId && renderPrice()}
          </Grid>
        </Grid>
      </div>
      <div className={classes.iconBoxV3}>
        <Grid
          wrap={'nowrap'}
          container
          spacing={0}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid item>
            {durationTiming && renderDurationTiming}
          </Grid>
          {embedReviewId &&
              <Grid item className={classes.embedSocialReview}>
                <EmbedSocialReviews dataRef={embedReviewId}/>
              </Grid>
          }
          {!hidePrice && !embedReviewId &&
              <Grid item className={classes.priceAndFeesTextBox}>
                {renderPrice()}
                {renderIncludedFeesText({})}
              </Grid>
          }
        </Grid>
      </div>
    </CardContent>
  </Fragment>);

  const mobileTileVariant3 = () => (<Fragment>
    <div className={classNames(classes.mediaVariant2, 'variant3')}>
      {isFeaturedTile && renderImageBanner()}
      <img alt={renderTitle} src={img}/>
    </div>
    <CardContent className={
      classNames('variant3', {
        [classes.content]: !enableDarkTheme,
        [classes.contentDark]: enableDarkTheme
      })}>
      <div className={classes.variant3Content}>
        <Typography variant={'h6'} color={getTypographyColor()}
          gutterBottom className={
            classNames('tileTitleStyling',
              classes.eventNameV2,
              'variant3')}>
          {renderTitle}
        </Typography>
        <div className={classNames(classes.variant2LocationCategory, 'variant3')}>
          <Typography
            variant={'body2'}
            color={getTypographyColor()}
            noWrap
            className={classNames(
              'cityResultTile',
              classes.locationVariant2,
              'variant3')}>
            {city}
          </Typography>
          <div className={classNames(classes.variant2Category, 'variant3')}>
            {!!webCategoriesMain && <CircleIcon/>}
            <Typography
              className={classNames(
                'mainWebCategoryTile',
                classes.webCategoriesVariant2
              )}
              variant={'body1'}
              color={'textSecondary'}
              noWrap>
              {webCategoriesMain}
            </Typography>
          </div>
        </div>
      </div>
      {renderBanner({ fontSize: 10 })}
      <Grid container>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'flex-end'}
        className={classes.priceDurationVariant3}>
        <Grid item id={`${objectID}-duration`} style={{ minWidth: 0 }}>
          <div className={classes.variant2Timing}>
            {!!durationTiming && <ScheduleIcon fontSize={'small'} style={{ marginRight: '2px', fontSize: '14px' }} />}
            {!!durationTiming && <Typography
              variant={'body1'} inline={false} noWrap={true} style={{ fontSize: '10px' }}>
              {!!durationTiming && durationTiming}
            </Typography>}
          </div>
        </Grid>
        {!hidePrice &&
            <Grid item id={`${objectID}-price`}>
              {renderVariant3Price({ variant2: true })}
              {renderIncludedFeesText({})}
            </Grid>
        }
      </Grid>
    </CardContent>
  </Fragment>);

  const mobileTileVariant2 = () => (<Fragment>

    <Grid container>
      <Grid item xs={5}>
        <div className={classes.mediaVariant2}>
          {isFeaturedTile && renderImageBanner(true)}
          <div className={classNames(classes.promoText, 'variant2')}>
            {/* {renderBanner({ fontSize: 10 })} */}
          </div>
          <img alt={renderTitle} src={img}/>
        </div>
      </Grid>
      <Grid item xs={7}>
        <CardContent
          className={!enableDarkTheme ? classes.variant2Content : classes.contentDark}
          style={{ paddingBottom: '8px' }}>
          <Typography variant={'h6'} color={getTypographyColor()}
            gutterBottom className={classNames(
              'tileTitleStyling',
              classes.variant2EventName)}>
            {renderTitle}
          </Typography>
          <div className={classes.variant2MainSection}>
            <div className={classes.variant2LocationCategory}>
              <Typography
                variant={'body2'}
                color={getTypographyColor()}
                noWrap
                className={classNames(
                  'cityResultTile',
                  classes.locationVariant2)}>
                {city}
              </Typography>
              <div className={classes.variant2Category}>
                {!!webCategoriesMain && <CircleIcon/>}
                <Typography
                  className={classNames(
                    'mainWebCategoryTile',
                    classes.webCategoriesVariant2
                  )}
                  variant={'body1'} noWrap>
                  {webCategoriesMain}
                </Typography>
              </div>
            </div>
            {renderBanner({ fontSize: 10 })}
          </div>
          <Grid container justifyContent={'space-between'} alignItems={'flex-end'}
            className={classes.priceDurationVariant3}>
            <Grid item id={`${objectID}-duration`} style={{ minWidth: 0 }}>
              <div className={classes.variant2Timing}>
                {!!durationTiming &&
                    <ScheduleIcon fontSize={'small'} style={{ marginRight: '2px', fontSize: '14px' }} />}
                {!!durationTiming && <Typography
                  variant={'body1'} inline={false} noWrap={true} style={{ fontSize: '10px' }}>
                  {!!durationTiming && durationTiming}
                </Typography>}
              </div>
            </Grid>
            {!hidePrice &&
                <Grid item id={`${objectID}-price`}>
                  {renderVariant3Price({ variant2: true })}
                </Grid>
            }
          </Grid>
          {renderIncludedFeesText({ marginTop: 8 })}
        </CardContent>
      </Grid>
    </Grid>
  </Fragment>);

  const renderTile = () => {
    if (enableMobileTileVariant2 && isXsView) {
      return mobileTileVariant2();
    } else if (enableMobileTileVariant3 && isXsView) {
      return mobileTileVariant3();
    }
    return primaryTile();
  };

  return (
    <Grid
      item
      xs={enableMobileTileVariant3 ? 6 : 12}
      sm={6}
      md={6}
      lg={isAdTile ? 8 : 4}
      xl={isAdTile ? 6 : 3}
      className={classNames({
        [classes.firstMobileResult]: itemIdx === 0,
        ['isAdTile']: isAdTile,
        tileGridResult: true
      })}
    >
      <Card className={classNames(
        classes.cardV2,
        'cardV2Inline',
        { [classes.cardMobile]: !label && isMobileView,
          ['variant2']: ((enableMobileTileVariant2 || enableMobileTileVariant3) && isXsView),
          ['variant3']: enableMobileTileVariant3 && isXsView,
          [classes.featuredTile]: isFeaturedTile }
      )}
      aria-label={consistentTitle}
      id={isAdTile ? 'product-double-tile' : `${propertyId}-${bookingTypeId}`}>
        <CardActionArea
          href={permalink}
          classes={{ root: classes.actionAreaV2, focusHighlight: classes.focusHighlight }}
          title={renderTitle}
          onClick={onClickCard}
        >
          {isAdTile ? renderAdTile() : renderTile() }
        </CardActionArea>
      </Card>
    </Grid>
  );
};

ResultItem.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default memo(withStyles(styles)(ResultItem));
