/* eslint-disable max-lines,max-len */
export default ({ palette, shadows }) => ({
  '.DefaultProgressBar__vertical': {
    width: 24,
    height: '100%'
  },
  '.DefaultProgressBar_progressBar': {
    backgroundColor: palette.primary.main,
    position: 'absolute'
  },
  '.DefaultProgressBar_progressBar__vertical': {
    height: '100%',
    width: 24
  },
  '.DefaultProgressBar_background__vertical': {
    height: '100%',
    top: 0,
    width: 15
  },
  '.DefaultProgressBar_background__horizontal': {
    height: 13,
    top: 0
  },
  '.DefaultHandle_handle': {
    width: 24,
    height: 24,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.primary.main,
    backgroundColor: palette.background.paper,
    borderRadius: '50%',
    outline: 'none',
    zIndex: 2,
    boxShadow: shadows[2]
  },
  '.DefaultHandle_handle:focus': {
    boxShadow: shadows[4]
  },
  '.DefaultHandle_handle:after': {
    content: '',
    display: 'block',
    position: 'absolute',
    backgroundColor: '#dadfe8'
  },
  '.DefaultHandle_handle:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    backgroundColor: ' #dadfe8'
  },
  '.DefaultHandle_handle__horizontal': {
    marginLeft: -12,
    top: -5
  },
  '.DefaultHandle_handle__horizontal:before': {
    top: 7,
    height: 10,
    width: 1,
    left: 10
  },
  '.DefaultHandle_handle__horizontal:after': {
    top: 7,
    height: 10,
    width: 1,
    left: 13
  },
  '.DefaultHandle_handle__vertical': {
    marginTop: -12,
    left: -10
  },
  '.DefaultHandle_handle__vertical:before': {
    top: 10
  },
  '.DefaultHandle_handle__vertical:after': {
    top: 13,
    left: 8,
    height: 1,
    width: 10
  },
  '.DefaultHandle_handle__disabled': {
    borderColor: '#dbdbdb'
  },
  '.DefaultBackground': {
    backgroundColor: palette.divider,
    height: 15,
    width: '100%',
    border: '1 solid #d8d8d8',
    position: 'relative'
  },
  '.DefaultBackground_background__horizontal': {
    height: 15,
    top: -2,
    left: -2,
    bottom: 4,
    width: '100%'
  },
  '.DefaultBackground_background__vertical': {
    width: 15,
    top: 0,
    height: '100%'
  },
  '.rheostat': {
    position: 'relative',
    overflow: 'visible'
  },
  '@media (min-width: 1128)': {
    '.autoAdjustVerticalPosition': {
      top: 12
    }
  },
  '.rheostat__vertical': {
    height: '100%'
  },
  '.handleContainer': {
    height: 15,
    top: -2,
    left: -2,
    bottom: 4,
    width: '100%',
    position: 'absolute'
  },
  '.rheostat_background': {
    backgroundColor: '#fcfcfc',
    border: '1 solid #d8d8d8',
    position: 'relative'
  },
  '.rheostat_background__horizontal': {
    height: 15,
    top: -2,
    left: -2,
    bottom: 4,
    width: '100%'
  },
  '.rheostat_background__vertical': {
    width: 15,
    top: 0,
    height: '100%'
  }
});
