// hello_algolia.js
const algoliasearch = require('algoliasearch');
const algolia = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_API_KEY
};

const client = algoliasearch(algolia.appId, algolia.apiKey);

const search = async({ searchState, index: searchIndex } = {}) => {
  const index = client.initIndex(searchIndex);
  const {
    showSpecialOffers,
    refinementList,
    query = ''
  } = searchState || {};
  const res = await index
    .search(query, {
      filters: [
        'privateEvent:false',
        'displayPrice > 0',
        'hideInWebsiteSearch:false',
        'displayIcon:owned',
        'NOT displayIcon:partner',
        'hasNoAvailableDates:true',
        showSpecialOffers && '(containsSpecialCoupon:true OR categoryTheme:"Special Offers")',
        ...((Object.keys((refinementList || {})) || []).map(key => (
          Array.isArray((refinementList || {})[key]) && (refinementList || {})[key].length ?
            `(${(refinementList || {})[key].map(item => `${key}:"${item}"`)
              .join(' OR ')})`
            : undefined)
        ))
      ].filter(val => val)
        .join(' AND ')
    });
  return res;
};

export default search;
