/* eslint-disable no-nested-ternary,max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { Text } from 'react-localize';
import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { connectRefinementList } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import stringSort from '../../lib/stringSort';
import classNames from 'classnames';
import DividerWithSpacing from '../DividerWithSpacing';
import { HIDE_CATEGORY_FOR_WEB } from '../../lib/shared/enums';

const styles = ({ palette, spacing }) => ({
  badge: {
    top: '50%',
    right: -16,
    backgroundColor: palette.mode === 'light' ? palette.grey[200] : palette.grey[100],
    zIndex: 0
  },
  badgeV2: {
    top: '50%',
    right: -20,
    backgroundColor: palette.mode === 'light' ? palette.grey[200] : palette.grey[100],
    zIndex: 0,
    fontSize: '0.7rem',
    color: palette.grey[600],
    minWidth: spacing(2.75),
    height: spacing(2.75),
    border: `1px solid ${palette.grey[300]}`,
    borderRadius: '50%',
    padding: '0 4px'
  },
  spacingFirstItem: {
    '& label:first-child': { marginTop: spacing(1) }
  },
  checkBoxRoot: {
    color: palette.grey[300],
    padding: `${spacing(.5)} ${spacing(2)} ${spacing(.5)} ${spacing(1.5)}`,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  labelV2: {
    color: palette.grey[800]
  },
  labelSelected: {
    color: palette.primary.main,
    fontWeight: 600
  },
  overrideFilterHighlight: {
    color: `${palette.common.white} !important`
  },
  badgeV2Selected: {
    fontWeight: 600
  },
  permanentFiltersContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: spacing(2)
  }
});

const RefinementList = ({
  classes,
  items,
  refine,
  tealiumTrack,
  trackingLabel,
  enableShowMore,
  showMoreLimit,
  searchForItems,
  showSearch,
  showSelectedOnly,
  sortAlphabetical,
  isMobileView,
  opt,
  itemSelected,
  toggleItemSelectedButNotExpanded,
  searchExperienceV3IsEnabled,
  isAdditionalRefinement,
  maybeSearchState,
  permanentSearchState,
  overrideDarkThemeText,
  enableDarkThemeTiles,
  availableBrands,
  setAvailableBrands,
  exclusiveDealBannerText
}) => {
  if (opt === 'brandTag' && (availableBrands || []).length !== (items || []).length) {
    setAvailableBrands(items);
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [moreVisible, setMoreVisible] = useState(false);
  const [touchedDefaultRefinements, setTouchedDefaultRefinements] = useState({});
  const [hiddenOptions, hiddenOptionsArr] = useMemo(() => {
    const { refinementList = {} } = permanentSearchState || {};
    const set = new Set(refinementList[opt] || []);
    return [set, Array.from(set)];
  }, [permanentSearchState, opt]);
  const clearSearchTerm = () => setSearchTerm('');
  useEffect(() => {
    if (showSearch) {
      searchForItems(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (toggleItemSelectedButNotExpanded && (items || []).length) {
      if (totalSelected > 0) {
        toggleItemSelectedButNotExpanded({ opt, value: true });
      } else {
        toggleItemSelectedButNotExpanded({ opt, value: false });
      }
    }
  }, [items]);

  useEffect(() => {
    if (itemSelected && moreVisible) {
      setMoreVisible(false);
    }
  }, [itemSelected]);

  const toggleMoreVisible = () => setMoreVisible(!moreVisible);
  const maybeSortAlpha = sortAlphabetical ?
    items.sort(({ label: alpha }, { label: beta }) => stringSort(alpha, beta)) : items;
  const { refinementList } = maybeSearchState || {};
  const defaultRefinements = (refinementList || {})[opt];

  let sortedItems = searchExperienceV3IsEnabled && (!showSelectedOnly && moreVisible)
    ? maybeSortAlpha : maybeSortAlpha
      .sort(({ isRefined: alpha }, { isRefined: beta }) => (beta ? 1 : 0) - (alpha ? 1 : 0));

  if (opt === 'categoryTheme') {
    sortedItems = sortedItems.filter(({ label }) => !HIDE_CATEGORY_FOR_WEB.includes(label));
  }

  const totalSelected = items.filter(({ isRefined, label }) => isRefined && !hiddenOptions.has(label)).length;
  const exposedLimit = ((showSelectedOnly && totalSelected > 0) || (!moreVisible && totalSelected >= showMoreLimit))
    ? totalSelected : (showMoreLimit || 6);
  const displayItems = ((enableShowMore && (!moreVisible || itemSelected)) ?
    sortedItems.slice(0, exposedLimit) : sortedItems);
  const withOverwrites = (displayItems || []).map(item => {
    if (opt === 'specialCouponCategories' && (item || {}).label === 'Exclusive Deal' && exclusiveDealBannerText) {
      return { ...item, label: exclusiveDealBannerText };
    }
    return item;
  });

  return (
    <div>
      {showSearch && (
        <TextField
          fullWidth
          placeholder={'Search'}
          variant={'outlined'}
          value={searchTerm}
          onChange={evt => {
            setSearchTerm(evt.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color={'default'}/>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={clearSearchTerm}>
                  <ClearIcon/>
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
      <div>
        {!!(hiddenOptionsArr || []).length && !!(withOverwrites || []).length && !itemSelected && (
          <>
            <div className={classes.permanentFiltersContainer}>
              {hiddenOptionsArr.map(item =>
                item
              )}
            </div>
          </>
        )}
        <FormControl color={'primary'} component="fieldset" className={classes.formControl}>
          <FormGroup className={isMobileView && classes.spacingFirstItem}>
            {withOverwrites.map(({ label, value, count, isRefined }) => (
              !hiddenOptions.has(label) && (<FormControlLabel
                key={`refinementList-${label}`}
                control={
                  <Checkbox checked={isRefined}
                    className={classNames(
                      { [classes.checkBoxRoot]: searchExperienceV3IsEnabled },
                      'refinement-checkbox')}
                    color={searchExperienceV3IsEnabled ? !overrideDarkThemeText && enableDarkThemeTiles ?
                      'highlight' : 'primary' : 'secondary'}
                    onChange={() => {
                      if (tealiumTrack) {
                        const labelPrefix = trackingLabel || 'filter';
                        tealiumTrack({
                          name: 'search',
                          category: 'search',
                          action: `${labelPrefix}_${isRefined ? 'remove' : 'add'}`,
                          label
                        });
                      }
                      clearSearchTerm();
                      if ((defaultRefinements || []).includes(label) && !(touchedDefaultRefinements || {})[label]) {
                        setTouchedDefaultRefinements({ ...touchedDefaultRefinements, [label]: true });
                      }
                      refine(value);
                    }} />
                }
                label={(
                  <Badge badgeContent={count} max={999}
                    classes={{ badge: searchExperienceV3IsEnabled ?
                      classNames(classes.badgeV2, { [classes.badgeV2Selected]: isRefined }) : classes.badge
                    }}>
                    <Typography variant={'body2'}
                      color={enableDarkThemeTiles && overrideDarkThemeText ?
                        '#161e2b' : (isRefined ? 'primary' : 'textPrimary')}
                      className={classNames({
                        [classes.labelSelected]: searchExperienceV3IsEnabled && isRefined,
                        [classes.overrideFilterHighlight]: !overrideDarkThemeText && enableDarkThemeTiles
                      })}>
                      {label}
                    </Typography>
                  </Badge>
                )}
              />)
            ))}
          </FormGroup>
        </FormControl>
      </div>
      {enableShowMore && !itemSelected && items.length > showMoreLimit && (
        <Button
          fullWidth
          color={enableDarkThemeTiles && !overrideDarkThemeText ? 'highlight' : 'primary'}
          onClick={toggleMoreVisible}
        >
          <Text message={moreVisible ? 'showLess' : 'showMore'}
            className={overrideDarkThemeText ? 'show-more-btn' : ''}/>
        </Button>
      )}
      {isAdditionalRefinement && searchExperienceV3IsEnabled && !!(withOverwrites || []).length &&
        <DividerWithSpacing spacing={4}/>}
    </div>
  );
};

RefinementList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  currentRefinement: PropTypes.arrayOf(PropTypes.string),
  refine: PropTypes.func,
  isFromSearch: PropTypes.bool,
  searchForItems: PropTypes.func,
  createURL: PropTypes.func
  // tealiumTrack: PropTypes.tealiumTrack
};

export default connectRefinementList(withStyles(styles)(RefinementList));
