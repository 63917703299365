import React from 'react';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import MuiDialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Typography from '@mui/material/Typography/Typography';
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&+.MuiDialogContent-root': {
      paddingTop: '20px !important'
    }
  },
  overrideModalToHighlightColors: {
    background: `${theme.palette.highlight.highlightBackground} !important`
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.white
  },
  centerTitle: {
    textAlign: 'center'
  },
  fullScreenCalendarForMobile: {
    backgroundColor: theme.palette.primary.contrastText,
    color: '#000',
    fontWeight: 900,
    borderBottom: '1px solid black',

    '&+.MuiDialogContent-root': {
      padding: 'unset !important',
      height: 'calc(100% - 150px)'
    },

    '& h6': {
      color: '#000 !important'
    }
  },
  fullScreenCalendarForMobileCloseButton: {
    color: 'gray'
  }
}))(props => {
  const {
    children, classes, id, onClose, centerTitle, overrideModalToHighlightColors, fullScreenCalendarForMobile } = props;
  return (
    <MuiDialogTitle disableTypography className={classNames(classes.root, 'modalTitle',
      { [classes.centerTitle]: centerTitle,
        [classes.overrideModalToHighlightColors]: overrideModalToHighlightColors,
        [classes.fullScreenCalendarForMobile]: fullScreenCalendarForMobile }
    )}
    >
      <Typography variant="h6" color="inherit" id={id}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classNames({
          [classes.closeButton]: true,
          [classes.fullScreenCalendarForMobileCloseButton]: fullScreenCalendarForMobile
        })} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default DialogTitle;
