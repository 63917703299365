/* eslint-disable max-len, id-length, max-lines */
export default {
  searchExperiences: '검색 경험',
  searchDestinationOrExperiences: '목적지 또는 경험 검색',
  search: '찾다',
  filters: '필터',
  refinements: {
    webCategoriesMain: '유형',
    durationTiming: '지속',
    eventPrice: '가격',
    displayPrice: '가격',
    availableDates: '날짜',
    country: '국가',
    promotionTag: '프로모션',
    city: '목적지',
    categoryTheme: '범주',
    audience: '좋은',
    priceBetween: '%s와 %s 사이',
    priceBelow: '%s 이하',
    priceAbove: '%s 이상',
    dateBetween: '%s와 %s 사이',
    dateBefore: '%s 이전',
    dateAfter: '%s 이후',
    brandTag: '상표',
    hierarchical: { lvl0: '카테고리' }
  },
  from: '에서',
  to: '에게',
  duration: '지속',
  clearAllFilters: '모든 필터 지우기',
  clear: '분명한',
  applyLabel: '적용하다',
  city: {
    REQUIRED_FIELD: '청구 도시를 입력하십시오.',
    INVALID_CITY: '이 필드에 입력한 정보가 유효하지 않습니다. 도시를 다시 입력하십시오.'
  },
  country: { REQUIRED_FIELD: '거주 국가를 선택하세요.' },
  date: { REQUIRED_FIELD: '이벤트 날짜를 선택하세요.' },
  recommended: '추천',
  filterByPrice: '가격으로 필터링',
  lowToHigh: '가격 - 낮음에서 높음',
  highToLow: '가격 - 높음에서 낮음',
  alipay: '알리페이',
  sendProposal: '제안서 작성',
  and: ' 그리고',
  sortBy: '정렬 기준',
  showLess: '간단히 보기',
  showMore: '자세히보기',
  showResults: '결과 보여줘',
  noResults: '결과 없음',
  price: '가격',
  none: '없음',
  cancelled: '취소 된',
  creditCardExternal: '%s 외부 신용 카드로 지불',
  cashiersCheck: '수표/자기앞 수표',
  achWire: '아크/와이어',
  or: '또는',
  off: '끄다',
  transfer: '옮기다',
  pending: '보류 중',
  loadMore: '더 불러오기',
  companyBalance: '회사 잔액',
  remove: '제거하다',
  accepted: '수락됨',
  denied: '거부됨',
  likelyToSellOut: '매진 가능성',
  noOfRecentBookings: '%s 최근 예약'
};
