/* eslint-disable max-lines */
import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import { connectSortBy } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = ({ spacing, typography, palette }) => ({
  sortForm: {
    textAlign: 'center',
    display: 'flex'
  },
  sortSelect: {
    '&.fullPadding': {
      padding: spacing(.5)
    },
    paddingLeft: spacing(.5),
    paddingRight: spacing(.5),
    fontSize: typography.caption.fontSize,
    '&:before': {
      border: 'none !important'
    },
    '&:after': {
      border: 'none !important'
    },
    '& #select-sortByFilter:focus': {
      background: 'none !important'
    }
  },
  menuItem: {
    backgroundColor: `${palette.common.white} !important`,
    color: `${palette.mode === 'light' ? palette.text.primary : palette.primary.main} !important`,
    '&:hover': {
      backgroundColor: `${palette.grey[200]} !important`
    }
  },
  menuItemActive: {
    backgroundColor: `${palette.primary.main} !important`,
    color: `${palette.common.white} !important`,
    '&:hover': {
      color: `${palette.mode === 'light' ? palette.text.primary : palette.primary.main} !important`
    }
  },
  icon: {
    color: `${palette.mode === 'light' ? palette.text.primary : palette.primary.main}`
  }
});

const SortByDrawer = ({
  items = [], refine, classes, tealiumTrack, isXsView,
  selectedValue, setFiltersSortByOpen, isRadio = true, sortByLabel,
  ariaLabelToUse, sortByDrawerOpen = true, setSortByDrawerOpen, enableDarkThemeTiles = false,
  fullWidthMobileDrawer = false
}) => {
  const [sortSelect, setSortSelect] = useState(false);

  const getLabel = valueArg => {
    if (valueArg.includes('desc')) {
      return 'price - High to Low';
    } else if (valueArg.includes('asc')) {
      return 'price - Low to High';
    } else if (valueArg.includes('geo')) {
      return 'Location - Near to Far';
    }
    return 'price - Recommended';
  };

  const handleChange = (event, value) => {
    event.preventDefault();
    setFiltersSortByOpen(false);
    const targetValue = event.target.value;
    tealiumTrack({
      name: 'search',
      category: 'search',
      action: 'sort_click',
      label: getLabel(value || targetValue)
    });
    refine(targetValue);
  };

  const handleSelectChange = value => {
    setSortByDrawerOpen(false);
    setSortSelect(false);
    const targetValue = value;
    tealiumTrack({
      name: 'search',
      category: 'search',
      action: 'sort_click',
      label: getLabel(targetValue)
    });
    refine(targetValue);
  };

  if (!isRadio) {
    return (
      <Select
        MenuProps={{
          MenuListProps: {
            sx: {
              backgroundColor: '#fff'
            }
          },
          disableAutoFocus: true,
          disableAutoFocusItem: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          getContentAnchorEl: null
        }}
        sx={{
          '.MuiOutlinedInput-input': { padding: '0px' },
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
          }
        }}
        className={classNames({
          [classes.sortSelect]: true,
          ['fullPadding']: !fullWidthMobileDrawer
        })}
        inputProps={{
          classes: {
            icon: classes.icon
          }
        }}
        autoFocus={false}
        name={'sortByFilter'}
        id={ariaLabelToUse}
        value={selectedValue}
        IconComponent={sortSelect ? ExpandLessIcon : ExpandMoreIcon }
        onChange={event => handleSelectChange(event.target.value)}
        open={sortByDrawerOpen}
        renderValue={() => selectedValue && (
          <Typography
            // eslint-disable-next-line no-nested-ternary
            variant={fullWidthMobileDrawer ? 'body1' : isXsView ? 'caption' : 'subtitle1'}
            color={enableDarkThemeTiles ? 'primary' : 'textPrimary'}>
            {sortByLabel}
          </Typography>
        )}>
        {items.map(({ label, value }, index) => {
          const selected = value === selectedValue;
          return <MenuItem
            button
            className={classNames({
              [classes.menuItem]: true,
              [classes.menuItemActive]: selected
            })}
            selected={selected}
            key={index}
            value={value}>
            <Typography
              // eslint-disable-next-line no-nested-ternary
              variant={fullWidthMobileDrawer ? 'body1' : isXsView ? 'caption' : 'subtitle1'} color={'inherit'}>
              {label}
            </Typography>
          </MenuItem>;
        }
        )}
      </Select>
    );
  }

  return (
    <div className={classes.sortForm}>
      <FormControl color={'primary'} component="fieldset" className={classes.formControl}>
        <RadioGroup
          aria-labelledby="Sort Filter"
          name="sortByFilter"
          value={selectedValue}
          onChange={(event, value) => {
            handleChange(event, value);
          }}
        >
          {items.map(({ label, value }) => (
            <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
          ))}

        </RadioGroup>
      </FormControl>
    </div>
  );
};

SortByDrawer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  refine: PropTypes.func,
  tealiumTrack: PropTypes.tealiumTrack,
  type: PropTypes.string.isRequired
};

export default connectSortBy(withStyles(styles)(SortByDrawer));
