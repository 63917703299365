import React from 'react';
import { Grid, Typography } from '@mui/material';
import { UK_CITIES_FOR_QUICK_FILTERS, ELLIPSE_ICON } from '../../lib/shared/enums';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  activeCityImg: {
    pointerEvents: 'none',
    width: '100%',
    height: '100%'

  },
  inactiveCityImg: {
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    opacity: '.5'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  optionContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer !important'
    },
    position: 'relative',
    textAlign: 'center',
    color: 'white'
  },
  cityImageText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '18px'
  },
  descriptionContainer: {
    display: 'block',
    paddingBottom: '18px'
  },
  dealsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '0px !important'
  },
  dealItemContainer: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    display: 'block'
  },
  dealsText: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px'
  },
  description: {
    fontSize: '12px',
    lineHeight: '18px',
    display: 'inline',
    fontWeight: '400'
  },
  discountCode: {
    fontSize: '12px',
    lineHeight: '18px',
    display: 'inline',
    color: '#FF00FF',
    fontWeight: '700'
  }
});

const handleOptionClick = ({ searchState, setSearchState, value, removeOption }) => {
  const updatedSearchState = {
    ...searchState,
    refinementList: {
      ...(searchState || {}).refinementList,
      city: removeOption ? [] : [value]
    }
  };
  setSearchState(updatedSearchState);
};

const CityQuickFilters = ({ classes, searchState, setSearchState }) => {
  const citiesToShow = UK_CITIES_FOR_QUICK_FILTERS;
  const {
    refinementList: {
      city = []
    } = {}
  } = searchState || {};
  const activeCity = (citiesToShow.find(cityEntry => cityEntry.value === city[0]) || []);
  return (
    <div>
      <Grid container spacing={3} justifyContent={'center'} alignContent={'center'} marginBottom={2} marginTop={2}>
        {citiesToShow.map(({ img, value, name }) => {
          const active = (activeCity.value === value);
          return (
            <Grid item key={value} xs={4} md={3} className={classes.itemContainer}>
              <div
                className={classes.optionContainer}
                onClick={() => handleOptionClick({ setSearchState, searchState, value, removeOption: active })}>
                <img src={img} className={active ? classes.activeCityImg : classes.inactiveCityImg}/>
                {!active ? <div className={classes.cityImageText}>{name}</div> : ''}
              </div>
            </Grid>
          );
        })}
        {citiesToShow.map(({ value, name }) => {
          const active = (activeCity.value === value);
          return (
            <Grid item key={value} xs={4} md={3} className={classes.dealsContainer}>
              { active ?
                <Grid className={classes.dealItemContainer}>
                  <img src={ELLIPSE_ICON}></img>
                  <Typography className={classes.dealsText}>{name} Deals</Typography>
                </Grid> : ''}
            </Grid>
          );
        })}
      </Grid>
      <Grid>
        {
          (activeCity.descriptions || []).map(description => (
            <Grid item key={description} className={classes.descriptionContainer} >
              <Typography item key={description} className={classes.description}>{description.text} </Typography>
              <Typography item key={description} className={classes.description}>Use code </Typography>
              <Typography item key={description} className={classes.discountCode}>{description.code}</Typography>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(CityQuickFilters);
