/* eslint-disable max-lines,import/no-unresolved */
import React, { useState, useEffect, Fragment } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connectRange } from 'react-instantsearch-dom';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
// import { InlineDatePicker } from 'material-ui-pickers';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import DateRangePicker from '../DateRangePicker';
import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import { DatePicker } from '@mui/x-date-pickers';

const DATE_CHIP_TYPES = {
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  THIS_WEEK: 'thisWeek'
};

const DATE_CHIP_LIST = {
  [DATE_CHIP_TYPES.TODAY]: {
    label: 'today',
    getDates: () => {
      const today = parseInt(moment().format('YYYYMMDD'));
      return { startDate: today, endDate: today };
    }
  },
  [DATE_CHIP_TYPES.TOMORROW]: {
    label: 'tomorrow',
    getDates: () => {
      const tomorrow = parseInt((moment().add(1, 'days'))
        .format('YYYYMMDD'));
      return { startDate: tomorrow, endDate: tomorrow };
    }
  },
  [DATE_CHIP_TYPES.THIS_WEEK]: {
    label: 'thisWeek',
    getDates: () => {
      const today = moment();
      const startDate = parseInt(today.format('YYYYMMDD'));
      const endDate = parseInt((today.add(7, 'days')).format('YYYYMMDD'));
      return { startDate, endDate };
    }
  }
};

const styles = ({ spacing, shape, typography }) => ({
  dateChipsSection: {
    marginTop: spacing(1)
  },
  dateContainer: {
    marginBottom: spacing(2)
  },
  dateChip: {
    width: '100%',
    height: spacing(5),
    borderRadius: shape.borderRadius,
    fontSize: typography.caption.fontSize,
    fontWeight: 500,
    '& .MuiChip-label': {
      overflow: 'visible',
      textOverflow: 'unset'
    },
    '@media (min-width:960px) and (max-width:1080px)': {
      fontSize: spacing(1.25)
    }
  },
  dateChipMobile: {
    fontSize: '.85rem'
  }
});

const toMoment = dt => moment(`${dt}`, 'YYYYMMDD').format('MM/dd/yyyy');
const toMomentL = dt => moment(`${dt}`, 'YYYYMMDD').format('L');

const DateRange = ({
  classes,
  currentRefinement,
  min,
  max,
  canRefine,
  refine,
  tealiumTrack,
  isMobileView,
  searchExperienceV3IsEnabled,
  selectedDate,
  setShowNoResult,
  setSelectedDate,
  isWidthSmDownView,
  hideMobileSearchComponents,
  customInclusiveCalendarEndDate,
  customInclusiveCalendarStartDate,
  blockCalendarChange,
  overrideShownMonths,
  overrideCalendarStartMonth,
  addMarginBottom = true,
  hideDatePickerPills,
  disableCalendars
}, { localize }) => {
  const [fromDate, setFromDate] = useState(currentRefinement.min || null);
  const [toDate, setToDate] = useState(currentRefinement.max || null);
  const [dateChip, setDateChip] = useState(null);

  const defaultNumberOfMonths = isMobileView ? 1 : 2;

  useEffect(() => {
    if ((canRefine || searchExperienceV3IsEnabled) && fromDate && toDate && fromDate <= toDate) {
      if (fromDate < min || max < toDate) {
        if (fromDate === toDate) {
          setShowNoResult(true);
        } else {
          refine({
            min: fromDate < min ? min : fromDate,
            max: toDate > max ? max : toDate
          });
          setShowNoResult(false);
        }
      } else {
        refine({
          min: fromDate,
          max: toDate
        });
        setShowNoResult(false);
      }

      if (typeof tealiumTrack === 'function') {
        tealiumTrack({
          name: 'search',
          category: 'search',
          action: 'date_range',
          label:
            `${moment(fromDate, 'YYYYMMDD').format('YYYY-MM-DD')} to ${moment(toDate, 'YYYYMMDD').format('YYYY-MM-DD')}`
        });
      }
    }

    if (!fromDate && !toDate && currentRefinement && currentRefinement.min && currentRefinement.max) {
      setDateChip(null);
      refine('');
      setShowNoResult(false);
    }
  }, [fromDate, toDate, min, max]);

  useEffect(() => {
    if (selectedDate && (selectedDate || {}).min && (selectedDate || {}).min !== fromDate) {
      setFromDate((selectedDate || {}).min);
    }
    if (selectedDate && (selectedDate || {}).max && (selectedDate || {}).max !== toDate) {
      setToDate((selectedDate || {}).max);
    }
    if (selectedDate && !(selectedDate || {}).min && !(selectedDate || {}).max) {
      setFromDate(null);
      setToDate(null);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (dateChip) {
      const {
        startDate,
        endDate
      } = dateChip !== 'none' && DATE_CHIP_LIST[dateChip].getDates();
      if (startDate && endDate) {
        setFromDate(startDate);
        setToDate(endDate);
        setSelectedDate({ min: startDate, max: endDate });
      }
    }
  }, [dateChip]);

  const onFromDateChange = date => {
    const newValue = parseInt(date.format('YYYYMMDD'));
    if (isNaN(parseInt(toDate)) || newValue > toDate) {
      setToDate(newValue);
    }
    setFromDate(newValue);
  };
  const onToDateChange = date => {
    const newValue = parseInt(date.format('YYYYMMDD'));
    if (isNaN(parseInt(fromDate)) || fromDate > newValue) {
      setFromDate(newValue);
    }
    setToDate(newValue);
  };

  const onDatesChange = ({ startDate, endDate }) => {
    const newStartValue = startDate && parseInt(startDate.format('YYYYMMDD'));
    const newEndValue = endDate && parseInt(endDate.format('YYYYMMDD'));
    setFromDate(newStartValue);
    setToDate(newEndValue);
    setSelectedDate({ min: newStartValue, max: newEndValue });
    setDateChip(null);
    const formattedStart = startDate && startDate.format('YYYYMMDD');
    const formattedEnd = endDate && endDate.format('YYYYMMDD');
    const oneWeekEnd = moment().add(7, 'days')
      .format('YYYYMMDD');
    if (startDate && endDate && formattedStart === formattedEnd && formattedStart === moment().format('YYYYMMDD')) {
      setDateChip('today');
    } else if (startDate && endDate && formattedStart === formattedEnd && formattedStart === moment().add(1, 'day')
      .format('YYYYMMDD')) {
      setDateChip('tomorrow');
    } else if (startDate && endDate && formattedStart === moment().format('YYYYMMDD') && formattedEnd === oneWeekEnd) {
      setDateChip('thisWeek');
    }
  };

  const renderDateChip = ({ id, label }) => {
    const isSelected = id === dateChip;
    return <Chip
      key={`date_chip_${id}`}
      className={classNames({
        [classes.dateChip]: true,
        [classes.dateChipMobile]: isWidthSmDownView
      })}
      label={localize(label)}
      variant={isSelected ? 'contained' : 'outlined'}
      clickable
      color={isSelected ? 'primary' : 'default'}
      onClick={() => {
        if (isSelected) {
          setFromDate(null);
          setToDate(null);
          setSelectedDate({});
        }
        setDateChip(isSelected ? null : id);
      }}
    />;
  };

  return (
    <div className={addMarginBottom && classes.dateContainer}>
      {!searchExperienceV3IsEnabled ? <Grid container spacing={1} wrap={'nowrap'} alignItems={'center'}>
        <Grid item>
          <DatePicker
            disabled={disableCalendars}
            fullWidth
            minDate={min ? toMoment(min) : undefined}
            maxDate={max ? toMoment(max) : undefined}
            variant="outlined"
            label={localize('from')}
            value={fromDate ? toMoment(fromDate) : null}
            onChange={date => onFromDateChange(date)}
            format={'DD MMM YYYY'}
          />
        </Grid>
        <Grid item>
          <DatePicker
            disabled={disableCalendars}
            fullWidth
            variant="outlined"
            minDate={min ? toMoment(min) : undefined}
            maxDate={max ? toMoment(max) : undefined}
            label={localize('to')}
            value={toDate ? toMoment(toDate) : null}
            onChange={date => onToDateChange(date)}
            format={'DD MMM YYYY'}
          />
        </Grid>
      </Grid>
        : <Fragment>
          <DateRangePicker
            disableCalendars={disableCalendars}
            initialStartDate={fromDate ? moment(fromDate, 'YYYYMMDD') : null}
            initialEndDate={toDate ? moment(toDate, 'YYYYMMDD') : null}
            min={min ? toMomentL(min) : undefined}
            max={max ? toMomentL(max) : undefined}
            orientation={'horizontal'}
            onDatesChange={onDatesChange}
            numberOfMonths={overrideShownMonths ? overrideShownMonths : defaultNumberOfMonths}
            dateChip={dateChip}
            setDateChip={setDateChip}
            customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
            customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
            blockCalendarChange={blockCalendarChange}
            overrideCalendarStartMonth={overrideCalendarStartMonth}
          />
          <Grid container alignItems={'center'} spacing={1} className={classes.dateChipsSection}>
            {((isMobileView && !hideMobileSearchComponents) || !isMobileView) && !hideDatePickerPills &&
              Object.keys(DATE_CHIP_LIST || {}).map(id =>
                <Grid item xs={4} key={`grid_date_chip_${id}`}>
                  {renderDateChip({ id, label: DATE_CHIP_LIST[id].label })}
                </Grid>
              )
            }
          </Grid>
        </Fragment>
      }
    </div>
  );
};

DateRange.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default connectRange(withStyles(styles)(DateRange));
