// InjectedHits.js
import React, { useEffect, useState } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { ConnectInjectedInfiniteHits } from './ConnectInjectedInfiniteHits';
import Results from '../SearchExperiences/Results';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Text } from 'react-localize';
import { Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';

export const InjectedInfiniteHits = connectInfiniteHits(
  ConnectInjectedInfiniteHits(({
    injectedInfiniteHits,
    numberOfHits,
    showNoResult,
    hasMore,
    refineNext,
    // eslint-disable-next-line no-unused-vars
    refinePrevious,
    ...props }) => {
    const [hits, setHits] = useState([]);
    useEffect(() => {
      if (!isEqual(injectedInfiniteHits, hits)) {
        setHits(injectedInfiniteHits);
      }
    }, [injectedInfiniteHits]);
    return (
      <div>
        <Results
          {...props}
          hits={hits}
          numberOfHits={numberOfHits}
          showNoResult={showNoResult}
          hasMore={hasMore}
        />
        <br/>
        <Grid container spacing={1}>
          {(hasMore && !showNoResult) && (
            <Grid item xs={12} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
              <Button onClick={() => {
                if (hasMore) {
                  refineNext();
                }
              }} color="primary" className={'load-more-btn btn btn-primary'}>
                <Text message={'loadMore'}/>
              </Button>
            </Grid>
          )}
          {(!showNoResult && !!(injectedInfiniteHits || []).length &&
            <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
              <Typography variant="body2" textAlign={'center'}>
                <Text
                  message={'showingExperiences'}
                  values={[(injectedInfiniteHits || []).length, numberOfHits]}
                  // className={classes.showExperiencesText}
                />
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>);
  }));
