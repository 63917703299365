
import React from 'react';
import Localize from './frontend/infrastructure/Localize/Localize';
import SearchExperiences from './frontend/components/SearchExperiences/SearchExperiences';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { deDE } from '@mui/x-date-pickers/locales';
import RootStyle from './frontend/components/RootStyle/RootStyle';
import cityexperiences from './frontend/lib/themes/cityexperiences';
import dark from './frontend/lib/themes/dark';
import sendAlgoliaEvent from './util/sendAlgoliaEvent';
import { setAlgoliaSearchInfo } from './util/setAlgoliaSearchInfo';
import tealiumTrack from './util/tealiumTrack';
import { StyledEngineProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';

const App = ({ ceSearchExperiencesParams, container }) => {
  const {
    onExperiencesSearchParamsChange,
    onHitCountChange,
    countryWithinDestinationEnabled,
    enableAndOperatorCategoryFilter,
    onScrollUp,
    enableAlwaysShowClearFilters,
    searchParams,
    hardRefresh,
    showInfiniteScrollOnTileListing,
    showDynamicTitle,
    showPromoCodeEligible,
    sortFeaturedFiltersByDate,
    enableProductsOnTheHorizon,
    showNewTextForNoResultFound,
    showCustomFilterToggle,
    customFilterToggleText,
    hideSpecialOffersToggle,
    enableDarkThemeTiles,
    showBrandQuickFilters,
    showUKCityQuickFilters,
    showFullLengthDatePickerMobile,
    showChooseDestinationDropdownMobile,
    hideMobileSearchComponents,
    customInclusiveCalendarEndDate,
    customInclusiveCalendarStartDate,
    showCalendarFilterViewOnMobile,
    hideDatePickerPills,
    searchBarHeaderText,
    hideRefinementsInUrl,
    overrideEnvTag,
    enableMobileTileVariant2Flag,
    enableMobileTileVariant3,
    showCategoryQuickFilters,
    disableCalendars,
    searchBarPlaceholderText,
    showFullWidthMobileSortByDrawer,
    showCurrentlyViewing,
    locale,
    fullScreenCalendarForMobile,
    enableAdTiles
  } = ceSearchExperiencesParams;

  return (<Localize locale={locale || 'en'}>
    <LocalizationProvider
      localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
      dateAdapter={AdapterDayjs}
      adapterLocale={'en'}>
      <RootStyle theme={enableDarkThemeTiles ? dark : cityexperiences}>
        <StyledEngineProvider injectFirst>
          <SearchExperiences
            hideDatePickerPills={hideDatePickerPills}
            showCurrentlyViewing={showCurrentlyViewing}
            hideRefinementsInUrl={hideRefinementsInUrl}
            searchBarHeaderText={searchBarHeaderText}
            showFullLengthDatePickerMobile={showFullLengthDatePickerMobile}
            showCalendarFilterViewOnMobile={showCalendarFilterViewOnMobile}
            hideMobileSearchComponents={hideMobileSearchComponents}
            showChooseDestinationDropdownMobile={showChooseDestinationDropdownMobile}
            showBrandQuickFilters={showBrandQuickFilters}
            showUKCityQuickFilters={showUKCityQuickFilters}
            showCustomFilterToggle={showCustomFilterToggle}
            customFilterToggleText={customFilterToggleText}
            enableProductsOnTheHorizon={enableProductsOnTheHorizon}
            hardRefresh={hardRefresh}
            searchParams={searchParams}
            onScrollUp={onScrollUp}
            onExperiencesSearchParamsChange={onExperiencesSearchParamsChange}
            siteId={'cityexperiences'}
            tealiumTrack={tealiumTrack}
            countryWithinDestinationEnabled={countryWithinDestinationEnabled}
            enableAndOperatorCategoryFilter={enableAndOperatorCategoryFilter}
            enableAlwaysShowClearFilters={enableAlwaysShowClearFilters}
            sendAlgoliaEvent={sendAlgoliaEvent}
            setAlgoliaSearchInfo={setAlgoliaSearchInfo}
            container={container}
            showInfiniteScrollOnTileListing={showInfiniteScrollOnTileListing}
            sortFeaturedFiltersByDate={sortFeaturedFiltersByDate}
            showDynamicTitle={showDynamicTitle}
            showPromoCodeEligible={showPromoCodeEligible}
            hideSpecialOffersToggle={hideSpecialOffersToggle}
            showNewTextForNoResultFound={showNewTextForNoResultFound}
            enableDarkThemeTiles={enableDarkThemeTiles}
            customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
            customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
            overrideEnvTag={overrideEnvTag}
            enableMobileTileVariant2Flag={enableMobileTileVariant2Flag}
            enableMobileTileVariant3={enableMobileTileVariant3}
            showCategoryQuickFilters={showCategoryQuickFilters}
            disableCalendars={disableCalendars}
            searchBarPlaceholderText={searchBarPlaceholderText}
            showFullWidthMobileSortByDrawer={showFullWidthMobileSortByDrawer}
            currentLocale={locale}
            fullScreenCalendarForMobile={fullScreenCalendarForMobile}
            enableAdTiles={enableAdTiles}
            onHitCountChange={onHitCountChange}
          />
        </StyledEngineProvider>
      </RootStyle>
    </LocalizationProvider>
  </Localize>);
};

export default App;
