/* eslint-disable max-lines */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import InputAdornment from '@mui/material/InputAdornment';
import { Button, Grid } from '@mui/material';
import CalendarIcon from '@mui/icons-material/TodayOutlined';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import DateRangePicker from '../DateRangePicker';
import { connectRange } from 'react-instantsearch-dom';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { Text } from 'react-localize';
import Modal from '../Modal';

const styles = ({ palette, shape, typography, breakpoints, spacing }) => ({
  dateBox: {
    borderRadius: shape.borderRadius,
    '& input': {
      display: 'initial',
      fontFamily: typography.body1.fontFamily,
      fontSize: `${typography.body1.fontSize} !important`,
      lineHeight: typography.body1.lineHeight,
      fontWeight: typography.body1.fontWeight,
      padding: '10.5px 0px',
      width: spacing(11),
      textAlign: 'left'
    },
    [breakpoints.down('sm')]: {
      '& input': {
        width: spacing(6),
        fontFamily: typography.body2.fontFamily,
        fontSize: `${typography.body2.fontSize} !important`,
        lineHeight: typography.body2.lineHeight,
        fontWeight: typography.body2.fontWeight,
        padding: '18.5px 0px'
      }
    }
  },
  dateBoxHidden: {
    [breakpoints.down('sm')]: {
      '& input': {
        display: 'none'
      }
    }
  },
  dateBoxFull: {
    '& input': {
      width: spacing(27)
    }
  },
  dateBoxHalf: {
    '& input': {
      width: spacing(13)
    }
  },
  calendarIcon: {
    width: spacing(9.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginLeft: spacing(1 / 2)
  },
  selectedDateContainer: {
    width: spacing(9.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginLeft: spacing(1 / 2)
  },
  maxDateLabel: {
    borderTop: `1px solid ${palette.divider}`
  },
  datesPopup: {
    position: 'absolute !important',
    top: '100% !important',
    left: '50% !important',
    backgroundColor: '#fff !important',
    borderRadius: '10px !important',
    boxShadow: '0px 3px 6px #00000026 !important',
    zIndex: '2 !important',
    [breakpoints.down('sm')]: {
      left: 'revert !important',
      right: '319px !important'
    }
  },
  loadMoreButton: {
    alignItems: 'end',
    justifyContent: 'space-around',

    '& button': {
      textTransform: 'unset',
      padding: '8px 16px',
      color: '#000',
      borderColor: '#000'
    }
  },
  infoButton: {
    position: 'absolute',
    bottom: '20px',
    display: 'flex',
    justifyContent: 'space-around',
    borderTop: '1px solid lightgray',

    '& div': {
      paddingInline: 'unset !important'
    },

    '& button': {
      textTransform: 'unset',
      paddingInline: '60px'
    },

    '& div:first-child > button': {
      textDecoration: 'underline'
    }
  }
});

const toMoment = dt => moment(`${dt}`, 'YYYYMMDD').format('L');

const DateBox = ({
  classes,
  isMobileView,
  isWidthSmDownView,
  isXsView,
  selectedDate,
  setSelectedDate,
  min,
  max,
  canRefine,
  refine,
  setShowNoResult,
  onScrollUp,
  keepCalendarOpen,
  customInclusiveCalendarEndDate,
  customInclusiveCalendarStartDate,
  overrideCalendarStartMonth,
  blockCalendarChange,
  disableCalendars,
  fullScreenCalendarForMobile
}, { localize }) => {
  const [hasDateFocus, setHasDateFocus] = useState(false);
  const [dateHover, setDateHover] = useState(false);
  const [dates, setDates] = useState({
    startDate: (selectedDate || {}).min || null,
    endDate: (selectedDate || {}).max || null
  });
  const [loadMore, setLoadMore] = useState(12);
  const [openModal, setOpenModal] = useState(false);
  const { startDate, endDate } = dates || {};
  const onApplyRef = useRef();
  const onClearRef = useRef();

  useEffect(() => {
    if (canRefine && startDate && endDate && startDate <= endDate) {
      if (startDate < min || max < endDate) {
        if (startDate === endDate) {
          setShowNoResult(true);
        } else {
          refine({
            min: startDate < min ? min : startDate,
            max: endDate > max ? max : endDate
          });
          setShowNoResult(false);
        }
      } else {
        refine({
          min: startDate,
          max: endDate
        });
        setShowNoResult(false);
      }
    }
  }, [dates]);

  useEffect(() => {
    if (selectedDate && (selectedDate || {}).min && (selectedDate || {}).max && (
      (selectedDate || {}).min !== startDate || (selectedDate || {}).max !== endDate)
    ) {
      setDates({
        startDate: (selectedDate || {}).min,
        endDate: (selectedDate || {}).max
      });
    }
    if (selectedDate && !(selectedDate || {}).min && !(selectedDate || {}).max) {
      setDates({
        startDate: null,
        endDate: null
      });
    }
  }, [selectedDate]);

  // retry for a fallback
  const retry = (count = 0, limit, cb) => {
    if (count < limit) {
      cb();
      retry(count + 1, limit, cb);
    }
  };

  useEffect(() => {
    if (fullScreenCalendarForMobile && isWidthSmDownView) {
      retry(0, 1, () => setTimeout(() => {
        const dayPickerContainer = document.getElementsByClassName('DayPicker_transitionContainer__horizontal_2');
        if (dayPickerContainer && dayPickerContainer.length > 0) {
          dayPickerContainer[0].style.height = `${loadMore * 305}px`;
        }

        const allWeekdayHeaders = document.querySelectorAll('.DayPicker_weekHeader_1');
        (allWeekdayHeaders || []).forEach((header, idx) => {
          // eslint-disable-next-line no-param-reassign
          header.style.top = `${56 + idx * 305}px`;
        });

        const loadMoreContainer = document.getElementById('loadMore');
        if (loadMoreContainer) {
          loadMoreContainer.style.height = `${60 + allWeekdayHeaders.length * 305}px`;
        }
      }, 1000));
    }
  }, [loadMore]);

  const onDatesChange = ({ startDate: _startDate, endDate: _endDate }) => {
    const fromDate = _startDate && parseInt(_startDate.format('YYYYMMDD'));
    const toDate = _endDate && parseInt(_endDate.format('YYYYMMDD'));
    setDates({
      startDate: fromDate,
      endDate: toDate
    });
    setSelectedDate({
      min: fromDate,
      max: toDate
    });
  };

  const getDateLabel = () => {
    if (!startDate || !endDate) {
      return '';
    }

    const sameDate = isEqual(startDate, endDate);
    if (sameDate) {
      return moment(startDate, 'YYYYMMDD').format('D MMM YYYY');
    }

    return startDate && endDate &&
        `${moment(startDate, 'YYYYMMDD').format('D MMM YYYY')} - ${moment(endDate, 'YYYYMMDD').format('D MMM YYYY')}`;
  };

  const startDateLabel = startDate ? moment(startDate, 'YYYYMMDD').format('D MMM YYYY') : '';
  const endDateLabel = endDate ? moment(endDate, 'YYYYMMDD').format('D MMM YYYY') : '';
  const openDatePicker = () => {
    if (!disableCalendars) {
      setHasDateFocus(true);
    }
  };

  const onApply = () => {
    if (onApplyRef && onApplyRef.current) {
      onApplyRef.current.click();
    }
  };

  const onClear = () => {
    if (onClearRef && onClearRef.current) {
      onClearRef.current.click();
    }
  };

  const renderDateRangePicker = (numberOfMonths = 1) => (
    <DateRangePicker
      onApplyRef={onApplyRef}
      onClearRef={onClearRef}
      disableCalendars={disableCalendars}
      initialStartDate={startDate ? moment(startDate, 'YYYYMMDD') : null}
      initialEndDate={endDate ? moment(endDate, 'YYYYMMDD') : null}
      min={min ? toMoment(min) : undefined}
      max={max ? toMoment(max) : undefined}
      onDatesChange={onDatesChange}
      withInput={false}
      onClose={() => {
        onScrollUp();
        setHasDateFocus(false);
        setDateHover(false);
        setOpenModal(false);
      }}
      numberOfMonths={numberOfMonths}
      customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
      customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
      overrideCalendarStartMonth={
        fullScreenCalendarForMobile && isWidthSmDownView ? moment() : overrideCalendarStartMonth}
      blockCalendarChange={blockCalendarChange}
      fullScreenCalendarForMobile={fullScreenCalendarForMobile}
    />
  );

  return (
    <Fragment>
      <TextField
        className={classNames({
          [classes.dateBox]: true,
          [classes.dateBoxHidden]: isXsView && (startDate || endDate),
          [classes.dateBoxFull]: !isXsView && !dateHover && ((startDate || endDate) && startDate !== endDate),
          [classes.dateBoxHalf]: !isXsView && !dateHover && ((startDate || endDate) && startDate === endDate)
        })}
        fullWidth
        variant={'standard'}
        margin={'dense'}
        value={getDateLabel()}
        placeholder={localize('dates')}
        onFocus={openDatePicker}
        onClick={() => {
          if (fullScreenCalendarForMobile && isWidthSmDownView) {
            setOpenModal(true);
          }
        }}
        sx={{
          margin: '0px !important'
        }}
        onBlur={() => {
          setTimeout(() => {
            setHasDateFocus(false);
          }, 100);
        }}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: isMobileView ? 8 : 0 }}>
              <div className={classes.calendarIcon}
                onClick={openDatePicker}
                onBlur={() => {
                  setTimeout(() => {
                    setHasDateFocus(false);
                  }, 100);
                }}
                tabIndex={0}
              >
                <CalendarIcon color={'primary'}
                  fontSize={'medium'} />
              </div>
              {isXsView && (startDate || endDate) &&
                <div className={classes.selectedDateContainer}
                  onClick={openDatePicker}
                  onBlur={() => {
                    setTimeout(() => {
                      setHasDateFocus(false);
                    }, 100);
                  }}
                  tabIndex={0}
                >
                  <Typography variant={'caption'} color={'textPrimary'}>
                    {startDateLabel}
                  </Typography>
                  {startDate !== endDate &&
                    <Typography variant={'caption'} color={'textPrimary'} className={classes.maxDateLabel}>
                      {endDateLabel}
                    </Typography>
                  }
                </div>
              }
            </InputAdornment>
          )
        }}
      />
      <Modal
        keepMounted
        isOpen={openModal && isWidthSmDownView && fullScreenCalendarForMobile}
        id="calendar-modal"
        onClose={() => {
          onScrollUp();
          setOpenModal(false);
        }}
        centerTitle
        fullScreenCalendarForMobile
        title={localize('addDates')}>
        <div>
          {renderDateRangePicker(loadMore)}
        </div>
        <Grid container id={'loadMore'} justifyContent={'flex-end'} spacing={2} className={classes.loadMoreButton} >
          <Button
            variant={'outlined'}
            onClick={() => setLoadMore(_loadmore => _loadmore + 12)}
          >
            <Text message={'loadMoreDates'} />
          </Button>
        </Grid>
        <Grid container justifyContent={'flex-end'} spacing={2} className={classes.infoButton} >
          <Grid item>
            <Button
              color={'primary'}
              disabled={!startDate}
              onClick={() => onClear()}
            >
              <Text message={'clear'}/>
            </Button>
          </Grid>
          <Grid item>
            <Button
              color={'primary'}
              variant={'contained'}
              disabled={!startDate}
              onClick={() => onApply()}
            >
              <Text message={'applyLabel'}/>
            </Button>
          </Grid>
        </Grid>
      </Modal>
      {(hasDateFocus || dateHover || keepCalendarOpen) &&
        (!fullScreenCalendarForMobile || (fullScreenCalendarForMobile && !isWidthSmDownView)) &&
        <div
          className={classes.datesPopup}
          onMouseEnter={() => setDateHover(true)}
          onMouseLeave={() => setDateHover(false)}
        >
          {renderDateRangePicker(isMobileView ? 1 : 2)}
        </div>
      }
    </Fragment>
  );
};

DateBox.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default connectRange(withStyles(styles)(DateBox));
