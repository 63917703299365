/* eslint-disable max-lines */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { BRANDS_FOR_QUICK_FILTERS } from '../../lib/shared/enums';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = ({ spacing, breakpoints }) => ({
  brandImg: {
    width: '100%',
    maxWidth: '15rem'
  },
  itemContainer: {
    alignText: 'center'
  },
  optionContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: '15rem',
    aspectRatio: '1/1',
    margin: 'auto',
    border: '1px solid white',
    [breakpoints.down('sm')]: {
      padding: spacing(2),
      paddingTop: `${0} !important`
    },
    padding: spacing(4),
    borderRadius: spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.hover': {
      '&:hover': {
        cursor: 'pointer',
        [breakpoints.up('md')]: {
          backgroundColor: '#ffffff3b'
        }
      }
    }
  },
  banner: {
    top: '-20px',
    left: '-20px',
    position: 'absolute',
    backgroundColor: '#1AEAEB',
    padding: '8px 20px',
    fontSize: '20px',
    lineHeight: '1.3',
    boxShadow: '5px 5px #087777',
    borderRadius: '0 10px',
    color: 'black',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column'
  },
  upTo: {
    fontSize: '10px'
  },
  pctOff: {
    fontSize: '25px',
    lineHeight: '20px'
  },
  percentOffColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  bannerColumns: {
    display: 'flex'
  },
  bannerPercent: {
    fontSize: '12px',
    lineHeight: '10px'
  },
  bannerOff: {
    fontSize: '10px',
    lineHeight: '8px'
  },
  bottomFlag: {
    bottom: 0,
    width: '100%',
    position: 'absolute',
    backgroundColor: '#1AEAEB',
    borderRadius: ' 0px 0px 16px 16px',
    borderTop: '1px solid white',
    justifyContent: 'center',
    color: 'black'
  },
  bottomFlagText: {
    fontSize: '11px',
    lineHeight: '20px'
  }
});

const useTopBanner = ({ classes, pctOff, showComingSoon }) => (<Grid container className={classes.banner}>
  <Grid item className={classes.bannerColumns}>
    <Typography className={classNames(classes.upTo, 'brandTileBannerText')}>UP TO</Typography>
  </Grid>
  <Grid item className={classes.bannerColumns}>
    <Grid container justifyContent={'flex'}>
      <Grid item>
        <Typography className={classNames(classes.pctOff, 'brandTileBannerText')}>{pctOff}</Typography>
      </Grid>
      <Grid item>
        <Grid container className={classes.percentOffColumn}>
          <Grid item>
            <Typography className={classNames(classes.bannerPercent, 'brandTileBannerText')}>%</Typography>
          </Grid>
          <Grid item>
            <Typography className={classNames(classes.bannerOff, 'brandTileBannerText')}>OFF</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  <Typography variant="caption">
    {showComingSoon && 'Starts 11/24'}
  </Typography>
</Grid>);

const useBottomBanner = ({ classes, pctOff }) => (<Grid container className={classes.bottomFlag}>
  <Grid item className={classes.mobileBannerContainer}>
    <Typography className={classes.bottomFlagText}>UP TO {pctOff}% OFF</Typography>
  </Grid>
</Grid>);

const handleOptionClick = ({ searchState, setSearchState, value, removeOption }) => {
  const {
    refinementList: {
      brandTag = []
    } = {}
  } = searchState || {};
  const selectedBrandTags = (value || []).split(',');
  const updatedBrandTagList = removeOption ? (brandTag || []).filter(opt => !(selectedBrandTags || []).includes(opt))
    : [...(brandTag || []), ...selectedBrandTags];
  const updatedSearchState = {
    ...searchState,
    refinementList: {
      ...(searchState || {}).refinementList,
      brandTag: updatedBrandTagList
    }
  };
  setSearchState(updatedSearchState);
};

const BrandQuickFilters = ({ classes, searchState, setSearchState, isWidthSmDownView, availableBrands }) => {
  const brandsToShow = BRANDS_FOR_QUICK_FILTERS;
  const {
    refinementList: {
      brandTag = []
    } = {}
  } = searchState || {};
  return (
    <Grid container spacing={2} justifyContent={'center'} alignContent={'center'} marginBottom={5} marginTop={2}>
      {
        brandsToShow.map(({ img, value, activeImg, subtext, pctOff, comingSoon }) => {
          const brands = (value || '').split(',');
          const active = (brands || []).every(brnd => !!(brandTag || []).find(tag => tag === brnd));
          const noResults = !(brands || []).some(brnd =>
            !!(availableBrands || []).find(availBrnd => ((availBrnd || {}).value || []).includes(brnd)));
          return (<Grid
            item
            key={value}
            xs={4}
            className={classes.itemContainer}
            style={noResults ? { filter: 'opacity(.5)' } : {}}>
            <div
              className={classNames({
                [classes.optionContainer]: true,
                hover: !noResults
              })}
              // eslint-disable-next-line no-nested-ternary
              style={active ?
                { backgroundColor: 'white' } : noResults ?
                  { filter: 'grayscale(1)' } : {}}
              onClick={() => (
                !noResults ? handleOptionClick({ setSearchState, searchState, value, removeOption: active }) : null
              )}>
              <img
                src={active ? activeImg : img}
                className={classes.brandImg}
                style={noResults ? { filter: 'opacity(.5)' } : {}}
              />
              { !isWidthSmDownView && useTopBanner({ classes, pctOff, showComingSoon: comingSoon && noResults })}
              { isWidthSmDownView && useBottomBanner({ classes, pctOff, showComingSoon: comingSoon && noResults })}
            </div>
            <Typography align="center" className={'brandDescriptionText'}>
              {isWidthSmDownView && comingSoon && noResults &&
              <Typography variant="caption">{'Starts 11/24'}</Typography>}
              {isWidthSmDownView ? <div style={{ marginTop: '.75rem' }}
                dangerouslySetInnerHTML={{ __html: subtext.replace(/\n/g, '<br />') }}/> :
                subtext}
            </Typography>
          </Grid>);
        })
      }
    </Grid>
  );
};

export default withStyles(styles)(BrandQuickFilters);
