import React from 'react';
import { compose } from 'recompose';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { Text } from 'react-localize';
import PropTypes from 'prop-types';

const styles = ({ spacing, typography, palette }) => ({
  root: {
    padding: spacing(1),
    fontFamily: typography.body1.fontFamily
  },
  title: {
    fontFamily: typography.secondaryFontFamily,
    color: palette.primary.main,
    fontWeight: 600
  }
});

const DynamicTitle = ({ searchState: { refinementList = {} } = {}, classes }) => {
  const { city = [], categoryTheme = [] } = refinementList;

  let cityName = (city || []).slice(0, 2).join(', ');
  let categoryName = (categoryTheme || []).slice(0, 1).join(', ');

  if (city?.length > 2) {
    cityName += ', and more';
  }

  if (categoryTheme?.length > 1) {
    categoryName += ', and more';
  }

  // eslint-disable-next-line no-extra-boolean-cast
  const message = `${!!categoryName ? categoryName : 'All'} Experiences ${!!cityName ? `in ${cityName}` : ''}`;

  return (
    <Typography variant={'h4'} className={classes.title}>
      <Text message={message} />
    </Typography>
  );
};

DynamicTitle.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles)
)(DynamicTitle);
