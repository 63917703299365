import React, { Fragment, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
// import stringSort from 'frontend/lib/stringSort';
// import PropTypes from 'prop-types';
import { connectHierarchicalMenu } from 'react-instantsearch-dom';
import stringSort from '../../lib/stringSort';
import FormGroup from '@mui/material/FormGroup';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { Text } from 'react-localize';
import Grid from '@mui/material/Grid';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';

const styles = ({ palette, spacing, typography }) => ({
  badge: {
    top: '50%',
    right: spacing(-2),
    backgroundColor: palette.mode === 'light' ? palette.grey[200] : palette.grey[900],
    zIndex: 0,
    color: typography.body2.color,
    lineHeight: typography.body2.lineHeight
  },
  badgeV2: {
    top: '50%',
    right: spacing(-2.75),
    backgroundColor: palette.mode === 'light' ? palette.grey[200] : palette.grey[900],
    zIndex: 0,
    fontSize: typography.body2.fontSize,
    minWidth: spacing(3),
    height: spacing(3),
    color: typography.body2.color,
    lineHeight: typography.body2.lineHeight,
    border: `1px solid ${palette.grey[300]}`
  },
  click: {
    cursor: 'pointer',
    display: 'flex'
  },
  iconButton: {
    marginLeft: `${spacing(-1.75)}`
  },
  childHierarchicalMenu: {
    marginLeft: `${spacing(3)}`
  },
  iconButtonV2: {
    marginLeft: `${spacing(-1.75)}`,
    padding: `${spacing(1) / 2} ${spacing(1) / 2} ${spacing(1) / 2} ${spacing(1.5)}`
  },
  labelSelected: {
    fontWeight: 600
  },
  badgeV2Selected: {
    fontWeight: 600
  }
});

const HierarchicalMenu = ({
  classes,
  items,
  refine,
  createURL,
  enableShowMore,
  showMoreLimit,
  sortAlphabetical,
  trackingLabel,
  tealiumTrack,
  searchExperienceV3IsEnabled
}) => {
  const [moreVisible, setMoreVisible] = useState(false);
  const [touched, setTouched] = useState(false);

  const toggleMoreVisible = () => setMoreVisible(!moreVisible);
  const maybeSortAlpha = sortAlphabetical ?
    items.sort(({ label: alpha }, { label: beta }) => stringSort(alpha, beta)) : items;
  const sortedItems = maybeSortAlpha
    .sort(({ isRefined: alpha }, { isRefined: beta }) => (beta ? 1 : 0) - (alpha ? 1 : 0));
  const totalSelected = items.filter(({ isRefined }) => isRefined).length;
  const exposedLimit = !touched && totalSelected > 0 ? totalSelected : (showMoreLimit || 10);
  const displayItems = ((enableShowMore && !moreVisible) ? sortedItems.slice(0, exposedLimit) : sortedItems);

  return (
    <div>
      <FormControl color={'primary'} component="fieldset" className={classes.formControl}>
        <FormGroup>
          {displayItems.map(({ label, value, count, isRefined, items: childItems }) => (
            <Fragment key={`hierarchicalList-${label}`}>
              <Grid container alignItems={'center'} className={classes.click}
                onClick={() => {
                  if (tealiumTrack) {
                    const labelPrefix = `trackingLabel_${label}` || 'filter';
                    tealiumTrack({
                      name: 'search',
                      category: 'search',
                      action: `${labelPrefix}_${isRefined ? 'remove' : 'add'}`,
                      label
                    });
                  }
                  setTouched(true);
                  refine(value);
                  createURL(value);
                }}>
                <Grid item>
                  <IconButton className={searchExperienceV3IsEnabled ? classes.iconButtonV2 : classes.iconButton}>
                    {isRefined && (childItems || []).length > 0 && <ExpandLessIcon/>}
                    {isRefined && !(childItems || []).length && <RadioButtonCheckedIcon />}
                    {!isRefined && <RadioButtonUncheckedIcon />}
                  </IconButton>
                </Grid>
                <Grid item>
                  <Badge badgeContent={count} max={999}
                    classes={{ badge: searchExperienceV3IsEnabled ?
                      classNames(classes.badgeV2, { [classes.badgeV2Selected]: isRefined }) : classes.badge
                    }}>
                    <Typography variant={'body1'} color={isRefined ? 'primary' : 'textPrimary'}
                      className={searchExperienceV3IsEnabled && isRefined ? classes.labelSelected : ''}>
                      {label}
                    </Typography>
                  </Badge>
                </Grid>
              </Grid>
              {(childItems || []).length > 0 &&
                <div className={classes.childHierarchicalMenu}>
                  <HierarchicalMenu
                    classes={classes}
                    items={childItems}
                    refine={refine}
                    createURL={createURL}
                    showMoreLimit={10}
                    sortAlphabetical={sortAlphabetical}
                    trackingLabel={trackingLabel}
                    tealiumTrack={tealiumTrack}
                    searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
                  />
                </div>
              }
            </Fragment>
          ))}
        </FormGroup>
      </FormControl>
      {enableShowMore && items.length > showMoreLimit && (
        <Button
          fullWidth
          color={'primary'}
          onClick={toggleMoreVisible}
        >
          <Text message={moreVisible ? 'showLess' : 'showMore'}/>
        </Button>
      )}
    </div>
  );
};

export default connectHierarchicalMenu(withStyles(styles)(HierarchicalMenu));
