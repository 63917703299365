import currencyLib from 'currency.js';

export const CURRENCY_SYMBOLS = {
  usd: '$',
  USD: '$',
  cad: '$',
  CAD: '$',
  gbp: '£',
  GBP: '£',
  eur: '€',
  EUR: '€',
  aud: 'A$',
  AUD: 'A$',
  undefined: '$'
};

const CURRENCY_FORMATTERS = {
  usd: (value, { precision = 2 } = {}) =>
    currencyLib(value, { symbol: CURRENCY_SYMBOLS.USD, precision }),
  cad: (value, { hideIso, precision = 2 }) =>
    currencyLib(value, { symbol: hideIso ? CURRENCY_SYMBOLS.CAD : 'CAD $', precision }),
  gbp: (value, { hideIso, precision = 2 }) =>
    currencyLib(value, { symbol: hideIso ? CURRENCY_SYMBOLS.GBP : 'GBP £', precision }),
  eur: (value, { hideIso, precision = 2, currencySymbolAfterNumber }) =>
    currencyLib(value, {
      symbol: (hideIso || currencySymbolAfterNumber) ? CURRENCY_SYMBOLS.EUR : 'EUR €',
      precision,
      ...currencySymbolAfterNumber && { pattern: '# !' }
    }),
  aud: (value, { hideIso, precision = 2 }) =>
    currencyLib(value, { symbol: hideIso ? CURRENCY_SYMBOLS.AUD : 'AUD $', precision })
};

const CURRENCY_FORMATTERS_FULL = {
  ...CURRENCY_FORMATTERS,
  usd: (value, { hideIso, precision = 2 }) =>
    currencyLib(value, { symbol: hideIso ? CURRENCY_SYMBOLS.USD : 'USD $', precision })
};

export const formatCurrency = (amount, {
  currency = 'usd',
  forceShowPrefix,
  forceHidePrefix,
  hideIso,
  precision,
  currencySymbolAfterNumber
} = {}) => {
  const formatter = forceShowPrefix ? CURRENCY_FORMATTERS_FULL : CURRENCY_FORMATTERS;
  if (forceHidePrefix) {
    const formattedAmount = (CURRENCY_FORMATTERS_FULL[currency.toLocaleLowerCase()] || formatter.usd)(amount,
      { hideIso, precision, currencySymbolAfterNumber }).format(true);
    if (currencySymbolAfterNumber) {
      return formattedAmount;
    }
    if (formattedAmount.startsWith('-')) {
      return `-${formattedAmount.slice(5)}`; // -USD $98.88
    }
    return formattedAmount.slice(4); // USD $98
  }
  return (formatter[currency.toLocaleLowerCase()] || formatter.usd)(amount,
    { hideIso, precision, currencySymbolAfterNumber }).format(true);
};
