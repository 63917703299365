/* eslint-disable max-len, id-length, max-lines */
export default {
  searchExperiences: 'Szukaj doświadczeń',
  searchDestinationOrExperiences: 'Wyszukaj miejsca docelowe lub atrakcje',
  search: 'Szukaj',
  filters: 'Filtry',
  refinements: {
    webCategoriesMain: 'Rodzaj',
    durationTiming: 'Czas trwania',
    eventPrice: 'Cena £',
    displayPrice: 'Cena £',
    availableDates: 'Daktyle',
    country: 'Kraj',
    promotionTag: 'Promocje',
    city: 'Miejsce docelowe',
    categoryTheme: 'Kategoria',
    audience: 'Dobre dla',
    priceBetween: 'Między %s a %s',
    priceBelow: 'Poniżej %s',
    priceAbove: 'Powyżej %s',
    dateBetween: 'Między %s a %s',
    dateBefore: 'Przed %s',
    dateAfter: 'Po %s',
    brandTag: 'Marka',
    hierarchical: { lvl0: 'Kategorie' }
  },
  from: 'Od',
  to: 'Do',
  duration: 'Czas trwania',
  clearAllFilters: 'Wyczyść wszystkie filtry',
  clear: 'Jasne',
  applyLabel: 'Zastosować',
  city: {
    REQUIRED_FIELD: 'Wprowadź miasto rozliczenia.',
    INVALID_CITY: 'Informacje wpisane w to pole są nieprawidłowe. Wprowadź ponownie swoje miasto.'
  },
  country: { REQUIRED_FIELD: 'Wybierz swój kraj zamieszkania.' },
  date: { REQUIRED_FIELD: 'Wybierz datę wydarzenia.' },
  recommended: 'Zalecana',
  filterByPrice: 'Filtruj według ceny',
  lowToHigh: 'Cena — od niskiej do wysokiej',
  highToLow: 'Cena - wysoka do niskiej',
  alipay: 'AliPay',
  sendProposal: 'Utwórz ofertę',
  and: ' oraz',
  sortBy: 'Sortuj według',
  showLess: 'Pokaż mniej',
  showMore: 'Pokaż więcej',
  showResults: 'Pokaż wyniki',
  noResults: 'Brak wyników',
  price: 'Cena £',
  none: 'Nic',
  cancelled: 'Odwołany',
  creditCardExternal: '%s zapłacił zewnętrzną kartą kredytową',
  cashiersCheck: 'Czek/czek kasjerski',
  achWire: 'Ach/Przewód',
  or: 'LUB',
  off: 'Wyłączony',
  transfer: 'Przenosić',
  pending: 'Aż do',
  loadMore: 'Załaduj więcej',
  companyBalance: 'Saldo firmy',
  remove: 'Usunąć',
  accepted: 'Przyjęty',
  denied: 'Odmówiono',
  likelyToSellOut: 'Prawdopodobnie do wyprzedania',
  noOfRecentBookings: '%s Ostatnie rezerwacje'
};
