/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable max-len, id-length, max-lines */

export default {
  searchExperiences: 'Search Experiences',
  searchDestinationOrExperiences: 'Search for Destinations or Experiences',
  search: 'Search',
  dates: 'Dates',
  filters: 'Filters',
  from: 'From',
  to: 'To',
  duration: 'Duration',
  clearAllFilters: 'Clear All',
  clearAllFiltersWithCount: 'Clear All (%s)',
  clearFilters: 'Clear All',
  clear: 'Clear',
  applyLabel: 'Apply',
  city: {},
  country: {},
  date: {},
  recommended: 'Recommended',
  filterByPrice: 'Sort By Price',
  lowToHigh: 'Price - Low to High',
  highToLow: 'Price - High to Low',
  closestToFarthest: 'Location - Near to Far',
  alipay: 'AliPay',
  sendProposal: 'Create Proposal',
  and: ' and ',
  sortBy: 'Sort By',
  showLess: 'Show Less',
  showMore: 'Show More',
  showResults: 'Show Results',
  showResultsWithCount: 'Show Results (%s)',
  today: 'Today',
  tomorrow: 'Tomorrow',
  thisWeek: 'This Week',
  noResults: 'No Results',
  price: 'Price',
  none: 'None',
  cancelled: 'Cancelled',
  creditCardExternal: '%s paid in external credit card',
  cashiersCheck: 'Check/Cashier\'s Check',
  achWire: 'Ach/Wire',
  or: 'OR',
  off: 'Off',
  transfer: 'Transfer',
  expired: 'Expired',
  pending: 'Pending',
  loadMore: 'Load More',
  companyBalance: 'Company Balance',
  remove: 'Remove',
  payAfterEvent: 'Pay After Event',
  accepted: 'Accepted',
  denied: 'Denied',
  likelyToSellOut: 'Likely to Sell Out',
  rewardPoints2x: '2X Reward Points',
  noOfRecentBookings: '%s Recent Bookings',
  couponSavings: 'Promo Offer',
  promoCodeEligible: 'Promo Code Eligible',
  exclusiveDeal: 'Exclusive Deal',
  savePercentage: 'Save up to %s%',
  payLater: 'Pay Later',
  showingExperiences: 'Showing %s of %s experiences',
  noResultsFound: {
    title: 'No Results',
    message: 'Sorry, we don\'t have experiences related to the keyword and/or filters. Please try new keyword and/or adjust filters or clear all.'
  },
  noResultsFoundWithFlag: {
    title: 'NO RESULTS FOUND',
    message: 'Sorry, we don’t have any experiences that match your search criteria. Try clearing your search for more options.'
  },
  flexibleDateWithFlag: {
    title: 'Flexible Dates?',
    message: 'Here are results for the next soonest available date'
  },
  flexibleDateWithFlagForSearchTerm: {
    title: 'Trending in %s',
    message: 'Take a look at the most popular experiences our guests are most looking forward to: '
  },
  productsOnHorizon: {
    header: 'Products on the Horizon',
    subheader: 'These products are not yet on sale, but will be on sale at a future date. Join the waitlist and learn more about our products!'
  },
  flexibleDateWithFlagForDateAndTerm: {
    title: 'Trending near %s',
    message: 'Here are results for similar experiences we offer near your search city:'
  },
  trendingNearYou: {
    title: 'Trending near you',
    message: 'Here are results for similar experiences we offer near your area:'
  },
  refinements: {
    specialCouponCategories: 'Special Offers',
    webCategoriesMain: 'Type',
    durationTiming: 'Duration',
    durationTimingV2: 'Time Of Day / Duration',
    eventPrice: 'Price',
    displayPrice: 'Price',
    availableDates: 'Dates',
    showPriceWithFees: 'Display total price',
    includesTaxesFees: 'Includes all fees and taxes',
    country: 'Country',
    promotionTag: 'Promotions',
    city: 'City',
    destination: 'Destination',
    categoryTheme: 'Category',
    audience: 'Good For',
    priceBetween: 'Between %s and %s',
    priceBelow: 'Below %s',
    priceAbove: 'Above %s',
    dateBetween: 'Between %s and %s',
    dateBefore: 'Before %s',
    dateAfter: 'After %s',
    brandTag: 'Brand',
    hierarchical: {
      lvl0: 'Categories'
    },
    containsSpecialCoupon: 'Special Offers'
  },
  feesIncludedTxt: 'All admin and port fees included',
  feesIncInPrice: 'Fees included in price',
  feesAddedTxt: '+ %s% fees',
  currentlyViewing: '15+ Currently Viewing',
  startDate: 'Start Date',
  endDate: 'End Date',
  addDates: 'Add Dates',
  loadMoreDates: 'Load more dates'
};
