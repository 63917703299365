/* eslint-disable max-lines */
import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const styles = ({ palette }) => ({
  btn: {
    cursor: 'pointer',
    backgroundColor: palette.action.hover,
    color: palette.text.primary,
    width: 40,
    height: 40,
    '&:hover': {
      backgroundColor: palette.action.selected
    }
  },
  active: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    '&:hover': {
      backgroundColor: palette.primary.dark
    }
  },
  disabled: {
    backgroundColor: palette.action.disabled,
    color: palette.text.disabled,
    '&:hover': {
      backgroundColor: palette.action.disabled
    }
  },
  notClickable: {
    cursor: 'default',
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

const noop = () => {};

const getDisplayedPages = ({ current, count }) => {
  if (current <= 4) {
    return [3, 4, 5].filter(val => val > 2 && val <= count - 1);
  }

  if (current >= count - 3) {
    return [
      count - 4,
      count - 3,
      count - 2
    ].filter(val => val > 2 && val < count - 1);
  }

  return [
    current - 1,
    current,
    current + 1
  ].filter(val => val > 2 && val < count - 1);
};

const Pagination = ({
  classes,
  current,
  count,
  onScrollUp,
  tealiumTrack,
  setSelectedPage
}) => {
  const displayedPages = getDisplayedPages({
    current,
    count
  });
  const scrollTop = typeof onScrollUp === 'function' ? onScrollUp : noop;
  const onChange = page => {
    if (tealiumTrack) {
      tealiumTrack({
        name: 'search',
        category: 'search',
        action: 'page_click',
        label: `${page}`
      });
    }
    setSelectedPage(page);
    scrollTop();
  };

  return (
    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
      <Grid item>
        <Avatar
          className={classNames({
            [classes.btn]: true,
            [classes.disabled]: current === 1
          })}
          key={'pagination-btn-prev'}
          onClick={() => current > 1 && onChange(current - 1)}
        >
          <ChevronLeftIcon/>
        </Avatar>
      </Grid>
      <Grid item>
        <Avatar
          className={classNames({
            [classes.btn]: true,
            [classes.active]: current === 1
          })}
          key={'pagination-btn-1'}
          onClick={() => onChange(1)}
        >
          1
        </Avatar>
      </Grid>
      {count > 1 && (
        <Grid item>
          {displayedPages[0] === 3 || count === 3 ? (
            <Avatar
              className={classNames({
                [classes.btn]: true,
                [classes.active]: current === 2
              })}
              key={'pagination-btn-2'}
              onClick={() => onChange(2)}
            >
              2
            </Avatar>
          ) : (count > 2 && (
            <Avatar
              className={classNames({
                [classes.btn]: true,
                [classes.notClickable]: true
              })}
              key={'pagination-btn-low-more'}
            >
              ...
            </Avatar>
          ))}
        </Grid>
      )}

      {displayedPages.map(page => (
        <Grid item key={`pagination-btn-${page}`}>
          <Avatar
            className={classNames({
              [classes.btn]: true,
              [classes.active]: current === page
            })}
            onClick={() => onChange(page)}
          >
            {page}
          </Avatar>
        </Grid>
      ))}
      {count > 5 && (
        <Grid item>
          {displayedPages[2] === count - 2 ? (
            <Avatar
              className={classNames({
                [classes.btn]: true,
                [classes.active]: current === count - 1
              })}
              key={`pagination-btn-${count - 1}`}
              onClick={() => onChange(count - 1)}
            >
              {count - 1}
            </Avatar>
          ) : (
            <Avatar
              className={classNames({
                [classes.btn]: true,
                [classes.notClickable]: true
              })}
              key={'pagination-btn-high-more'}
            >
          ...
            </Avatar>
          )}
        </Grid>
      )}
      {count > 1 && (
        <Grid item>
          <Avatar
            className={classNames({
              [classes.btn]: true,
              [classes.active]: current === count
            })}
            key={'pagination-btn-1'}
            onClick={() => onChange(count)}
          >
            {count }
          </Avatar>
        </Grid>
      )}
      <Grid item>
        <Avatar
          className={classNames({
            [classes.btn]: true,
            [classes.disabled]: current === count
          })}
          key={'pagination-btn-next'}
          onClick={() => current < count && onChange(current + 1)}
        >
          <ChevronRightIcon/>
        </Avatar>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Pagination);
