import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandLess } from '@mui/icons-material';

const styles = ({ spacing, shadows }) => ({
  destinationDropdown: {
    borderRadius: '8px',
    marginTop: spacing(2),
    padding: spacing(1),
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: 'white',
    cursor: 'pointer',
    position: 'relative'
  },
  refinementList: {
    padding: spacing(1),
    backgroundColor: 'white',
    color: 'black !important',
    position: 'absolute',
    width: '100%',
    top: '100%',
    zIndex: 5,
    boxShadow: shadows[4]
  },
  dropdownText: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  }
});

const ChooseDestinationDropdown = ({ classes, renderRefinementList, searchState }) => {
  const [isOpen, setIsOpen] = useState(false);

  const newRef = useRef(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const handleOutsideClick = evt => {
    if (newRef.current && !newRef.current.contains(evt.target)) {
      setIsOpen(false);
    }
  };
  const {
    refinementList: {
      city = []
    } = {}
  } = searchState || {};
  return (
    <Grid container mb={1}>
      <Grid item xs={12} ref={newRef}>
        <div className={classes.destinationDropdown}
          style={isOpen ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : {}}>
          <div onClick={() => setIsOpen(!isOpen)} className={classes.dropdownText}>
            <Typography noWrap px={2}>
              {(city && (city || []).length) ? city.join(', ') : 'Choose a Destination'}
            </Typography>
            {isOpen ? <ExpandLess/> : <ExpandMoreIcon/>}
          </div>
          <div className={classes.refinementList} hidden={!isOpen}>
            <Typography
              align="right"
              color="primary"
              marginRight={2}
              marginTop={1}
              onClick={() => setIsOpen(false)}>
              Close
            </Typography>
            {
              renderRefinementList(
                {
                  opt: 'city',
                  itemSelected: false,
                  trackingLabel: 'city',
                  sortAlphabetical: true,
                  searchable: false,
                  operator: 'or',
                  sortByCount: 10,
                  overrideDarkThemeText: true
                }
              )
            }
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ChooseDestinationDropdown);
