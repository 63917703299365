/* eslint-disable import/no-anonymous-default-export */
import {
  green,
  grey,
  red,
  purple,
  cyan,
  indigo,
  lime,
  yellow,
  blue,
  pink,
  lightGreen
} from '@mui/material/colors';

export default {
  typography: {
    fontFamily: ['Poppins', 'Rubik', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 14
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 900
      // xl: 1536
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#161e2b'
    },
    secondary: {
      light: '#c6e5f4',
      main: '#54B1E4',
      dark: '#1ab1eb',
      contrastText: '#fff'
    },
    highlight: {
      main: '#fff',
      highlightBackground: 'linear-gradient(90deg, rgba(42,62,136,1) 0%, rgba(84,168,220,1) 100%)'
    },
    calendar: {
      main: '#54B1E4',
      blockedText: '#808080',
      dateText: '#000',
      contrastText: '#fff'
    },
    highlightPaper: {
      light: '#eef8fe',
      main: '#54B1E4'
    },
    checkoutV2: {
      main: '#f9fbff'
    },
    background: {
      default: '#ffffff'
    },
    success: {
      light: '#6fbf73',
      main: '#4caf50',
      dark: '#357a38',
      contrastText: '#ffffff'
    },
    warning: {
      light: '#ffac33',
      main: '#ff9800',
      dark: '#b26a00',
      contrastText: '#ffffff'
    },
    info: {
      light: '#33bccd',
      main: '#00acc1',
      dark: '#007887',
      contrastText: '#ffffff'
    },
    special: {
      light: green[50],
      main: green[200],
      dark: green[800],
      contrastText: '#000'
    },
    insuranceHighlight: {
      main: grey[100]
    },
    confirmed: {
      main: lightGreen.A400,
      contrastText: grey[900]
    },
    pending: {
      main: yellow[500],
      contrastText: grey[900]
    },
    canceled: {
      main: red[900],
      contrastText: grey[50]
    },
    tentative: {
      main: cyan.A200,
      contrastText: grey[900]
    },
    option2: {
      main: blue[500],
      contrastText: grey[50]
    },
    option3: {
      main: blue[500],
      contrastText: grey[50]
    },
    option4: {
      main: blue[500],
      contrastText: grey[50]
    },
    closed: {
      main: '#ED6C02',
      contrastText: grey[900]
    },
    cancellation_in_progress: {
      main: pink[100],
      contrastText: grey[900]
    },
    ready_to_recognize: {
      main: purple[500],
      contrastText: grey[50]
    },
    settled: {
      main: grey[900],
      contrastText: grey[50]
    },
    open_date: {
      main: indigo.A700,
      contrastText: lime.A200
    },
    calendar_discount: {
      main: '#eaaf58',
      contrastText: '#ffffff'
    },
    calendar_discount_v3: {
      main: '#238500',
      contrastText: '#fff',
      hover: '#8AB670'
    },
    affirm: {
      light: '#f0f0fe',
      main: '#4a4af4'
    },
    likely_to_sellout_v2: {
      main: '#d1372f',
      contrastText: '#ffffff'
    },
    new_label: {
      // main: '#ed5e3c',
      main: '#b12704',
      contrastText: '#ffffff'
    },
    enhancement: {
      main: '#0863B2'
    },
    checkout_background: {
      main: '#DCE4F3'
    },
    // Venture Ashore Sales Banner colors
    chipColor1: {
      main: '#1ab1eb',
      contrastText: '#fff'
    },
    chipColor2: {
      main: '#ffa16b',
      contrastText: '#fff'
    },
    chipColor3: {
      main: '#3bd4ae',
      contrastText: '#fff'
    },
    chipColor4: {
      main: '#003DA5',
      contrastText: '#fff'
    }
  }
};
