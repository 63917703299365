import React from 'react';
import PropTypes from 'prop-types';
import Localization from 'react-localize';
import en from '../../translations/en';
import fr from '../../translations/fr';
import es from '../../translations/es';
import ja from '../../translations/ja';
import pt from '../../translations/pt';
import he from '../../translations/he';
import de from '../../translations/de';
import it from '../../translations/it';
import enUS from '../../translations/en_US';
import enAU from '../../translations/en_AU';
import enGB from '../../translations/en_GB';
import frCA from '../../translations/fr_CA';
import esMX from '../../translations/es_MX';
import ar from '../../translations/ar';
import zh from '../../translations/zh';
import ko from '../../translations/ko';
import pl from '../../translations/pl';

const getMessages = _locale => {
  const locales = {
    en,
    fr,
    es,
    ja,
    pt,
    he,
    de,
    it,
    'en-US': enUS,
    'en-AU': enAU,
    'en-GB': enGB,
    'fr-CA': frCA,
    fr_CA: frCA,
    'fr-FR': frCA,
    fr_FR: frCA,
    'es-MX': esMX,
    es_MX: esMX,
    ar,
    zh,
    ko,
    pl
  };
  const locale = _locale || 'en-US';
  return Object.assign(
    {},
    locales.en,
    locales[locale] || locales[locale.split('-')[0]] || locales.en
  );
};

const Localize = ({ children, locale }) => {
  const messages = getMessages(locale);
  return (
    <Localization messages={messages}>
      {children}
    </Localization>
  );
};

Localize.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Localize;
