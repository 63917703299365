
export default {
  applyLabel: 'Anwenden',
  price: 'Preis',
  alipay: 'AliPay',
  city: {
    INVALID_CITY: 'Die in dieses Feld eingegebenen Informationen sind ungültig. Bitte geben Sie Ihre Stadt erneut ein.',
    REQUIRED_FIELD: 'Bitte geben Sie Ihren Rechnungsort an.'
  },
  achWire: 'Ach / Wire',
  transfer: 'Transfer',
  clear: 'klar',
  date: { REQUIRED_FIELD: 'Bitte wählen Sie einen Veranstaltungstermin.' },
  off: 'aus',
  cancelled: 'Abgesagt',
  noResults: 'Keine Ergebnisse',
  country: { REQUIRED_FIELD: 'Bitte wählen Sie Ihr Wohnsitzland.' },
  cashiersCheck: 'Bankscheck',
  none: 'Keiner',
  sendProposal: 'Angebot erstellen'
};
