import cookie from 'cookiejs';
const COOKIE_NAME = 'cex_campaign';

export const isVwoTestActive = ({ campaignCookie, matchUrl, matchToUrls }) => {
  try {
    const isActive = cookie.get(COOKIE_NAME) === campaignCookie;
    const urlMatches = !matchUrl || (matchToUrls || []).some(url => (matchUrl || '').match(url));
    return isActive && (urlMatches || !matchUrl);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('cookie error', err);
    return false;
  }
};
