/* eslint-disable no-console */
import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import DateRangePickerWrapper from './DateRangePickerWrapper';
import DateRangePickerController from './DateRangePickerController';
import PropTypes from 'prop-types';

const DateRangePicker = ({
  locale = 'en',
  initialStartDate,
  initialEndDate,
  min,
  max,
  displayFormat = 'D MMM YYYY',
  orientation = 'horizontal',
  numberOfMonths = 2,
  showIcon = true,
  customInputIcon,
  customArrowIcon,
  onDatesChange,
  hideEndDate = true,
  showClearButton = true,
  withInput = true,
  onClose,
  dateChip,
  setDateChip,
  blockCalendarChange,
  overrideCalendarStartMonth,
  customInclusiveCalendarEndDate,
  customInclusiveCalendarStartDate,
  disableCalendars,
  onApplyRef,
  onClearRef,
  fullScreenCalendarForMobile
}, { localize }) => (
  !withInput ?
    <DateRangePickerController
      onApplyRef={onApplyRef}
      onClearRef={onClearRef}
      locale={locale}
      disableCalendars={disableCalendars}
      orientation={orientation}
      min={min}
      max={max}
      initialStartDate={initialStartDate}
      initialEndDate={initialEndDate}
      onDatesChange={onDatesChange}
      onClose={onClose}
      numberOfMonths={numberOfMonths}
      customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
      customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
      blockCalendarChange={blockCalendarChange}
      overrideCalendarStartMonth={overrideCalendarStartMonth}
      fullScreenCalendarForMobile={fullScreenCalendarForMobile}
    /> :
    <DateRangePickerWrapper
      onApplyRef={onApplyRef}
      onClearRef={onClearRef}
      disableCalendars={disableCalendars}
      locale={locale}
      initialStartDate={initialStartDate}
      initialEndDate={initialEndDate}
      min={min}
      max={max}
      displayFormat={displayFormat}
      orientation={orientation}
      numberOfMonths={numberOfMonths}
      showIcon={showIcon}
      customInputIcon={customInputIcon}
      customArrowIcon={customArrowIcon}
      onDatesChange={onDatesChange}
      hideEndDate={hideEndDate}
      showClearButton={showClearButton}
      dateChip={dateChip}
      setDateChip={setDateChip}
      customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
      customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
      blockCalendarChange={blockCalendarChange}
      overrideCalendarStartMonth={overrideCalendarStartMonth}
      endDatePlaceholderText={localize('endDate')}
      startDatePlaceholderText={localize('startDate')}
      fullScreenCalendarForMobile={fullScreenCalendarForMobile}
    />
);

DateRangePicker.contextTypes = {
  localize: PropTypes.func.isRequired
};
export default DateRangePicker;
