/* eslint-disable max-len, id-length, max-lines */
export default {
  applyLabel: 'Appliquer',
  price: 'Prix',
  alipay: 'AliPay',
  city: {
    INVALID_CITY: 'Les informations saisies dans ce champ sont invalides. Veuillez ré-entrer votre ville.',
    REQUIRED_FIELD: 'Veuillez entrer votre ville de facturation.'
  },
  achWire: 'Fil',
  transfer: 'Transfert',
  clear: 'Clair',
  date: { REQUIRED_FIELD: 'Veuillez sélectionner la date de l\'événement.' },
  off: 'De',
  cancelled: 'Annulé',
  noResults: 'Aucun résultat',
  country: { REQUIRED_FIELD: 'Veuillez sélectionner votre pays de résidence.' },
  cashiersCheck: 'Chèque de Caisse',
  none: 'Aucun',
  sendProposal: 'Créer une proposition',
  or: 'Ou',
  pending: 'À venir',
  searchExperiences: 'Expériences de recherche',
  searchDestinationOrExperiences: 'Rechercher des destinations ou des expériences',
  search: 'Recherche',
  filters: 'Filtres',
  today: 'Aujourd\'hui',
  tomorrow: 'Demain',
  thisWeek: 'Cette semaine',
  refinements: {
    specialCouponCategories: 'Offres spéciales',
    durationTimingV2: 'Heure du jour/durée',
    webCategoriesMain: 'Taper',
    durationTiming: 'Durée',
    eventPrice: 'Prix',
    displayPrice: 'Prix',
    availableDates: 'Rendez-vous',
    country: 'Pays',
    promotionTag: 'Promotions',
    city: 'Destination',
    categoryTheme: 'Catégorie',
    audience: 'Bon pour',
    priceBetween: 'Entre %s et %s',
    priceBelow: 'En dessous de %s',
    priceAbove: 'Au-dessus de %s',
    dateBetween: 'Entre %s et %s',
    dateBefore: 'Avant %s',
    dateAfter: 'Après %s',
    brandTag: 'Marque',
    hierarchical: { lvl0: 'Catégories' }
  },
  from: 'Depuis',
  to: 'Pour',
  duration: 'Durée',
  clearAllFilters: 'Effacer tous les filtres',
  recommended: 'Recommandé',
  filterByPrice: 'Filtrer par prix',
  lowToHigh: 'Prix croissant',
  highToLow: 'Prix - Élevé à bas',
  and: ' et',
  sortBy: 'Trier par',
  showLess: 'Montrer moins',
  showMore: 'Montre plus',
  showResults: 'Montrer les résultats',
  creditCardExternal: '%s payé par carte de crédit externe',
  loadMore: 'Charger plus',
  companyBalance: 'Solde de l\'entreprise',
  remove: 'Retirer',
  accepted: 'Accepté',
  denied: 'Refusé',
  likelyToSellOut: 'Susceptible de se vendre',
  noOfRecentBookings: '%s Réservations récentes',
  startDate: 'Date de début',
  endDate: 'Date de fin'
};
