/* eslint-disable max-lines,import/no-unresolved */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import Grid from '@mui/material/Grid';
import { getElasticSearchClient } from '../../elasticSearch/index';
import ClearRefinements from '../Algolia/ClearRefinements';
import classNames from 'classnames';
import {
  DEFAULT_ALIASES,
  DEFAULT_EXPANDED,
  DEFAULT_EXPANDED_V2,
  HIERARCHICAL_LIST_CONFIG,
  INACTIVE_REFINEMENTS_TO_HIDE,
  RANGE_LIST_CONFIG,
  REFINEMENT_LIST_CONFIG,
  REFINEMENT_LIST_CONFIG_V2,
  REFINEMENT_LIST_CONFIG_V3,
  SEARCH_EXPERIENCES_FILTER_DEFAULT_MAX_HEIGHT,
  SEARCH_EXPERIENCES_FILTER_DEFAULT_SM_HEIGHT,
  SEARCH_EXPERIENCES_FILTER_EXCLUDE_VALUES,
  DEFAULT_FEATURED_FILTERS,
  DEFAULT_FEATURED_FILTERS_FOR_HIERARCHICAL,
  UTM_PARAMETERS
} from '../../lib/shared/enums';
import withAbTest from '../../lib/withAbTest';
import { Text } from 'react-localize';
import {
  isHideFilters,
  isWidthSmDown,
  isWidthXs
} from '../../lib/width';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import qs from 'querystring';
import { isTrue } from '../../lib/booleanCheck';
// import RangeSlider from '../Algolia/RangeSlider';
import DividerWithSpacing from '../DividerWithSpacing';
import HierarchicalMenu from '../Algolia/HierarchicalMenu';
import RefinementList from '../Algolia/RefinementList';
import ToggleSwitch from '../ToggleSwitch';
import DateRange from '../Algolia/DateRange';
import Badge from '@mui/material/Badge';
import TuneIcon from '@mui/icons-material/Tune';
import CurrentRefinements from '../Algolia/CurrentRefinements';
import moment from 'moment-timezone';
import FeaturedFilters from './FeaturedFilters';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import SortByDrawer from '../Algolia/SortByDrawer';
import Pagination from '../Algolia/Pagination';
import SearchBox from '../Algolia/SearchBox';
import { Configure, InstantSearch, QueryRuleCustomData, Stats, Index } from 'react-instantsearch-dom';
import Modal from '../Modal';
import DynamicTitle from './DynamicTitle';
import { getCorrelationId } from 'util/sendAlgoliaEvent';
// eslint-disable-next-line id-length
import _ from 'lodash';
import { styles } from './styles';
import BrandQuickFilters from '../QuickFilters/BrandQuickFilters';
import CityQuickFilters from '../QuickFilters/CityQuickFilters';
import ChooseDestinationDropdown from '../QuickFilters/ChooseDestinationDropdown';
import CruiseQuickFilters from '../QuickFilters/CruiseQuickFilters';
import CategoryQuickFilters from '../QuickFilters/CategoryQuickFilters';
import { InjectedHits } from '../Algolia/InjectedHits';
import { InjectedInfiniteHits } from '../Algolia/InjectedInfiniteHits';
import useWidth from '../../../hooks/useWidth';
import isEqual from 'lodash/isEqual';

const envTag = process.env.REACT_APP_ENV_TAG;
const CURRENT_PATHNAME = window.location.pathname;

const searchClient = getElasticSearchClient();

const getRefinementListOptions = refinementListConfig => refinementListConfig.reduce((acc, { id, subRefinement }) => {
  acc.push(id);
  (subRefinement || []).forEach(({ id: sid }) => acc.push(sid));
  return acc;
}, []).filter(val => val);
const getRangeListOptions = rangeListConfig => rangeListConfig.map(({ id, qsId }) => ({ id, qsId }));
const getHierarchicalListOptions = hierarchicalListConfig => hierarchicalListConfig.map(({ id }) => id);

const toSearchUrl = ({ configOptions, state, showPriceWithFees, showSpecialOffers, specialCouponFilterOn, chatkey,
  utmParams, hideRefinementsInUrl, selectedPage }) => {
  const {
    query,
    refinementList,
    range,
    sortBy,
    hierarchicalMenu
  } = state || {};

  return qs.stringify({
    ...utmParams,
    pageNo: selectedPage,
    query,
    sortBy,
    ...getRefinementListOptions((configOptions || {}).refinementListConfig).reduce((acc, opt) => ({
      ...acc,
      [opt]: (refinementList || {})[opt]
    }), {}),
    ...getRangeListOptions((configOptions || {}).rangeListConfig).reduce((acc, opt) => ({
      ...acc,
      [`${opt.qsId}Min`]: ((range || {})[opt.id] || {}).min,
      [`${opt.qsId}Max`]: ((range || {})[opt.id] || {}).max
    }), {}),
    ...getHierarchicalListOptions((configOptions || {}).hierarchicalListConfig).reduce((acc, opt) => ({
      ...acc,
      [opt]: (hierarchicalMenu || {})[opt]
    }), {}),
    ...chatkey && { chatkey },
    showPriceWithFees,
    ...!hideRefinementsInUrl.includes('showSpecialOffers') && showSpecialOffers,
    ...!hideRefinementsInUrl.includes('specialCouponFilterOn') && specialCouponFilterOn
  });
};

const getUrlOnlyCity = searchUrl => {
  try {
    const parsed = qs.parse((searchUrl || '').trim()
      .replace(/^[?#&]/, ''));

    const parsedKeys = Object.keys(parsed || {});
    const utmParams = ([...parsedKeys]).filter(key => UTM_PARAMETERS[key]);
    const utmParamsObj = (utmParams || []).reduce((accum, param) => {
      // eslint-disable-next-line no-param-reassign
      accum[param] = parsed[param];
      return accum;
    }, {});
    return {
      onlyCity: [...(parsedKeys || [])].filter(key => !UTM_PARAMETERS[key]).length === 1
      && 'city' in parsed && parsed.city,
      utmParams: utmParamsObj
    };
  } catch {
    return false;
  }
};

const fromSearchUrl = ({ configOptions, searchUrl, hitsPerPage, adOffset }) => {
  let state;
  if (!searchUrl) {
    return state;
  }
  try {
    const parsed = qs.parse((searchUrl || '').trim()
      .replace(/^[?#&]/, ''));
    const utmParams = (Object.keys(parsed) || []).filter(key => UTM_PARAMETERS[key]);
    const onlyUtmParams = utmParams.length === Object.keys(parsed).length;
    const utmParamsObj = (utmParams || []).reduce((accum, param) => {
      // eslint-disable-next-line no-param-reassign
      accum[param] = parsed[param];
      return accum;
    }, {});
    state = {
      onlyUtmParams,
      utmParams: utmParamsObj,
      offset: (parsed.pageNo - 1) * (hitsPerPage || 12) - (parsed.pageNo > 1 ? adOffset : 0),
      query: parsed.query,
      sortBy: parsed.sortBy,
      range: getRangeListOptions((configOptions || {}).rangeListConfig).reduce((acc, opt) => ({
        ...acc,
        ...(!isNaN(parseInt(parsed[`${opt.qsId}Min`] || '')) || !isNaN(parseInt(parsed[`${opt.qsId}Max`] || ''))) && {
          [opt.id]: {
            ...!isNaN(parseInt(parsed[`${opt.qsId}Min`] || '')) && { min: parseInt(parsed[`${opt.qsId}Min`] || '') },
            ...!isNaN(parseInt(parsed[`${opt.qsId}Max`] || '')) && { max: parseInt(parsed[`${opt.qsId}Max`] || '') }
          }
        }
      }), {}),
      refinementList: getRefinementListOptions((configOptions || {}).refinementListConfig).reduce((acc, opt) => ({
        ...acc,
        ...parsed[opt] && {
          [opt]: (Array.isArray(parsed[opt]) ? parsed[opt] : [parsed[opt]]).map(val => decodeURIComponent(val))
        }
      }), {}),
      hierarchicalMenu: getHierarchicalListOptions((configOptions || {}).hierarchicalListConfig).reduce((acc, opt) => ({
        ...acc,
        ...parsed[opt] && {
          [opt]: decodeURIComponent(parsed[opt])
        }
      }), {}),
      showPriceWithFees: isTrue(parsed.showPriceWithFees),
      showSpecialOffers: isTrue(parsed.showSpecialOffers),
      specialCouponFilterOn: isTrue(parsed.specialCouponFilterOn),
      chatkey: parsed.chatkey
    };
  } catch (err) {
    // invalid qs
  }
  return state;
};
const getDefaultSearchState = ({
  configOptions,
  city,
  maybeSearchState,
  maybeSearchUrl,
  hardRefresh,
  defaultSortByRefinement,
  permanentSearchState,
  hitsPerPage,
  adOffset }) => {
  // If only city url param is in URL, we will merge with the searchState from flags
  const { onlyCity, utmParams: defaultStateUtmParams } = getUrlOnlyCity(maybeSearchUrl);
  const { refinementList } = maybeSearchState || {};
  const defaultSearchState = {
    utmParams: defaultStateUtmParams,
    ...(maybeSearchState || {}),
    refinementList: {
      ...refinementList,
      ...(onlyCity && { city: Array.isArray(onlyCity) ? onlyCity : [onlyCity] })
    }
  };
  const searchUrlState = fromSearchUrl({
    configOptions,
    searchUrl: maybeSearchUrl,
    hitsPerPage,
    adOffset
  });
  const { onlyUtmParams } = searchUrlState || {};

  const {
    showPriceWithFees,
    showSpecialOffers,
    specialCouponFilterOn,
    chatkey,
    utmParams,
    ...searchState
  } = (hardRefresh || onlyCity || onlyUtmParams) ? (defaultSearchState || {}) :
    searchUrlState || defaultSearchState || {};
  const {
    refinementList: permRefinementList = {}
  } = permanentSearchState || {};
  const refinementListPlusCity = {
    ...permRefinementList,
    ...(city ? { city: [city] } : {})
  };
  return {
    defaultState: {
      ...searchState,
      sortBy: (searchState || {}).sortBy || defaultSortByRefinement,
      refinementList: (getRefinementListOptions((configOptions || {}).refinementListConfig) || [])
        .reduce((accum, refinement) => {
          if (refinementListPlusCity[refinement] && accum[refinement]) {
            // eslint-disable-next-line no-param-reassign
            accum[refinement] = Array.from(new Set([...refinementListPlusCity[refinement], ...accum[refinement]]));
          } else if (refinementListPlusCity[refinement]) {
            // eslint-disable-next-line no-param-reassign
            accum[refinement] = refinementListPlusCity[refinement];
          }
          return accum;
        }, (searchState || {}).refinementList || {})
    },
    showPriceWithFees,
    showSpecialOffers,
    specialCouponFilterOn,
    chatkey,
    utmParams
  };
};

function getRowSize(width, enableMobileTileVariant3) {
  if (width === 'xs') {
    return enableMobileTileVariant3 ? 2 : 1;
  } else if (width === 'sm') {
    return 2;
  }
  return 3;
}

const SearchExperiences = ({
  classes,
  searchParams,
  siteId,
  tealiumTrack,
  countryWithinDestinationEnabled,
  enableAndOperatorCategoryFilter,
  enableAlwaysShowClearFilters,
  sendAlgoliaEvent,
  setAlgoliaSearchInfo,
  abTestVariant,
  showPromoCodeEligible,
  container,
  hardRefresh,
  showInfiniteScrollOnTileListing,
  showDynamicTitle,
  showNewTextForNoResultFound,
  onExperiencesSearchParamsChange: propParamsChange,
  sortFeaturedFiltersByDate,
  enableProductsOnTheHorizon: enableProductsOnHorizonFlag,
  customFilterToggleText,
  showCustomFilterToggle,
  hideSpecialOffersToggle,
  enableDarkThemeTiles,
  showBrandQuickFilters,
  showUKCityQuickFilters,
  showFullLengthDatePickerMobile,
  showChooseDestinationDropdownMobile,
  showFullWidthMobileSortByDrawer,
  hideMobileSearchComponents,
  showCalendarFilterViewOnMobile,
  hideDatePickerPills,
  searchBarHeaderText,
  hideRefinementsInUrl,
  overrideEnvTag,
  enableMobileTileVariant3,
  showCategoryQuickFilters,
  disableCalendars,
  searchBarPlaceholderText,
  showCurrentlyViewing,
  currentLocale,
  fullScreenCalendarForMobile,
  onHitCountChange,
  enableAdTiles
}, { localize = text => text } = {}) => {
  const {
    city,
    searchIndex,
    searchState: maybeSearchState,
    featuredFilters: maybeFeaturedFilters,
    excludePartnerProducts,
    onlyOwnedProducts,
    propertyIds,
    ignoreHideInWebsiteSearch,
    hitsPerPage,
    disableScrollUp,
    recentBookingsThreshold,
    sellOutThreshold,
    tourCurrencies,
    excludedPropBookingType,
    hidePromotions = false,
    showHierarchical = false,
    showPriceWithFeesToggle = false,
    showEmbedSocialReview = false,
    allowedDisplayLabels: allowedDisplayLabelsProp = ['saveNow'],
    hideAdsChip = false,
    addCtaButton = false,
    enableRedirectAdbutton = false,
    showToursWithAvailabilityOnly = false,
    toShowCentsPropertyIds: toShowCentsPropertyIdsProp = ['hacco', 'hstatueco', 'hncco'],
    aliases = DEFAULT_ALIASES || {},
    hideRefinements = [],
    mobileQuickFilterCruiseTypes = {},
    searchExperienceV3IsEnabled,
    searchExperienceV2IsEnabled,
    keepCalendarOpen,
    startingFilterState,
    showMoreLimit,
    hiddenRefinementLists,
    permanentSearchState,
    customInclusiveCalendarEndDate,
    customInclusiveCalendarStartDate,
    overrideCalendarStartMonth,
    blockCalendarChange,
    overrideShownMonths,
    availableCities,
    onlyShowSpecialFilterCouponProducts,
    defaultSortOption,
    overrideModalToHighlightColors,
    allowFilterDatesWithPermalink,
    specialCouponNonFilterCategory: couponCatsToShow = []
  } = searchParams || {};
  // Winner of AB test - always enable
  const enableMobileTileVariant2Flag = true;
  const onExperiencesSearchParamsChange = ({ searchUrl, searchState }) => {
    window.history.pushState(searchState, 'Search Results', `?${searchUrl}`);
    propParamsChange({ searchUrl, searchState });
  };
  const isShowToursWithAvailabilityOnly = isTrue(showToursWithAvailabilityOnly);
  const shouldHideAdlabels = isTrue(hideAdsChip);
  const shouldAddCtaButton = isTrue(addCtaButton);
  const shouldRedirectAd = isTrue(enableRedirectAdbutton);
  const maybeSearchUrl = window.location.search;
  const onScrollUp = () => {
    document.getElementById(container).scrollIntoView({ behavior: 'smooth' });
  };

  const width = useWidth();
  const isMobileView = isHideFilters(width);
  const isXsView = isWidthXs(width);
  const isWidthSmDownView = isWidthSmDown(width);

  let configOptions = {
    // eslint-disable-next-line no-nested-ternary
    refinementListConfig: searchExperienceV2IsEnabled ? REFINEMENT_LIST_CONFIG_V2 :
      (searchExperienceV3IsEnabled ? REFINEMENT_LIST_CONFIG_V3 : REFINEMENT_LIST_CONFIG),
    rangeListConfig: RANGE_LIST_CONFIG,
    hierarchicalListConfig: HIERARCHICAL_LIST_CONFIG,
    defaultExpanded: (searchExperienceV2IsEnabled || searchExperienceV3IsEnabled) ?
      DEFAULT_EXPANDED_V2 : DEFAULT_EXPANDED
  };

  if (hidePromotions) {
    configOptions = {
      ...configOptions,
      refinementListConfig: configOptions.refinementListConfig.filter(item => item.id !== 'promotionTag')
    };
  }
  if (showHierarchical) {
    configOptions = {
      ...configOptions,
      refinementListConfig: configOptions.refinementListConfig
        .filter(item => !['categoryTheme', 'webCategoriesMain'].includes(item.id))
    };
  }
  if (searchExperienceV3IsEnabled) {
    configOptions = {
      ...configOptions,
      refinementListConfig: configOptions.refinementListConfig
        .filter(item => !['audience'].includes(item.id))
    };
  }
  if (hideRefinementsInUrl && hideRefinementsInUrl.length) {
    configOptions = Object.entries(configOptions).reduce((accum, [key, value]) => {
      if (key === 'defaultExpanded') {
        // eslint-disable-next-line no-param-reassign
        accum[key] = value;
      } else {
        // eslint-disable-next-line no-param-reassign
        accum[key] = (value || []).filter(({ id }) => !hideRefinementsInUrl.includes(id));
      }
      return accum;
    }, {});
  }

  const elasticSearchIndex = `${overrideEnvTag || envTag}_${searchIndex || 'ce_experiences'}`;

  const sortOptions = [
    {
      value: `${elasticSearchIndex}`,
      label: localize('recommended'),
      hideZeroPriceTours: false
    },
    {
      value: `${elasticSearchIndex}_price_asc`,
      label: localize('lowToHigh'),
      hideZeroPriceTours: true
    },
    {
      value: `${elasticSearchIndex}_price_desc`,
      label: localize('highToLow'),
      hideZeroPriceTours: false
    }
  ];

  const sortOptionByGeo = {
    value: `${elasticSearchIndex}_geo`,
    label: localize('closestToFarthest'),
    hideZeroPriceTours: false
  };

  const defaultSortByRefinement = defaultSortOption ? defaultSortOption : sortOptions[0].value;

  const featuredFilters = maybeFeaturedFilters ||
      (showHierarchical ? DEFAULT_FEATURED_FILTERS_FOR_HIERARCHICAL : DEFAULT_FEATURED_FILTERS);

  // eslint-disable-next-line no-nested-ternary
  const [adOffset, setAdOffset] = useState(enableAdTiles ? (isWidthSmDownView ? 1 : 2) : 0);

  const {
    defaultState,
    showPriceWithFees: defaultShowPriceWithFees,
    showSpecialOffers: defaultShowSpecialOffers,
    specialCouponFilterOn: defaultSpecialCouponFilterOn,
    chatkey,
    utmParams: defaultUtmParams
  } = getDefaultSearchState({
    configOptions,
    city,
    maybeSearchState,
    maybeSearchUrl,
    hardRefresh,
    defaultSortByRefinement,
    permanentSearchState,
    hitsPerPage,
    adOffset });
  const defaultExpandedFromDefaultState = getRefinementListOptions(configOptions.refinementListConfig)
    .reduce((acc, opt) => ({
      ...acc,
      [opt]: ((defaultState.refinementList || {})[opt] || []).length > 0
    }), {});
  const defaultExpanded = {
    ...defaultExpandedFromDefaultState,
    ...configOptions.refinementListConfig,
    ...startingFilterState
  };
  const defaultSelectedDate = ((defaultState || {}).range || {}).availableDates || {};
  const [searchState, setSearchState] = useState(defaultState);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [filtersSortByOpen, setFiltersSortByOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState(defaultExpanded);
  const [showPriceWithFees, _setShowPriceWithFees] = useState(!!defaultShowPriceWithFees);
  const [showSpecialOffers, _setShowSpecialOffers] = useState(!!defaultShowSpecialOffers);
  const [specialCouponFilterOn, _setSpecialCouponFilterOn] = useState(!!defaultSpecialCouponFilterOn);
  const [clearSearchTerm, setClearSearchTerm] = useState(false);
  const [hasSearchTerm, setHasSearchTerm] = useState(false);
  const [itemSelectedButNotExpanded, setItemSelectedButNotExpanded] = useState({});
  const [fixedHeight, setFixedHeight] = useState(true);
  const [selectedDate, setSelectedDate] = useState(defaultSelectedDate);
  const [showNoResult, setShowNoResult] = useState(false);
  const [numberOfHits, setNumberOfHits] = useState(0);
  const [sortByDrawerOpen, setSortByDrawerOpen] = useState(false);
  const [mobileFullWidthSortByOpen, setMobileFullWidthSortByOpen] = useState(false);
  const [rulesCustomData, setRulesCustomData] = useState({});
  const [sortOpt, setSortOpt] = useState(sortOptions);
  const [activeRefinements, _setActiveRefinements] = useState(new Set());
  const [availableBrands, setAvailableBrands] = useState([]);
  const [utmParams] = useState(defaultUtmParams);
  const [numberOfPages, setNumberOfPages] = useState(Math.ceil(numberOfHits / (hitsPerPage || 12)));
  const [selectedPage, setSelectedPage] = useState(1);
  const [rowSize, setRowSize] = useState(getRowSize(width, enableMobileTileVariant3));
  const currentTime = useMemo(() => moment().unix(), []);
  const [allowedDisplayLabels, setAllowedDisplayLabels] = useState(allowedDisplayLabelsProp);
  const [toShowCentsPropertyIds, setToShowCentsPropertyIds] = useState([]);

  useEffect(() => {
    if (!isEqual(allowedDisplayLabelsProp, allowedDisplayLabels)) {
      setAllowedDisplayLabels(allowedDisplayLabelsProp);
    }
  }, [allowedDisplayLabelsProp]);

  useEffect(() => {
    if (!isEqual(toShowCentsPropertyIdsProp, toShowCentsPropertyIds)) {
      setToShowCentsPropertyIds(toShowCentsPropertyIdsProp);
    }
  }, [toShowCentsPropertyIdsProp]);

  useEffect(() => {
    if (typeof onHitCountChange === 'function') {
      try {
        onHitCountChange(numberOfHits);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('something went wrong with onHitCountChange callback', err);
      }
    }
  }, [numberOfHits]);

  useEffect(() => {
    onSearchStateChange(defaultState);
  }, []);

  useEffect(() => {
    if (enableAdTiles) {
      setRowSize(getRowSize(width, enableMobileTileVariant3));
    }
  }, [width]);

  useEffect(() => {
    if (enableAdTiles) {
      setAdOffset(isWidthSmDownView ? 1 : 2);
    }
  }, [rowSize]);

  // Rules from Algolia - can be configured by index
  const {
    enableProductsOnHorizon = false,
    exclusiveDealBannerText = ''
  } = Array.isArray(rulesCustomData) ?
    (rulesCustomData || []).reduce((accum, rule) => (
      { ...accum, ...rule }
    ), {}) : {};
  const resetDefaultState = () => {
    const hardResetState = getDefaultSearchState({
      configOptions,
      city,
      maybeSearchState,
      maybeSearchUrl,
      hardRefresh: true,
      permanentSearchState,
      defaultSortByRefinement,
      hitsPerPage,
      adOffset
    });
    const { defaultState: resetState } = hardResetState || {};
    onSearchStateChange(resetState);
  };
  const setShowPriceWithFees = value => {
    _setShowPriceWithFees(value);
    const updatedToggleState = { ...searchState, showPriceWithFees: value };
    setSearchState(updatedToggleState);
    onExperiencesSearchParamsChange({
      searchState: updatedToggleState,
      searchUrl: toSearchUrl({
        configOptions,
        state: updatedToggleState,
        showPriceWithFees: value,
        chatkey,
        utmParams,
        hideRefinementsInUrl,
        selectedPage
      })
    });
  };
  const setShowSpecialOffers = value => {
    _setShowSpecialOffers(value);
    const updatedToggleState = { ...searchState, showSpecialOffers: value };
    setSearchState(updatedToggleState);
    onExperiencesSearchParamsChange({
      searchState: updatedToggleState,
      searchUrl: toSearchUrl({
        configOptions,
        state: updatedToggleState,
        showSpecialOffers: value,
        chatkey,
        utmParams,
        hideRefinementsInUrl,
        selectedPage
      })
    });
  };
  const setSpecialCouponFilterOn = value => {
    _setSpecialCouponFilterOn(value);
    const updatedToggleState = { ...searchState, specialCouponFilterOn: value };
    setSearchState(updatedToggleState);
    onExperiencesSearchParamsChange({
      searchState: updatedToggleState,
      searchUrl: toSearchUrl({
        configOptions,
        state: updatedToggleState,
        specialCouponFilterOn: value,
        chatkey,
        utmParams,
        hideRefinementsInUrl,
        selectedPage
      })
    });
  };
  const setActiveRefinements = refinements => {
    const newActive = new Set(refinements);
    _setActiveRefinements(newActive);
  };

  const shouldUseSearchState = updatedState => {
    const {
      range: {
        availableDates: {
          min,
          max
        } = {}
      } = {}
    } = updatedState || {};
    return (!updatedState.range.availableDates || (!min && !max)) && searchState.range.availableDates;
  };

  const onSearchStateChange = updatedState => {
    const tempList = (((updatedState || {}).refinementList || {}).searchQuery || [])
      .filter(val => !((updatedState || {}).query || '').includes(val));
    // eslint-disable-next-line no-param-reassign
    updatedState = {
      ...updatedState,
      ...!updatedState.refinementList && searchState.refinementList && {
        refinementList: searchState.refinementList
      },
      ...updatedState.refinementList && searchState.refinementList && searchState.refinementList.searchQuery && {
        refinementList: {
          ...updatedState.refinementList,
          searchQuery: (updatedState.refinementList.searchQuery || [])
            .filter(searchType => !tempList.includes(searchType))
        }
      },
      ...!updatedState.range && searchState.range && {
        range: searchState.range
      },
      ...updatedState.range && searchState.range && {
        range: {
          ...updatedState.range,
          ...shouldUseSearchState(updatedState) && {
            availableDates: searchState.range.availableDates
          },
          ...!updatedState.range.displayPrice && searchState.range.displayPrice && {
            displayPrice: searchState.range.displayPrice
          }
        }
      },
      ...!updatedState.hierarchicalMenu && searchState.hierarchicalMenu && {
        hierarchicalMenu: searchState.hierarchicalMenu
      },
      ...!updatedState.sortBy && searchState.sortBy && {
        sortBy: searchState.sortBy
      }
    };
    setSearchState(updatedState);
    const { refinementList: { city: refinementListCity = [] } = {}, sortBy = '' } = updatedState;
    const sortByGeo = sortBy.includes('geo');
    if (!(refinementListCity.length) || refinementListCity.length > 1) {
      const updatedSortOptions = sortOptions.concat(sortOptionByGeo);
      setSortOpt(updatedSortOptions);
    } else if (!sortByGeo) {
      setSortOpt(sortOptions);
      getDefaultSearchState({
        configOptions,
        city,
        maybeSearchState,
        maybeSearchUrl,
        hardRefresh: true,
        permanentSearchState,
        defaultSortByRefinement,
        hitsPerPage,
        adOffset
      });
    }
    onExperiencesSearchParamsChange({
      searchState: updatedState,
      searchUrl: toSearchUrl({
        configOptions,
        state: updatedState,
        showPriceWithFees,
        showSpecialOffers,
        specialCouponFilterOn,
        chatkey,
        utmParams,
        hideRefinementsInUrl,
        selectedPage
      })
    });

    if (searchExperienceV3IsEnabled && !isWidthSmDownView) {
      const node = document.getElementById('searchExperiencesFilterBar');
      const resultNode = document.getElementById('searchExperiencesResultContainer');
      if (node && resultNode) {
        const widgetPosTop = window.WIDGET_POSITION_TOP;
        if (widgetPosTop && widgetPosTop < -100) {
          node.style.top = '0px';
          resultNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        const { height } = node.getBoundingClientRect();
        if (height && height > SEARCH_EXPERIENCES_FILTER_DEFAULT_MAX_HEIGHT) {
          node.style.top = '0px';
          node.style.maxHeight = `${SEARCH_EXPERIENCES_FILTER_DEFAULT_MAX_HEIGHT}px`;
          node.style.height = SEARCH_EXPERIENCES_FILTER_DEFAULT_SM_HEIGHT;
        }
      }
    }
  };

  const toggleExpandedSection = section => () => {
    if (expandedSection[section] && configOptions && configOptions.refinementListConfig) {
      const hasSub = (configOptions.refinementListConfig.find(({ id }) => id === section) || {}).subRefinement;

      if (hasSub) {
        const subItemSelected = (hasSub || []).some(({ id }) => itemSelectedButNotExpanded[id]);
        if (subItemSelected) {
          const req = (hasSub || []).reduce((accum, { id }) => ({ ...accum, [id]: false }), {});
          setExpandedSection({
            ...expandedSection,
            ...req
          });
          return;
        }
      }
    }

    setExpandedSection({
      ...expandedSection,
      [section]: !expandedSection[section]
    });
  };

  const toggleItemSelectedButNotExpanded = ({ opt, value }) => {
    if (searchExperienceV3IsEnabled && itemSelectedButNotExpanded[opt] !== value) {
      setItemSelectedButNotExpanded({
        ...itemSelectedButNotExpanded,
        [opt]: value
      });
    }
  };

  const {
    hideZeroPriceTours
  } = sortOpt.find(({ value }) => value === searchState.sortBy) || {};

  const displayPriceFilter = (
    <Fragment>
      <Grid container justifyContent={'space-between'} alignItems={'center'}
        onClick={toggleExpandedSection('displayPrice')} className={classes.click}>
        <Grid item>
          <Typography variant={'body1'}
            className={searchExperienceV3IsEnabled ? classes.titleV2 : classes.title} gutterBottom>
            <Text message={'refinements.displayPrice'}/>
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={toggleExpandedSection('displayPrice')}
          >
            {expandedSection.displayPrice ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={expandedSection.displayPrice}>
        {/* <RangeSlider attribute={'displayPrice'} tealiumTrack={tealiumTrack}/> */}
      </Collapse>
    </Fragment>
  );

  const displayHierarchicalFilters = showHierarchical && configOptions &&
    configOptions.hierarchicalListConfig
      .map(({ id: opt, hidden, trackingLabel, sortAlphabetical }) => (
        <div key={`hierarchical-list-${opt}`} className={classNames({ [classes.hidden]: hidden })}>
          <Grid container justifyContent={'space-between'} alignItems={'center'}
            onClick={toggleExpandedSection(opt)} className={classes.click}>
            <Grid item>
              <Typography variant={'body1'}
                className={searchExperienceV3IsEnabled ? classes.titleV2 : classes.title}>
                <Text message={`refinements.${opt}`}/>
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={toggleExpandedSection(opt)}
              >
                {!searchExperienceV3IsEnabled && (expandedSection[opt] ? <ExpandLessIcon/> : <ExpandMoreIcon/>)}
                {searchExperienceV3IsEnabled && (expandedSection[opt] ? <ExpandMoreIcon/> : <NavigateNextIcon/>)}
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={expandedSection[opt]}>
            <HierarchicalMenu
              attributes={[
                'hierarchical.lvl0',
                'hierarchical.lvl1',
                'hierarchical.lvl2',
                'hierarchical.lvl3'
              ]}
              limit={100}
              enableShowMore
              showMoreLimit={showMoreLimit || 7}
              showSearch={!!(((configOptions || {})
                .refinementListConfig || []).find(({ id }) => id === opt) || {}).searchable
              }
              sortAlphabetical={sortAlphabetical}
              trackingLabel={trackingLabel}
              tealiumTrack={tealiumTrack}
              searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
            />
          </Collapse>
          <DividerWithSpacing spacing={16}/>
        </div>
      ));

  const renderRefinementList = ({
    opt,
    itemSelected,
    sortAlphabetical,
    trackingLabel,
    searchable,
    isAdditionalRefinement,
    sortByCount,
    operator = 'or',
    overrideDarkThemeText
  }) =>
    <RefinementList
      limit={100}
      enableShowMore
      showMoreLimit={showMoreLimit || 7}
      attribute={opt}
      showSearch={searchable}
      showSelectedOnly={itemSelected}
      sortAlphabetical={sortAlphabetical}
      trackingLabel={trackingLabel}
      tealiumTrack={tealiumTrack}
      isMobileView={isMobileView}
      opt={opt}
      itemSelected={itemSelected}
      operator={operator}
      toggleItemSelectedButNotExpanded={searchExperienceV3IsEnabled && toggleItemSelectedButNotExpanded}
      searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
      isAdditionalRefinement={isAdditionalRefinement || false}
      sortByCount={sortByCount}
      maybeSearchState={maybeSearchState}
      permanentSearchState={permanentSearchState}
      overrideDarkThemeText={overrideDarkThemeText}
      enableDarkThemeTiles={enableDarkThemeTiles}
      availableBrands={availableBrands}
      setAvailableBrands={setAvailableBrands}
      exclusiveDealBannerText={exclusiveDealBannerText}
    />;

  const customRefinement = args => {
    const { opt, trackingLabel, hidden, sortAlphabetical, subRefinement,
      additionalRefinement, customRefinementLabel, sortByCount } = args || {};
    const isExpanded = expandedSection[opt];
    const itemSelected = searchExperienceV3IsEnabled && itemSelectedButNotExpanded[opt] && !isExpanded;
    const operator = ((enableAndOperatorCategoryFilter && opt === 'categoryTheme') ||
      ((permanentSearchState || {}).refinementList || {})[opt]) ? 'and' : 'or';
    return <div key={`refinement-list-${opt}`}
      className={classNames({
        [classes.hidden]: hidden || hideRefinements.includes(opt) ||
        (showNewTextForNoResultFound && (numberOfHits <= 0 || showNoResult)) ||
        (!activeRefinements.has(opt) && INACTIVE_REFINEMENTS_TO_HIDE[opt])
      })}>
      <Grid container alignItems={'center'} justifyContent={'space-between'}
        onClick={toggleExpandedSection(opt)} className={classes.click}>
        <Grid item>
          <Typography variant={'body1'}
            className={searchExperienceV3IsEnabled ? classes.titleV2 : classes.title}>
            <Text message={customRefinementLabel ? `refinements.${customRefinementLabel}` : `refinements.${opt}`}/>
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={toggleExpandedSection(opt)}
            aria-label={`toggle ${opt} filter list`}
          >
            {!searchExperienceV3IsEnabled && (isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>)}
            {searchExperienceV3IsEnabled && (isExpanded ? <ExpandMoreIcon/> : <NavigateNextIcon/>)}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={isExpanded || itemSelected}
        className={classNames({
          [classes.collapseContainer]: expandedSection.destination && opt === 'city',
          [classes.hidden]: showNoResult
        })}>
        <Grid container direction="row" alignItems={'center'} justifyContent={isMobileView ? 'flex-end' : ''}>
          <Grid item sm={1}/>
          <Grid item sm={11} className={classes.destinationContainer}>
            {countryWithinDestinationEnabled && subRefinement && subRefinement.map(subRefine => customRefinement({
              ...subRefine,
              opt: subRefine.id
            }))
            }
          </Grid>
        </Grid>
        {/* Render additional refinement list for nested refinements */}
        { additionalRefinement && additionalRefinement.id && searchExperienceV3IsEnabled && (
          <>
            {renderRefinementList({
              opt: additionalRefinement.id,
              itemSelected,
              trackingLabel: additionalRefinement.trackingLabel,
              isAdditionalRefinement: true,
              sortByCount,
              sortAlphabetical: additionalRefinement.sortAlphabetical || false,
              searchable: !!(((configOptions || {}).refinementListConfig
                .find(({ id }) => id === opt) || {}).additionalRefinement || {}).searchable,
              operator
            })}
          </>
        )
        }
        {/* Default refinement list */}
        {renderRefinementList({
          opt,
          itemSelected,
          sortByCount,
          trackingLabel,
          sortAlphabetical,
          searchable: !!((configOptions || {}).refinementListConfig
            .find(({ id }) => id === opt) || {}).searchable,
          operator
        })}
      </Collapse>
      {(opt !== 'city' || searchExperienceV3IsEnabled) && <DividerWithSpacing spacing={8}/>}
    </div>;
  };

  const renderShowPriceWithFees = <ToggleSwitch
    input={{
      value: showPriceWithFees,
      onChange: () => {
        setShowPriceWithFees(!showPriceWithFees);
        if (typeof tealiumTrack === 'function') {
          tealiumTrack({
            category: 'search',
            action: 'show_price_with_fees',
            name: 'search',
            label: !showPriceWithFees
          });
        }
      }
    }}
    label={<div>
      <Typography variant={'body1'} className={searchExperienceV3IsEnabled ? classes.titleV2 : classes.title}>
        <Text message={localize('refinements.showPriceWithFees')} /></Typography>
      <Typography gutterBottom variant={'caption'} color={'textSecondary'} className={classes.title}>
        <Text message={localize('refinements.includesTaxesFees')} /></Typography>
    </div>}
    color={searchExperienceV3IsEnabled ? 'primary' : 'secondary'}
  />;
  const renderShowSpecialOffers = <ToggleSwitch
    input={{
      value: showSpecialOffers,
      onChange: () => {
        setShowSpecialOffers(!showSpecialOffers);
        if (typeof tealiumTrack === 'function') {
          tealiumTrack({
            category: 'search',
            action: 'show_special_offers',
            name: 'search',
            label: !showSpecialOffers
          });
        }
      }
    }}
    label={<div>
      <Typography variant="body2" className={classNames(classes.titleV2,
        { [classes.specialOffersToggleMobile]: isWidthSmDownView })}>
        <Text message={localize('refinements.containsSpecialCoupon')} /></Typography>
    </div>}
    color="primary"
  />;

  const renderShowSpecialCoupon = <div>
    <ToggleSwitch
      input={{
        value: specialCouponFilterOn,
        onChange: () => {
          setSpecialCouponFilterOn(!specialCouponFilterOn);
        }
      }}
      label={<div className={classes.specialCouponLabelContainer}>
        <Typography variant="body2" className={classNames(classes.titleV2,
          { [classes.specialOffersToggleMobile]: isWidthSmDownView })}>
          <Text message={customFilterToggleText} /></Typography>
        <div className={classes.newLabel}>
          <Typography variant="caption"><Text message={'NEW'}/></Typography>
        </div>
      </div>}
      color="primary"
    />
  </div>;

  const filtersView = (
    <div id="searchExperiencesFilterBar"
      className={searchExperienceV3IsEnabled && !isMobileView ? classes.stickyContainer : ''}>
      {!searchExperienceV2IsEnabled && !searchExperienceV3IsEnabled && displayPriceFilter}
      {searchExperienceV3IsEnabled && <div className={classes.hidden}>{displayPriceFilter}</div>}
      {!searchExperienceV3IsEnabled && <DividerWithSpacing spacing={8}/>}
      { showPriceWithFeesToggle && !searchExperienceV3IsEnabled && renderShowPriceWithFees}

      {!searchExperienceV3IsEnabled &&
          <Grid container justifyContent={'space-between'} alignItems={'center'}
            onClick={toggleExpandedSection('availableDates')} className={classes.click}>
            <Grid item>
              <Typography variant={'body1'}
                className={searchExperienceV3IsEnabled ? classes.titleV2 : classes.title}>
                <Text message={'refinements.availableDates'}/>
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={toggleExpandedSection('availableDates')}>
                {expandedSection.availableDates ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
              </IconButton>
            </Grid>
          </Grid>
      }
      <Collapse in={expandedSection.availableDates || searchExperienceV3IsEnabled}>
        <DateRange attribute={'availableDates'} tealiumTrack={tealiumTrack}
          isMobileView={isMobileView}
          isWidthSmDownView={isWidthSmDownView}
          searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
          selectedDate={searchExperienceV3IsEnabled && selectedDate}
          setSelectedDate={searchExperienceV3IsEnabled ? setSelectedDate : () => {}}
          setShowNoResult={searchExperienceV3IsEnabled ? setShowNoResult : () => {}}
          customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
          customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
          blockCalendarChange={blockCalendarChange}
          overrideShownMonths={overrideShownMonths}
          overrideCalendarStartMonth={overrideCalendarStartMonth}
          hideDatePickerPills={hideDatePickerPills}
          disableCalendars={disableCalendars}
        />

      </Collapse>

      {!searchExperienceV3IsEnabled && <DividerWithSpacing spacing={8}/>}

      { searchExperienceV3IsEnabled && !hideSpecialOffersToggle && renderShowSpecialOffers}
      { searchExperienceV3IsEnabled && showCustomFilterToggle && renderShowSpecialCoupon}
      { searchExperienceV3IsEnabled && <DividerWithSpacing spacing={8}/>}
      { showPriceWithFeesToggle && searchExperienceV3IsEnabled && renderShowPriceWithFees}
      { showPriceWithFeesToggle && searchExperienceV3IsEnabled && <DividerWithSpacing spacing={8}/>}

      {(configOptions && configOptions.refinementListConfig) &&
            configOptions.refinementListConfig
              .filter(refine =>
                !(searchExperienceV2IsEnabled && countryWithinDestinationEnabled &&
                    (refine.trackingLabel === 'country' || refine.trackingLabel === 'destination'))
                     && !(hiddenRefinementLists || []).includes(refine.id))
              .map(({
                id: opt,
                hidden,
                trackingLabel,
                sortAlphabetical,
                subRefinement,
                injectHierarchical,
                additionalRefinement,
                sortByCount,
                customRefinementLabel
              }) => (injectHierarchical ?
                displayHierarchicalFilters :
                customRefinement({
                  opt,
                  trackingLabel,
                  hidden,
                  sortAlphabetical,
                  subRefinement,
                  sortByCount,
                  additionalRefinement,
                  customRefinementLabel
                }))
              )}

      {searchExperienceV2IsEnabled && !searchExperienceV3IsEnabled && displayPriceFilter}
    </div>
  );
  const currentRefinements = (
    <CurrentRefinements
      transformLabels={{
        displayPrice: ({ label }) => {
          if (!`${label}`.indexOf('<=')) {
            return label;
          }

          const [val1, val2, val3] = label.split('<=')
            .map(val => val.trim());

          if (val3) {
            return localize('refinements.priceBetween', [val1, val3]);
          }
          if (val1 === 'displayPrice') {
            return localize('refinements.priceBelow', [val2]);
          }
          return localize('refinements.priceAbove', [val1]);
        },
        availableDates: ({ label }) => {
          if (!`${label}`.indexOf('<=')) {
            return label;
          }

          const formatDate = dt => moment(dt, 'YYYYMMDD')
            .format('DD MMM YYYY');

          const [val1, val2, val3] = label.split('<=')
            .map(val => val.trim());

          if (val3) {
            return localize('refinements.dateBetween', [formatDate(val1), formatDate(val3)]);
          }
          if (val1 === 'availableDates') {
            return localize('refinements.dateBefore', [formatDate(val2)]);
          }
          return localize('refinements.dateAfter', [formatDate(val1)]);
        },
        'hierarchical.lvl0': ({ label = '' }) => {
          const [, val2] = label.split(':').map(val => val.trim());
          return val2;
        }
      }}
    />
  );

  const getFilterCount = () => {
    const {
      refinementList,
      range,
      hierarchicalMenu
    } = searchState;
    let count = 0;

    count += (configOptions || {}).refinementListConfig
      .filter(({ id }) => !hideRefinements.includes(id))
      .map(opt => (refinementList || {})[opt.id] || [])
      .map(opt => (opt || []).filter(val => !searchExperienceV3IsEnabled ||
            (searchExperienceV3IsEnabled && !SEARCH_EXPERIENCES_FILTER_EXCLUDE_VALUES.includes(val || ''))).length)
      .reduce((acc, opt) => acc + opt, 0);

    const checkRange = opt => {
      const min = isNaN(parseInt(((range || {})[opt.id] || {}).min));
      const max = isNaN(parseInt(((range || {})[opt.id] || {}).max));
      return min && max ? 0 : 1;
    };

    count += (configOptions || {}).rangeListConfig.map(opt => checkRange(opt))
      .reduce((acc, opt) => acc + opt, 0);

    count += (configOptions || {}).hierarchicalListConfig
      .map(opt => (hierarchicalMenu || {})[opt.id] || '')
      .map(opt => (opt.length ? 1 : 0))
      .reduce((acc, opt) => acc + opt, 0);

    count += showPriceWithFees;
    count += showSpecialOffers;
    count += specialCouponFilterOn;
    return count;
  };

  const createFilterButton = ({ inline = false, textDark = false }) => {
    const count = getFilterCount();

    if (inline) {
      return <div
        data-bdd={'mobile-filters-button'}
        className={classes.filterButtonV2}
        onClick={() => {
          onScrollUp();
          setFiltersModalOpen(true);
        }}>
        <TuneIcon className={classes.filterItem} color={'textPrimary'}/>
        <Badge badgeContent={count} max={999} showZero={false}
          classes={{
            root: classes.filterBadgeRoot,
            badge: classNames(classes.filterBadgeCount, count === 0 && classes.filterBadgeCountInvisible) }}>
          <Typography variant={isXsView ? 'caption' : 'subtitle1'}
            color={textDark ? 'black' : 'textPrimary'}
            className="ce-search-filters-label">Filters</Typography>
        </Badge>
      </div>;
    }

    return <Button
      data-bdd={'mobile-filters-button'}
      color="primary"
      className={classes.parentIcon}
      onClick={() => {
        onScrollUp();
        setFiltersModalOpen(true);
      }}>

      <div className={classes.borderIcon}>
        <Badge badgeContent={count} max={999} color="primary">
          <TuneIcon className={classes.leftIcon}/>
        </Badge>
      </div>
    </Button>;
  };
  const sortByFilter = () => {
    setFiltersSortByOpen(!filtersSortByOpen);
  };
  const sortByLabel = sortOpt.reduce((accum, opt) => {
    if (opt.value === searchState.sortBy) {
      // eslint-disable-next-line no-param-reassign
      accum = opt.label;
    }
    return accum;
  }, '');

  const renderInfiniteHits = hitsProps => (
    <InjectedInfiniteHits
      slots={() => [
        {
          injectAt: ({ hitsFromSlotIndex, position }) => validateInjectionPosition({ hitsFromSlotIndex, position }),
          getHits: ({ resultsByIndex }) => (resultsByIndex.prodv3_ce_websearch?._rawResults || [])
            .reduce((acc, results) => acc.concat(results.hits), []) || [],
          injectionType: 'prodv3_ce_websearch'
        }
      ]}
      currentLocale={currentLocale}
      enableDarkThemeTiles={enableDarkThemeTiles}
      showCurrentlyViewing={showCurrentlyViewing}
      elasticSearchIndex={elasticSearchIndex}
      chatkey={chatkey}
      showPromoCodeEligible={showPromoCodeEligible}
      siteId={siteId}
      abTestVariant={abTestVariant}
      tealiumTrack={tealiumTrack}
      isMobileView={isMobileView}
      recentBookingsThreshold={recentBookingsThreshold}
      sellOutThreshold={sellOutThreshold}
      allowFilterDatesWithPermalink={allowFilterDatesWithPermalink}
      tourCurrencies={tourCurrencies}
      showPriceWithFees={showPriceWithFees}
      shouldHideAdlabels={shouldHideAdlabels}
      shouldAddCtaButton={shouldAddCtaButton}
      showEmbedSocialReview={showEmbedSocialReview && searchExperienceV3IsEnabled}
      allowedDisplayLabels={allowedDisplayLabels}
      searchIndex={elasticSearchIndex}
      sendAlgoliaEvent={sendAlgoliaEvent}
      setAlgoliaSearchInfo={setAlgoliaSearchInfo}
      shouldRedirectAd={shouldRedirectAd}
      searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
      showToursWithAvailabilityOnly={isShowToursWithAvailabilityOnly}
      fixedHeight={fixedHeight}
      setFixedHeight={setFixedHeight}
      toShowCentsPropertyIds={searchExperienceV3IsEnabled && toShowCentsPropertyIds}
      aliases={aliases}
      showNoResult={searchExperienceV3IsEnabled && showNoResult}
      hitsPerPage={hitsPerPage}
      disableScrollUp={disableScrollUp}
      onScrollUp={onScrollUp}
      enableProductsOnHorizon={ enableProductsOnHorizon && enableProductsOnHorizonFlag}
      searchState={searchState}
      showNewTextForNoResultFound={showNewTextForNoResultFound}
      hasSearchTerm={hasSearchTerm}
      selectedDate={selectedDate}
      setActiveRefinements={setActiveRefinements}
      enableMobileTileVariant2={enableMobileTileVariant2Flag}
      enableMobileTileVariant3={enableMobileTileVariant3}
      isXsView={isXsView}
      exclusiveDealBannerText={exclusiveDealBannerText}
      enableAdTiles={enableAdTiles}
      displacement={isWidthSmDownView ? 1 : 2}
      numberOfHits={numberOfHits}
      {...hitsProps}
    />
  );

  const validateInjectionPosition = ({ hitsFromSlotIndex, position }) => {
    const listOrder = hitsFromSlotIndex && hitsFromSlotIndex.length && hitsFromSlotIndex[0].listOrder;
    if (!listOrder) {
      return false;
    }
    return (isWidthSmDownView || listOrder % rowSize !== 0) ? listOrder - 1 === position : listOrder === position;
  };

  const renderHits = (
    <InjectedHits
      slots={() => [
        {
          injectAt: ({ hitsFromSlotIndex, position }) => validateInjectionPosition({ hitsFromSlotIndex, position }),
          getHits: ({ resultsByIndex }) => (resultsByIndex.prodv3_ce_websearch?._rawResults || [])
            .reduce((acc, results) => acc.concat(results.hits), []) || [],
          injectionType: 'prodv3_ce_websearch'
        }
      ]}
      currentLocale={currentLocale}
      enableDarkThemeTiles={enableDarkThemeTiles}
      showCurrentlyViewing={showCurrentlyViewing}
      elasticSearchIndex={elasticSearchIndex}
      siteId={siteId}
      abTestVariant={abTestVariant}
      showPromoCodeEligible={showPromoCodeEligible}
      tealiumTrack={tealiumTrack}
      isMobileView={isMobileView}
      recentBookingsThreshold={recentBookingsThreshold}
      sellOutThreshold={sellOutThreshold}
      allowFilterDatesWithPermalink={allowFilterDatesWithPermalink}
      tourCurrencies={tourCurrencies}
      showPriceWithFees={showPriceWithFees}
      shouldHideAdlabels={shouldHideAdlabels}
      shouldAddCtaButton={shouldAddCtaButton}
      showEmbedSocialReview={showEmbedSocialReview && searchExperienceV3IsEnabled}
      allowedDisplayLabels={allowedDisplayLabels}
      searchIndex={elasticSearchIndex}
      sendAlgoliaEvent={sendAlgoliaEvent}
      setAlgoliaSearchInfo={setAlgoliaSearchInfo}
      shouldRedirectAd={shouldRedirectAd}
      searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
      showToursWithAvailabilityOnly={isShowToursWithAvailabilityOnly}
      fixedHeight={fixedHeight}
      setFixedHeight={setFixedHeight}
      toShowCentsPropertyIds={searchExperienceV3IsEnabled && toShowCentsPropertyIds}
      aliases={aliases}
      showNoResult={searchExperienceV3IsEnabled && showNoResult}
      chatkey={chatkey}
      hitsPerPage={hitsPerPage}
      disableScrollUp={disableScrollUp}
      onScrollUp={onScrollUp}
      enableProductsOnHorizon={enableProductsOnHorizon && enableProductsOnHorizonFlag}
      searchState={searchState}
      showNewTextForNoResultFound={showNewTextForNoResultFound}
      hasSearchTerm={hasSearchTerm}
      selectedDate={selectedDate}
      setActiveRefinements={setActiveRefinements}
      enableMobileTileVariant2={enableMobileTileVariant2Flag}
      enableMobileTileVariant3={enableMobileTileVariant3}
      isXsView={isXsView}
      exclusiveDealBannerText={exclusiveDealBannerText}
      displacement={isWidthSmDownView ? 1 : 2}
      isFirstPage={selectedPage === 1}
      enableAdTiles={enableAdTiles}
      numberOfHits={numberOfHits}
    />
  );

  const renderClearAndResultButton = () => (
    <Fragment>
      <Grid item xs={6}>
        <ClearRefinements
          resetDefaultState={resetDefaultState}
          permanentSearchState={permanentSearchState}
          setClearSearchTerm={setClearSearchTerm}
          hideOnNoFilters
          showPriceWithFees={showPriceWithFees}
          setShowPriceWithFees={setShowPriceWithFees}
          enableAlwaysShowClearFilters={true}
          showSpecialOffers={showSpecialOffers}
          setShowSpecialOffers={setShowSpecialOffers}
          specialCouponFilterOn={specialCouponFilterOn}
          setSpecialCouponFilterOn={setSpecialCouponFilterOn}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          hideRefinements={hideRefinements}
          isWidthSmDownView={isWidthSmDownView}
          size={'small'}
          count={getFilterCount()}
          buttonText={getFilterCount() ? 'clearAllFiltersWithCount' : 'clearAllFilters' }
          hasSearchTerm={hasSearchTerm}
          overrideModalToHighlightColors={overrideModalToHighlightColors}
          onScrollUp={onScrollUp}
          disableCalendars={disableCalendars}
        />
      </Grid>
      <Grid item xs={6}>
        <ClearRefinements
          disableCalendars={disableCalendars}
          resetDefaultState={resetDefaultState}
          permanentSearchState={permanentSearchState}
          setClearSearchTerm={setClearSearchTerm}
          hideOnNoFilters
          showPriceWithFees={showPriceWithFees}
          setShowPriceWithFees={setShowPriceWithFees}
          enableAlwaysShowClearFilters={true}
          showSpecialOffers={showSpecialOffers}
          setShowSpecialOffers={setShowSpecialOffers}
          specialCouponFilterOn={specialCouponFilterOn}
          setSpecialCouponFilterOn={setSpecialCouponFilterOn}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          hideRefinements={hideRefinements}
          count={numberOfHits}
          buttonText={(numberOfHits && !showNoResult) ? 'showResultsWithCount' : 'noResults' }
          variant={'contained'}
          handleClick={() => setFiltersModalOpen(false)}
          isWidthSmDownView={isWidthSmDownView}
          size={'small'}
          hasSearchTerm={hasSearchTerm}
          disabled={!numberOfHits || showNoResult}
          overrideDefaultDisableSettings={true}
          overrideModalToHighlightColors={overrideModalToHighlightColors}
          onScrollUp={onScrollUp}
        />
      </Grid>
    </Fragment>
  );

  const renderDefaultView = <Fragment>
    <div className={classNames({
      [classes.hidden]: isMobileView
    })}>
      <FeaturedFilters
        featuredFilters={featuredFilters}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        isMobileView={isMobileView}
        tealiumTrack={tealiumTrack}
        showHierarchical={showHierarchical}
        sortFeaturedFiltersByDate={sortFeaturedFiltersByDate}
      />
    </div>

    <Grid container spacing={3}>
      <Grid item xs={12} md={3} className={classNames({
        [classes.hidden]: isMobileView
      })}>
        <Grid container justifyContent={'center'} className={classes.clearFilter}>
          <Grid item xs={12} sm={9}>
            <ClearRefinements
              disableCalendars={disableCalendars}
              resetDefaultState={resetDefaultState}
              permanentSearchState={permanentSearchState}
              setClearSearchTerm={setClearSearchTerm}
              hideOnNoFilters
              showPriceWithFees={showPriceWithFees}
              setShowPriceWithFees={setShowPriceWithFees}
              showSpecialOffers={showSpecialOffers}
              setShowSpecialOffers={setShowSpecialOffers}
              specialCouponFilterOn={specialCouponFilterOn}
              setSpecialCouponFilterOn={setSpecialCouponFilterOn}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              hideRefinements={hideRefinements}
              isWidthSmDownView={isWidthSmDownView}
              enableAlwaysShowClearFilters={enableAlwaysShowClearFilters}
              hasSearchTerm={hasSearchTerm}
              onScrollUp={onScrollUp}/>
          </Grid>
        </Grid>
        {!isMobileView && filtersView}
      </Grid>

      <Grid item xs={12} md={isMobileView ? 12 : 9}>
        <Grid container spacing={1} wrap={'nowrap'} alignItems={'center'}>
          <Grid item className={classes.grow}>
            <SearchBox
              disableCalendars={disableCalendars}
              label={searchBarPlaceholderText ||
                  localize(isMobileView ? 'searchExperiences' : 'searchDestinationOrExperiences')}
              tealiumTrack={tealiumTrack}
              clearSearchTerm={clearSearchTerm}
              setClearSearchTerm={setClearSearchTerm}
              setHasSearchTerm={setHasSearchTerm}
              hasSearchTerm={hasSearchTerm}
              customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
              customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
              overrideCalendarStartMonth={overrideCalendarStartMonth}
              blockCalendarChange={blockCalendarChange}
              fullScreenCalendarForMobile={fullScreenCalendarForMobile}
            />
          </Grid>
          <Grid className={classes.filterBox}>
            <Grid item className={classNames(
              isMobileView && classes.SortButtonIcon, {
                [classes.buttonIcon]: true,
                [classes.hidden]: !isMobileView
              })}>
              <Typography variant={'caption'} color={'primary'}>Filters</Typography>
              {createFilterButton({})}
            </Grid>
            {isMobileView ? <div className={classNames(classes.buttonIcon, classes.SortButtonIcon)}>
              <Typography variant={'caption'} color={'primary'}><Text message={'sortBy'}/></Typography>
              <Button
                data-bdd={'mobile-filters-button'}
                color="primary"
                className={classes.parentIcon}
                onClick={sortByFilter}>
                <div className={classes.borderSortIcon}>
                  <SwapVertOutlinedIcon className={classes.sortIcon} color="primary"/>
                </div>
              </Button>
            </div> :
              <div className={classes.fullScreen}>
                <Typography variant={'body1'} color={'primary'} className={classes.setLeft}>
                  {localize('sortBy')}: {sortByLabel}</Typography>
                <IconButton onClick={sortByFilter}>
                  <ExpandMoreIcon />
                </IconButton>
              </div>
            }

          </Grid>
        </Grid>

        <div className={classNames({
          [classes.hidden]: !isMobileView
        })}>
          <FeaturedFilters
            featuredFilters={featuredFilters}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            isMobileView={isMobileView}
            tealiumTrack={tealiumTrack}
            showHierarchical={showHierarchical}
            sortFeaturedFiltersByDate={sortFeaturedFiltersByDate}
          />
        </div>
        <div className={classNames({
          [classes.hidden]: isMobileView
        })}>
          <Grid container spacing={2} wrap={'nowrap'} justifyContent={'space-between'} alignItems={'flex-start'}>
            <Grid item xs={12} sm={9}>
              {currentRefinements}
            </Grid>
          </Grid>
        </div>

        <div className={classes.results}>
          {renderHits}
        </div>
        <Pagination
          tealiumTrack={tealiumTrack}
          onScrollUp={onScrollUp}
          numberOfPages={numberOfPages}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
        />
      </Grid>
    </Grid>
  </Fragment>;

  const renderVersion2 = <Fragment>
    <div className={classNames({
      [classes.hidden]: isMobileView,
      [classes.headerContainer]: true,
      [classes.headerContainerWithFilters]: !!maybeFeaturedFilters
    })}>
      <Grid container spacing={1} alignItems={'center'}>
        <Stats
          translations={{
            stats(nbHits, processingTime, nbSortedHits, areHitsSorted) {
              setNumberOfHits(areHitsSorted ? nbSortedHits : nbHits);
              setNumberOfPages(Math.ceil((areHitsSorted ? nbSortedHits : nbHits) / (hitsPerPage || 12)));
            }
          }}
        />
        <Grid item xs={12}>
          {searchBarHeaderText && (
            <Typography variant="h5" className="search-bar-header">
              {`${searchBarHeaderText} (${numberOfHits || 0} Results)`}
            </Typography>
          )}
        </Grid>
        <Grid item md={6}>
          <SearchBox
            disableCalendars={disableCalendars}
            label={searchBarPlaceholderText || localize('searchDestinationOrExperiences')}
            tealiumTrack={tealiumTrack}
            searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
            clearSearchTerm={clearSearchTerm}
            setClearSearchTerm={setClearSearchTerm}
            setHasSearchTerm={setHasSearchTerm}
            hasSearchTerm={hasSearchTerm}
            enableDarkThemeTiles={enableDarkThemeTiles}
            customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
            customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
            overrideCalendarStartMonth={overrideCalendarStartMonth}
            blockCalendarChange={blockCalendarChange}
            fullScreenCalendarForMobile={fullScreenCalendarForMobile}
          />
        </Grid>
        <Grid item md={6} className={classes.filterSortBoxButton}>
          <div className={classes.clearButtonV2}>
            <ClearRefinements
              disableCalendars={disableCalendars}
              resetDefaultState={resetDefaultState}
              permanentSearchState={permanentSearchState}
              hideOnNoFilters
              showPriceWithFees={showPriceWithFees}
              setShowPriceWithFees={setShowPriceWithFees}
              searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
              enableAlwaysShowClearFilters ={enableAlwaysShowClearFilters}
              variant={'text'}
              buttonText={'clearFilters'}
              showSpecialOffers={showSpecialOffers}
              setShowSpecialOffers={setShowSpecialOffers}
              specialCouponFilterOn={specialCouponFilterOn}
              setSpecialCouponFilterOn={setSpecialCouponFilterOn}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              hideRefinements={hideRefinements}
              isWidthSmDownView={isWidthSmDownView}
              setClearSearchTerm={setClearSearchTerm}
              hasSearchTerm={hasSearchTerm}
              onScrollUp={onScrollUp}
            />
          </div>
          <div
            className={classNames(classes.fullScreen, classes.sortByButtonDesktop, 'sortByButtonDesktopInline')}
            onClick={() => setSortByDrawerOpen(!sortByDrawerOpen)}>
            <Typography variant={'subtitle1'}
              color={enableDarkThemeTiles ? 'primary' : 'textPrimary'} className={classes.setRight}>
              {localize('sortBy')}: </Typography>
            <SortByDrawer
              enableDarkThemeTiles={enableDarkThemeTiles}
              sortByDrawerOpen={sortByDrawerOpen && !isMobileView}
              setSortByDrawerOpen={setSortByDrawerOpen}
              defaultRefinement={elasticSearchIndex}
              tealiumTrack={tealiumTrack}
              selectedValue={searchState.sortBy}
              items={sortOpt}
              setFiltersSortByOpen={setFiltersSortByOpen}
              type={'price'}
              isRadio={false}
              sortByLabel={sortByLabel}
              ariaLabelToUse={'sort-by-drawer-desktop'}
            />
          </div>
        </Grid>
      </Grid>
      <FeaturedFilters
        featuredFilters={maybeFeaturedFilters}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        isMobileView={isMobileView}
        tealiumTrack={tealiumTrack}
        showHierarchical={showHierarchical}
        searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
        sortFeaturedFiltersByDate={sortFeaturedFiltersByDate}
      />
    </div>
    <div className={classNames('mobileSearchBar', {
      [classes.hidden]: !isMobileView || hideMobileSearchComponents
    })}>
      <Grid container spacing={1} wrap={'nowrap'} alignItems={'center'}>
        <Grid item className={classes.grow}>
          <SearchBox
            disableCalendars={disableCalendars}
            label={localize('search')}
            tealiumTrack={tealiumTrack}
            searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
            showAddDate={true}
            isXsView={isXsView}
            isMobileView={isMobileView}
            isWidthSmDownView={isWidthSmDownView}
            selectedDate={((searchState || {}).range || {}).availableDates}
            setSelectedDate={searchExperienceV3IsEnabled && setSelectedDate}
            setShowNoResult={searchExperienceV3IsEnabled && setShowNoResult}
            onScrollUp={onScrollUp}
            keepCalendarOpen={keepCalendarOpen}
            clearSearchTerm={clearSearchTerm}
            setClearSearchTerm={setClearSearchTerm}
            setHasSearchTerm={setHasSearchTerm}
            hasSearchTerm={hasSearchTerm}
            customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
            customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
            overrideCalendarStartMonth={overrideCalendarStartMonth}
            blockCalendarChange={blockCalendarChange}
            fullScreenCalendarForMobile={fullScreenCalendarForMobile}
          />
        </Grid>
      </Grid>
    </div>
    <Grid container spacing={3}>
      <Grid item xs={12} md={3} className={classNames('left-side-search-algolia', {
        [classes.hidden]: isMobileView
      })}>
        {!isMobileView && filtersView}
      </Grid>
      <Grid item xs={12} md={isMobileView ? 12 : 9}>
        <div className={classNames('mobileFilters', {
          [classes.hidden]: !isMobileView ||
          (hideMobileSearchComponents && !showCalendarFilterViewOnMobile)
        })}>
          <Grid
            container
            className={classNames(
              classes.mobileRefinements,
              'sortByButtonIpadInline',
              { noPaddingBottom: !!(maybeFeaturedFilters || []).length }
            )}
            spacing={1}
            justifyContent="space-between"
            alignItems="center">
            <Grid
              item
              className={classNames({
                [classes.fullScreen]: true,
                [classes.sortByButton]: !enableDarkThemeTiles,
                [classes.sortByButtonWithBackground]: enableDarkThemeTiles
              })}
              onClick={() => setSortByDrawerOpen(!sortByDrawerOpen)}>
              <Typography variant={isXsView ? 'caption' : 'subtitle1'}
                color={enableDarkThemeTiles ? 'primary' : 'textPrimary'}
                className={classes.setRight}>
                {localize('sortBy')}: </Typography>
              <SortByDrawer
                defaultRefinement={elasticSearchIndex}
                enableDarkThemeTiles={enableDarkThemeTiles}
                tealiumTrack={tealiumTrack}
                selectedValue={searchState.sortBy}
                items={sortOpt}
                setFiltersSortByOpen={setFiltersSortByOpen}
                type={'price'}
                isRadio={false}
                sortByLabel={sortByLabel}
                isXsView={isXsView}
                isMobileView={isMobileView}
                ariaLabelToUse={'sort-by-drawer-mobile'}
                sortByDrawerOpen={sortByDrawerOpen && isMobileView}
                setSortByDrawerOpen={setSortByDrawerOpen}
              />
            </Grid>
            <Grid item className={classNames({
              [classes.filterButtonMobileWithBackground]: enableDarkThemeTiles,
              [classes.filterButtonMobile]: !enableDarkThemeTiles
            })}>
              {createFilterButton({ inline: true, textDark: true })}
            </Grid>
          </Grid>
          <FeaturedFilters
            featuredFilters={maybeFeaturedFilters}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            isMobileView={isMobileView}
            tealiumTrack={tealiumTrack}
            showHierarchical={showHierarchical}
            searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
            sortFeaturedFiltersByDate={sortFeaturedFiltersByDate}
          />
        </div>
        {isMobileView && searchBarHeaderText &&
        <Grid item xs={12} mb={3}>
          <Typography variant="body1" textAlign="center" className="search-bar-header">
            {`${searchBarHeaderText} (${numberOfHits || 0} Results)`}
          </Typography>
        </Grid>}
        {showCategoryQuickFilters && isWidthSmDownView &&
        <CategoryQuickFilters searchState={searchState} setSearchState={setSearchState}/>}
        {
          isTrue(showInfiniteScrollOnTileListing) ?
            (
              <div>
                {renderInfiniteHits({ showNoResult, numberOfHits })}
              </div>
            ) :
            (<div>
              <div className={classes.resultsV2} id="searchExperiencesResultContainer">
                {renderHits}
              </div>
              <Pagination
                tealiumTrack={tealiumTrack}
                onScrollUp={onScrollUp}
                numberOfPages={numberOfPages}
                setSelectedPage={setSelectedPage}
                selectedPage={selectedPage}
              />
            </div>)
        }
      </Grid>
    </Grid>
  </Fragment>;

  const dateRangeWithParams = () => <DateRange attribute={'availableDates'} tealiumTrack={tealiumTrack}
    disableCalendars={disableCalendars}
    isMobileView={isMobileView}
    isWidthSmDownView={isWidthSmDownView}
    searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
    selectedDate={searchExperienceV3IsEnabled && selectedDate}
    setSelectedDate={searchExperienceV3IsEnabled ? setSelectedDate : () => {}}
    setShowNoResult={searchExperienceV3IsEnabled ? setShowNoResult : () => {}}
    hideMobileSearchComponents={hideMobileSearchComponents}
    addMarginBottom={false}
    customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
    customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
    blockCalendarChange={blockCalendarChange}
    overrideShownMonths={overrideShownMonths}
    overrideCalendarStartMonth={overrideCalendarStartMonth}
    hideDatePickerPills={hideDatePickerPills}
  />;

  return (
    <div className={searchExperienceV3IsEnabled ? classes.rootV3 : classes.root}>
      {showBrandQuickFilters && <BrandQuickFilters searchState={searchState} setSearchState={setSearchState}
        isWidthSmDownView={isWidthSmDownView} availableBrands={availableBrands}/>}
      {isMobileView && showUKCityQuickFilters &&
        <CityQuickFilters searchState={searchState} setSearchState={setSearchState}/>}
      {showDynamicTitle && <DynamicTitle
        searchState={searchState}
      />}
      <InstantSearch
        indexName={elasticSearchIndex}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
      >
        <QueryRuleCustomData>
          {({ items }) => {
            if (!_.isEqual(items, rulesCustomData)) {
              setRulesCustomData(items);
            }
          }}
        </QueryRuleCustomData>
        <Configure
          filters={[
            'privateEvent:false',
            (couponCatsToShow && couponCatsToShow.length) ?
              couponCatsToShow.map(cat => `specialCouponNonFilterCategory:"${cat}"`).join(' OR ') : '',
            (enableProductsOnHorizonFlag && enableProductsOnHorizon) ? 'hasNoAvailableDates:false' : undefined,
            onlyShowSpecialFilterCouponProducts ? 'specialFilterCoupon:true' : undefined,
            hideZeroPriceTours ? 'displayPrice > 0' : undefined,
            (availableCities && !!(availableCities || []).length) ?
              availableCities.map(cit => `city:"${cit}"`).join(' OR ') : undefined,
            ignoreHideInWebsiteSearch ? undefined : 'hideInWebsiteSearch:false',
            onlyOwnedProducts ? 'displayIcon:owned' : undefined,
            excludePartnerProducts ? 'NOT displayIcon:partner' : undefined,
            Array.isArray(propertyIds) && propertyIds.length ?
              `(${propertyIds.map(pid => `propertyId:${pid}`)
                .join(' OR ')})`
              : undefined,
            ...Array.isArray(excludedPropBookingType) ?
              excludedPropBookingType.map(pbt => `NOT propBookingType:"${pbt}"`)
              : [],
            showSpecialOffers && '(containsSpecialCoupon:true OR categoryTheme:"Special Offers")',
            specialCouponFilterOn && '(specialFilterCoupon:true)'
          ].filter(val => val)
            .join(' AND ')}
          hitsPerPage={hitsPerPage || 12}
          {...(!showInfiniteScrollOnTileListing) && {
            offset: (selectedPage - 1) * (hitsPerPage || 12) - (selectedPage > 1 ? adOffset : 0),
            length: (hitsPerPage || 12) + (selectedPage > 1 ? adOffset : 0)
          }}
          userToken={getCorrelationId()}
          clickAnalytics
          aroundLatLngViaIP = {(searchState.sortBy || '').includes('geo') ? true : undefined}
          aroundRadius = {(searchState.sortBy || '').includes('geo') ? 'all' : undefined}
        />
        {enableAdTiles &&
        <Index indexName="prodv3_ce_websearch">
          <Configure
            filters={[
              'active:true',
              'type:adTile',
              'propertyId:hbcorp',
              `startDate<=${currentTime}`,
              `endDate>=${currentTime}`,
              `pathName:${CURRENT_PATHNAME}`
            ].filter(val => val)
              .join(' AND ')}
            length={1}
            offset={0}
            aroundLatLngViaIP={undefined}
            aroundRadius={undefined}
          />
        </Index>
        }
        {showChooseDestinationDropdownMobile && isMobileView &&
          <ChooseDestinationDropdown renderRefinementList={renderRefinementList} searchState={searchState}/>}
        {showFullWidthMobileSortByDrawer && isMobileView &&
          <Grid
            item
            className={classNames({
              [classes.mobileFullWidthSortByDrawer]: true
            })}
            onClick={() => setMobileFullWidthSortByOpen(!mobileFullWidthSortByOpen)}>
            <Typography variant="body1"
              className={classes.setRight}>
              {localize('sortBy')}: </Typography>
            <SortByDrawer
              defaultRefinement={elasticSearchIndex}
              enableDarkThemeTiles={enableDarkThemeTiles}
              tealiumTrack={tealiumTrack}
              fullWidthMobileDrawer={true}
              selectedValue={searchState.sortBy}
              items={sortOpt}
              setFiltersSortByOpen={setFiltersSortByOpen}
              type={'price'}
              isRadio={false}
              sortByLabel={sortByLabel}
              isXsView={isXsView}
              isMobileView={isMobileView}
              ariaLabelToUse={'sort-by-drawer-mobile'}
              sortByDrawerOpen={mobileFullWidthSortByOpen && isMobileView}
              setSortByDrawerOpen={setMobileFullWidthSortByOpen}
            />
          </Grid>}
        {(showFullLengthDatePickerMobile || showCalendarFilterViewOnMobile) && isMobileView &&
          dateRangeWithParams()}
        {isMobileView && hideMobileSearchComponents &&
        !!Object.keys(mobileQuickFilterCruiseTypes).length && <Grid
          container
          className={classNames(classes.mobileRefinements)}
          spacing={1}
          justifyContent="space-between"
          alignItems="center">
          <CruiseQuickFilters
            mobileQuickFilterCruiseTypes={mobileQuickFilterCruiseTypes}
            searchState={searchState}
            setSearchState={setSearchState}
          />
        </Grid>}
        {!searchExperienceV3IsEnabled && renderDefaultView}
        {searchExperienceV3IsEnabled && renderVersion2}
        <Modal
          keepMounted
          isOpen={filtersModalOpen && isMobileView}
          id="select-mobile-filters-dialog-1"
          onClose={() => setFiltersModalOpen(false)}
          centerTitle
          heightFix={searchExperienceV3IsEnabled && fixedHeight}
          overrideModalToHighlightColors={overrideModalToHighlightColors}
          title={localize('filters')}>
          <Grid container justifyContent={'center'} spacing={searchExperienceV3IsEnabled ? 1 : 0}>
            {!searchExperienceV3IsEnabled ?
              <Grid item xs={12} sm={3}>
                <ClearRefinements
                  disableCalendars={disableCalendars}
                  resetDefaultState={resetDefaultState}
                  permanentSearchState={permanentSearchState}
                  hideOnNoFilters
                  showPriceWithFees={showPriceWithFees}
                  setShowPriceWithFees={setShowPriceWithFees}
                  enableAlwaysShowClearFilters={enableAlwaysShowClearFilters}
                  showSpecialOffers={showSpecialOffers}
                  setShowSpecialOffers={setShowSpecialOffers}
                  specialCouponFilterOn={specialCouponFilterOn}
                  setSpecialCouponFilterOn={setSpecialCouponFilterOn}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  isWidthSmDownView={isWidthSmDownView}
                  setClearSearchTerm={setClearSearchTerm}
                  hasSearchTerm={hasSearchTerm}
                  onScrollUp={onScrollUp}
                />
              </Grid>
              : renderClearAndResultButton()
            }
          </Grid>
          <Grid item className={classes.clearFilter}>
            {!searchExperienceV3IsEnabled && currentRefinements}
          </Grid>
          {isMobileView && filtersView}
          {!searchExperienceV3IsEnabled && <DividerWithSpacing spacing={16}/>}
          <Grid container justifyContent={'center'} spacing={searchExperienceV3IsEnabled ? 1 : 0}>
            {!searchExperienceV3IsEnabled ?
              <Grid item>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => setFiltersModalOpen(false)}
                >
                  {localize('showResults')}
                </Button>
              </Grid>
              : renderClearAndResultButton()
            }
          </Grid>
        </Modal>
        <Modal isOpen={filtersSortByOpen} onClose={() => setFiltersSortByOpen(false)}
          classes={{
            content: classes.sortByScroll
          }}
          centerTitle
          title={localize('filterByPrice')}
          keepMounted
        >
          <SortByDrawer
            defaultRefinement={elasticSearchIndex}
            tealiumTrack={tealiumTrack}
            selectedValue={searchState.sortBy}
            items={sortOpt}
            setFiltersSortByOpen={setFiltersSortByOpen}
            type={'price'}
            sortByDrawerOpen={sortByDrawerOpen}
            setSortByDrawerOpen={setSortByDrawerOpen}
          />
        </Modal>
      </InstantSearch>
    </div>
  );
};

SearchExperiences.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles),
  withAbTest('wi0bRBVdTvuSdcTPmr0sMg', { event: 'optimize.activate.commerceSdk.searchExperiences' })
)(SearchExperiences);
