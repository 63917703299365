// eslint-disable-next-line import/no-unresolved
// import { algolia } from 'config';
import algoliasearch from 'algoliasearch/lite';

const algolia = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_API_KEY
};
let algoliaClient;

export const getElasticSearchClient = () => {
  if (!algoliaClient) {
    algoliaClient = algoliasearch(algolia.appId, algolia.apiKey);
  }

  return algoliaClient;
};
