export const loadEmbedSocialReview = () => {
  // eslint-disable-next-line id-length
  (function(d, s, id) {
    let js;
    if (d.getElementById(id)) {
      const elem = d.getElementById(id);
      elem.remove();
    }
    // eslint-disable-next-line prefer-const
    js = d.createElement(s);js.id = id;
    js.src = 'https://embedsocial.com/cdn/ht.js';
    d.getElementsByTagName('head')[0].appendChild(js);
  }(document, 'script', 'EmbedSocialHashtagScript'));
};
