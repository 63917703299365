/* eslint-disable max-len, id-length, max-lines */
export default {
  applyLabel: 'Applicare',
  price: 'Prezzo',
  alipay: 'AliPay',
  city: {
    INVALID_CITY: 'Le informazioni inserite in questo campo non sono valide. Inserisci di nuovo la tua città.',
    REQUIRED_FIELD: 'Inserisci la tua città di fatturazione.'
  },
  achWire: 'Ach / Wire',
  transfer: 'Trasferimento',
  clear: 'Chiaro',
  date: { REQUIRED_FIELD: 'Seleziona la data dell\'evento.' },
  off: 'via',
  cancelled: 'Annullato',
  noResults: 'Nessun risultato',
  country: { REQUIRED_FIELD: 'Seleziona il tuo paese di residenza.' },
  cashiersCheck: 'Assegno circolare',
  none: 'Nessuna',
  sendProposal: 'Crea proposta',
  searchExperiences: 'Cerca Esperienze',
  searchDestinationOrExperiences: 'Cerca Destinazioni o Esperienze',
  search: 'Ricerca',
  filters: 'Filtri',
  refinements: {
    webCategoriesMain: 'Tipo',
    durationTiming: 'Durata',
    eventPrice: 'Prezzo',
    displayPrice: 'Prezzo',
    availableDates: 'Date',
    country: 'Paese',
    promotionTag: 'Promozioni',
    city: 'Destinazione',
    categoryTheme: 'Categoria',
    audience: 'Buono per',
    priceBetween: 'Tra %s e %s',
    priceBelow: 'Sotto %s',
    priceAbove: 'Sopra %s',
    dateBetween: 'Tra %s e %s',
    dateBefore: 'Prima di %s',
    dateAfter: 'Dopo %s',
    brandTag: 'Marca',
    hierarchical: { lvl0: 'Categorie' }
  },
  from: 'Da',
  to: 'A',
  duration: 'Durata',
  clearAllFilters: 'Cancella tutti i filtri',
  recommended: 'Consigliato',
  filterByPrice: 'Filtra per prezzo',
  lowToHigh: 'Prezzo - Da basso ad alto',
  highToLow: 'Prezzo - Da alto a basso',
  and: ' E',
  sortBy: 'Ordina per',
  showLess: 'Mostra meno',
  showMore: 'Mostra di più',
  showResults: 'Mostra i risultati',
  creditCardExternal: '%s pagato con carta di credito esterna',
  or: 'O',
  pending: 'In attesa di',
  loadMore: 'Carica di più',
  companyBalance: 'Equilibrio Aziendale',
  remove: 'Rimuovere',
  accepted: 'Accettato',
  denied: 'Negato',
  likelyToSellOut: 'Probabile esaurimento',
  noOfRecentBookings: '%s Prenotazioni recenti'
};
