/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from 'react';
import Debug from 'debug';
const debug = Debug('hb:commerce-sdk:lib:withAbTest');

export default (experimentId, { event } = {}) => WrappedComponent =>
  class WithAbTest extends Component {
    intervalId;
    state = {
      variant: '0'
    };

    async componentDidMount() {
      let retries = 0;

      if (window.dataLayer && event) {
        await window.dataLayer.push({ event });
      }

      this.intervalId = setInterval(() => {
        retries = retries + 1;
        if (retries === 10) {
          clearInterval(this.intervalId);
          return;
        }
        if (window.google_optimize !== undefined) {
          const variant = window.google_optimize.get(experimentId);
          if (variant !== undefined) {
            debug(`activating a/b test ${experimentId} with variant ${variant}`);
            this.setState({ variant });
            clearInterval(this.intervalId);
          }
        }
      }, 200);
    }

    render() {
      return (
        <WrappedComponent {...this.props} abTestVariant={this.state.variant} />
      );
    }
  };
