/* eslint-disable max-lines,import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import momentWithLocale from 'moment';
import omit from 'lodash/omit';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import { DateRangePickerPhrases } from 'react-dates/lib/defaultPhrases';
import DateRangePickerShape from 'react-dates/lib/shapes/DateRangePickerShape';
import {
  ANCHOR_LEFT,
  END_DATE,
  HORIZONTAL_ORIENTATION,
  INFO_POSITION_TOP,
  OPEN_DOWN,
  START_DATE
} from 'react-dates/constants';
import { compose } from 'recompose';
import withStyles from '@mui/styles/withStyles';
import styles from './styles';
import classNames from 'classnames';
import CalendarIcon from '@mui/icons-material/TodayOutlined';
import { Text } from 'react-localize';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import isEqual from 'lodash/isEqual';
import TextField from '@mui/material/TextField';

const propTypes = {
  // example props for the demo
  autoFocus: PropTypes.bool,
  autoFocusEndDate: PropTypes.bool,
  stateDateWrapper: PropTypes.func,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,

  ...omit(DateRangePickerShape, [
    'startDate',
    'endDate',
    'onDatesChange',
    'focusedInput',
    'onFocusChange'
  ])
};

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  readOnly: true,

  // input related props
  startDateId: START_DATE,
  startDatePlaceholderText: 'Start Date',
  endDateId: END_DATE,
  endDatePlaceholderText: 'End Date',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,
  block: false,
  small: false,
  regular: false,
  autoComplete: 'off',

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,
  openDirection: OPEN_DOWN,

  // navigation related props
  navPosition: INFO_POSITION_TOP,
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () => moment.localeData().longDateFormat('L'),
  monthFormat: 'MMMM YYYY',
  phrases: DateRangePickerPhrases,

  stateDateWrapper: date => date
};

class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }

    this.state = {
      focusedInput,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
  }

  componentDidMount() {
    const { locale } = this.props;
    if (locale !== 'en') {
      momentWithLocale.locale(locale);
    }
  }

  componentWillUnmount() {
    momentWithLocale.locale('en');
  }

  onDatesChange({ startDate, endDate }) {
    const { stateDateWrapper } = this.props;
    const _startDate = startDate && startDate.locale('en');
    let _endDate = endDate && endDate.locale('en');

    if (this.state.focusedInput === START_DATE) {
      _endDate = null;
    }

    this.setState({
      startDate: _startDate && stateDateWrapper(_startDate),
      endDate: _endDate && stateDateWrapper(_endDate)
    });
    this.props.onDatesChange({ startDate: _startDate, endDate: _endDate });
  }

  onFocusChange(focusedInput) {
    const { dateChip, setDateChip } = this.props;
    if (dateChip && dateChip !== 'thisWeek' && (typeof setDateChip === 'function')) {
      setDateChip(null);
      this.setState({ focusedInput: START_DATE });
    } else {
      this.setState({ focusedInput });
    }
  }

  checkDatesEqual = (fromDate, toDate) => {
    const { startDate, endDate } = this.state;
    const date1 = fromDate !== undefined ? fromDate : startDate;
    const date2 = toDate !== undefined ? toDate : endDate;
    if (!date1 || !date2) {
      return false;
    }
    return isEqual(date1, date2);
  };

  onApply = () => {
    const { stateDateWrapper } = this.props;
    const { startDate } = this.state;
    const _endDate = startDate && startDate.locale('en');

    this.setState({
      endDate: _endDate && stateDateWrapper(_endDate)
    });
    this.props.onDatesChange({ startDate, endDate: _endDate });
    this.onFocusChange(null);
  };

  renderDatePresets() {
    const { classes } = this.props;
    const { startDate } = this.state;
    return (
      <Grid container justifyContent={'flex-end'} spacing={1} className={classes.infoButton}>
        <Grid item>
          <Button
            color={'primary'}
            variant={'contained'}
            disabled={!startDate}
            onClick={() => this.onApply()}
          >
            <Text message={'applyLabel'}/>
          </Button>
        </Grid>
      </Grid>
    );
  }

  renderInput() {
    const props = this.props;
    return (
      <TextField {...props}></TextField>
    );
  }

  isOutsideRange = day => {
    const { customInclusiveCalendarEndDate: endDate, customInclusiveCalendarStartDate: startDate } = this.props;
    const isBeforeStartDate = day.isBefore(startDate ? moment(startDate, 'YYYY/MM/DD') : moment().subtract(1, 'days'));
    const isAfterEndDate = endDate ? !day.isBefore(moment(endDate, 'YYYY/MM/DD').add(1, 'days')) : false;
    return isBeforeStartDate || isAfterEndDate;
  };

  render() {
    const { focusedInput, startDate, endDate } = this.state;
    const { classes, initialStartDate, initialEndDate, displayFormat, orientation,
      numberOfMonths, showIcon, customInputIcon, hideEndDate, showClearButton, customInclusiveCalendarEndDate,
      overrideCalendarStartMonth, blockCalendarChange, disableCalendars, customInclusiveCalendarStartDate } =
      this.props;
    const props = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'stateDateWrapper',
      'classes',
      'min',
      'max',
      'onDatesChange',
      'showIcon',
      'hideEndDate',
      'showClearButton'
    ]);
    //eslint-disable-next-line no-unused-vars
    const { ...rest } = props;
    const shouldHideEndDate = hideEndDate && this.checkDatesEqual(initialStartDate, initialEndDate);
    const startMonth = initialStartDate !== undefined ? initialStartDate : startDate;
    const monthToShow = startMonth ? startMonth : moment().format('YYYY-MM-DD');

    return (
      <div
        ref={this.containerRef}
        className={classNames({
          [classes.root]: true,
          [classes.hideEndDate]: shouldHideEndDate,
          [classes.hideCalendarMonthChange]: blockCalendarChange
        })}
        data-bdd="date-range-picker"
      >
        <DateRangePicker
          {...rest}
          block
          disabled={disableCalendars}
          renderInput={this.renderInput}
          minimumNights={0}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={initialStartDate !== undefined ? initialStartDate : startDate}
          endDate={initialEndDate !== undefined ? initialEndDate : endDate}
          displayFormat={displayFormat}
          orientation={orientation}
          numberOfMonths={numberOfMonths}
          initialVisibleMonth={() => (overrideCalendarStartMonth && !startMonth ?
            moment(overrideCalendarStartMonth) : moment(monthToShow)) }
          customInputIcon={showIcon &&
              (customInputIcon || <CalendarIcon color={'primary'} />)}
          renderCalendarInfo={this.renderDatePresets}
          showClearDates={showClearButton && !disableCalendars}
          isOutsideRange={customInclusiveCalendarEndDate || customInclusiveCalendarStartDate ?
            this.isOutsideRange : defaultProps.isOutsideRange}
        />
      </div>
    );
  }
}

DateRangePickerWrapper.propTypes = propTypes;
DateRangePickerWrapper.defaultProps = defaultProps;

export default compose(
  withStyles(styles)
)(DateRangePickerWrapper);
