import { SEARCH_EXPERIENCES_FILTER_DEFAULT_MAX_HEIGHT } from '../../../frontend/lib/shared/enums';

/* eslint-disable max-lines */
const styles = ({ spacing, palette, shape, shadows, typography, breakpoints }) => ({
  root: {
    padding: spacing(1),
    fontFamily: typography.body1.fontFamily
  },
  rootV3: {
    padding: `0 ${spacing(2)}`,
    fontFamily: typography.body1.fontFamily

  },
  grow: {
    flexGrow: 1
  },
  title: {
    fontFamily: typography.secondaryFontFamily
  },
  search: {
    minHeight: 750
  },
  hero: {
    backgroundColor: palette.divider,
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  },
  itinerarySelected: {
    boxShadow: shadows[4],
    padding: spacing(2),
    backgroundColor: palette.background.paper,
    marginTop: spacing(3),
    marginBottom: spacing(-5)
  },
  pageActions: {
    marginTop: spacing(8)
  },
  pageTitle: {
    color: palette.common.white,
    padding: spacing(2),
    background: 'linear-gradient(to top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%)'
  },
  drawerContent: {
    width: 450,
    maxWidth: '100%',
    padding: spacing(2)
  },
  drawerHeader: {
    paddingLeft: spacing(2),
    borderBottom: `solid 1px ${palette.divider}`
  },
  paper: {
    minWidth: 450,
    maxWidth: 450
  },
  results: {
    paddingTop: spacing(2),
    marginBottom: spacing(3)
  },
  resultsV2: {
    paddingTop: 0,
    marginBottom: spacing(3)
  },
  click: {
    cursor: 'pointer',
    display: 'flex'
    // justifyContent: 'space-between'
  },
  clickSort: {
    cursor: 'pointer',
    display: 'flex',
    paddingLeft: spacing(1)
  },
  leftIcon: {
    margin: spacing(1)
  },
  buttonIcon: {
    display: 'flex',
    paddingTop: spacing(1.25)
  },
  parentIcon: {
    display: 'flex'
  },
  borderIcon: {
    border: `solid 1px ${palette.primary.main}`,
    borderRadius: shape.borderRadius * 10
  },
  clearFilter: {
    marginTop: spacing(2)
  },
  refinementsFilters: {
    padding: '10px 0'
  },
  collapseContainer: {
    marginBottom: spacing(-3)
  },
  hidden: {
    display: 'none'
  },
  sortByScroll: {
    position: 'fixed',
    height: spacing(33),
    width: spacing(45),
    bottom: '0',
    right: '0px',
    top: spacing(6)
  },
  borderSortIcon: {
    border: `solid 1px ${palette.primary.main}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center'
  },
  sortIcon: {
    margin: spacing(1)
  },
  SortBorder: {
    backgroundColor: `${palette.primary.main}`,
    height: spacing(4)
  },
  SortButtonIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: spacing(2.5)
  },
  fullScreen: {
    display: 'flex',
    alignItems: 'center'
  },
  mobileFullWidthSortByDrawer: {
    borderRadius: '8px',
    marginTop: spacing(2),
    marginBottom: spacing(2),
    padding: spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    cursor: 'pointer',
    position: 'relative'
  },
  setLeft: {
    marginLeft: spacing(1.5)
  },
  setRight: {
    marginRight: spacing(.5),
    [breakpoints.down('xs')]: {
      marginRight: spacing(1) / 2
    }
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center'
  },
  destinationContainer: {
    borderBottom: 'none',
    [breakpoints.down('xs')]: {
      marginLeft: spacing(1.5)
    }
  },
  filterSortBoxButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: spacing(1)
  },
  clearButtonV2: {
    marginLeft: spacing(1)
  },
  titleV2: {
    fontFamily: typography.secondaryFontFamily,
    fontWeight: 500,
    marginRight: spacing(1),
    '@media (min-width:960px) and (max-width:1150px)': {
      fontSize: typography.body2.fontSize
    }
  },
  filterButtonV2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  filterButtonMobile: {
    border: `1px solid ${palette.divider}`,
    borderRadius: shape.borderRadius * 2,
    display: 'flex',
    fontSize: '20px',
    height: spacing(4),
    margin: `${spacing(1 / 4)} 0px `,
    padding: `0px ${spacing(1.5)} !important`
  },
  filterButtonMobileWithBackground: {
    borderRadius: shape.borderRadius * 2,
    backgroundColor: 'white',
    color: 'black !important',
    display: 'flex',
    fontSize: '20px',
    height: spacing(4),
    margin: `${spacing(1 / 4)} 0px `,
    padding: `0px ${spacing(1.5)} !important`
  },
  sortByButton: {
    fontSize: '20px',
    border: `1px solid ${palette.divider}`,
    borderRadius: shape.borderRadius * 2,
    margin: `${spacing(1 / 4)} 0px`,
    height: spacing(4),
    // paddingLeft: `${spacing(1.5)} !important`
    // paddingTop: `${spacing(1.5)} !important`
    padding: `0px ${spacing(1.5)} !important`,
    '&:hover': {
      cursor: 'pointer !important'
    }
  },
  sortByButtonWithBackground: {
    fontSize: '20px',
    border: `1px solid ${palette.divider}`,
    borderRadius: shape.borderRadius * 2,
    margin: `${spacing(1 / 4)} 0px`,
    backgroundColor: 'white !important',
    height: spacing(4),
    padding: `0px ${spacing(1.5)} !important`,
    '&:hover': {
      cursor: 'pointer !important'
    }
  },
  sortByFiltersContainer: {
    display: 'flex'
  },
  mobileRefinements: {
    paddingBottom: spacing(2),
    margin: '0px',
    width: '100%',
    '&.noPaddingBottom': {
      paddingBottom: 0
    }
  },
  sortByButtonDesktop: {
    border: `1px solid ${palette.divider}`,
    borderRadius: shape.borderRadius * 2,
    padding: `0px ${spacing(1.5)}`,
    backgroundColor: palette.mode === 'light' ? 'inherit' : palette.common.white,
    '&:hover': {
      cursor: 'pointer !important'
    }
  },
  filterItem: {
    marginRight: spacing(1 / 2),
    fontSize: spacing(2)
  },
  filterBadgeRoot: {
    alignItems: 'center'
  },
  filterBadgeCount: {
    position: 'initial',
    backgroundColor: palette.primary.main,
    zIndex: 0,
    fontSize: spacing(1.2),
    color: palette.secondary.contrastText,
    minWidth: spacing(2),
    height: spacing(2),
    marginLeft: spacing(1 / 2),
    borderRadius: '50%',
    transform: 'scale(1) translate(0%, 0%)'
  },
  filterBadgeCountInvisible: {
    position: 'absolute',
    transform: 'scale(0) translate(0%, 0%)'
  },
  headerContainer: {
    marginBottom: spacing(2)
  },
  headerContainerWithFilters: {
    marginBottom: spacing(1) / 2
  },
  stickyContainer: {
    position: 'relative',
    overflowY: 'visible',
    overflowX: 'visible',
    maxHeight: SEARCH_EXPERIENCES_FILTER_DEFAULT_MAX_HEIGHT,
    height: '100%'
  },
  specialOffersToggleMobile: {
    fontSize: typography.body1.fontSize
  },
  specialCouponLabelContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  newLabel: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    backgroundColor: palette.new_label.main,
    color: palette.new_label.contrastText,
    borderRadius: '5px',
    fontSize: '.75rem'
  }
});

export { styles };
