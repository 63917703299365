/* eslint-disable max-lines */
import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { CATEGORY_QUICK_FILTER_OPTIONS } from '../../lib/shared/enums';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = ({ spacing, palette }) => ({
  scrollContainer: {
    display: 'flex',
    overflowX: 'scroll',
    marginBottom: spacing(1)
  },
  categoryOption: {
    minWidth: 'max-content',
    marginRight: spacing(1),
    marginLeft: spacing(1),
    marginBottom: spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'

  },
  label: {
    transition: 'border-bottom 100ms',
    paddingBottom: spacing(1),
    borderBottom: '2px solid #884d4d00',
    fontSize: '14px',
    color: '#7c7d7d',
    '&.active': {
      borderBottom: `${palette.primary.main} solid 2px`,
      color: `${palette.primary.main} !important`
    }
  },
  optionImage: {
    filter: 'invert(58%) sepia(0%) saturate(3%) hue-rotate(136deg) brightness(91%) contrast(93%)',
    '&.active': {
      filter: 'none',
      color: palette.primary.main
    },
    width: '25px',
    overflow: 'hidden'
  }
});

const handleOptionClick = ({ searchState, setSearchState, value, removeOption, setActiveSection }) => {
  if (removeOption) {
    const updatedSearchState = {
      ...searchState,
      refinementList: {
        ...((searchState || {}).refinementList || []),
        categoryTheme: []
      }
    };
    setSearchState(updatedSearchState);
    setActiveSection(null);
    return;
  }

  const selectedCategories = (value || []).split(',').map(val => (val || '').trim());
  const updatedCategoryThemeList = [ ...selectedCategories];
  const updatedSearchState = {
    ...searchState,
    refinementList: {
      ...((searchState || {}).refinementList || []),
      categoryTheme: updatedCategoryThemeList
    }
  };
  setActiveSection(value);
  setSearchState(updatedSearchState);
};

const BrandQuickFilters = ({ classes, searchState, setSearchState }) => {
  const [activeSection, setActiveSection] = useState();
  return (
    <Grid container spacing={2}>
      <Grid item style={{ width: '100%' }}>
        <div className={classes.scrollContainer}>
          {CATEGORY_QUICK_FILTER_OPTIONS.map(({ label, value, image }) => {
            const isActive = activeSection === value;
            return (<div
              key={value}
              className={classNames(classes.categoryOption, { ['active']: isActive })}
              onClick={() => {
                handleOptionClick({ searchState, setSearchState, value, removeOption: isActive, setActiveSection });
              }
              }>
              <img src={image} className={classNames(classes.optionImage, { ['active']: isActive })}/>
              <Typography variant="body2" className={classNames(classes.label, { ['active']: isActive })}>
                {label}
              </Typography>
            </div>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(BrandQuickFilters);
