import React, { useCallback, useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connectSearchBox } from 'react-instantsearch-dom';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import DateBox from './DateBox';

const styles = ({ typography, breakpoints, palette }) => ({
  searchBox: {
    backgroundColor: palette.common.white,
    borderRadius: '4px',
    '& input': {
      fontSize: '1.175rem',
      padding: '10.5px 0px',
      color: palette.mode === 'light' ? palette.text.primary : palette.primary.main
    },
    [breakpoints.down('sm')]: {
      '& input': {
        fontFamily: typography.body2.fontFamily,
        fontSize: typography.body2.fontSize,
        lineHeight: typography.body2.lineHeight,
        fontWeight: typography.body2.fontWeight,
        textAlign: 'left',
        padding: '14px 0px'
      }
    }
  }
});

const SearchBox = ({
  classes,
  currentRefinement,
  refine,
  tealiumTrack,
  label,
  searchExperienceV3IsEnabled,
  isXsView = false,
  isMobileView = false,
  showAddDate = false,
  isWidthSmDownView = false,
  selectedDate,
  setSelectedDate,
  setShowNoResult,
  onScrollUp,
  keepCalendarOpen,
  clearSearchTerm,
  setClearSearchTerm,
  setHasSearchTerm,
  hasSearchTerm,
  enableDarkThemeTiles,
  customInclusiveCalendarEndDate,
  customInclusiveCalendarStartDate,
  disableCalendars,
  overrideCalendarStartMonth,
  blockCalendarChange,
  fullScreenCalendarForMobile
}, { localize }) => {
  const [searchTerm, setSearchTerm] = useState(currentRefinement || '');
  useEffect(() => {
    if (currentRefinement !== searchTerm) {
      setSearchTerm(currentRefinement);
    }
  }, [currentRefinement]);

  useEffect(() => {
    if (searchTerm && !hasSearchTerm) {
      setHasSearchTerm(true);
    } else if (!searchTerm && hasSearchTerm) {
      setHasSearchTerm(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (clearSearchTerm) {
      setSearchTerm('');
      setClearSearchTerm(false);
      refine('');
    }
  }, [clearSearchTerm]);

  const delayedRefine = useCallback(debounce(value => {
    refine(value);
  }, 500), []);

  const delayedTracking = useCallback(debounce(value => {
    if (typeof tealiumTrack === 'function' && value) {
      tealiumTrack({
        name: 'search',
        category: 'search',
        action: 'search_on_search_result',
        label: value
      });
    }
  }, 5000), []);

  return (
    <div>
      <TextField
        className={classNames({
          [classes.searchBox]: searchExperienceV3IsEnabled
        })}
        fullWidth
        variant={'outlined'}
        margin={'normal'}
        label={!searchExperienceV3IsEnabled && label}
        placeholder={searchExperienceV3IsEnabled && label}
        value={searchTerm}
        onChange={event => {
          const value = event.currentTarget.value;
          setSearchTerm(value);
          delayedRefine(value);
          delayedTracking(value);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.target.blur();
          }
        }}
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                color={searchExperienceV3IsEnabled ? 'primary' : 'action'}
                fontSize={'medium'} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {showAddDate && isMobileView ?
                <DateBox
                  overrideCalendarStartMonth={overrideCalendarStartMonth}
                  blockCalendarChange={blockCalendarChange}
                  attribute={'availableDates'}
                  disableCalendars={disableCalendars}
                  selectedDate={selectedDate}
                  isMobileView={isMobileView}
                  isWidthSmDownView={isWidthSmDownView}
                  isXsView={isXsView}
                  searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
                  setSelectedDate={setSelectedDate}
                  setShowNoResult={setShowNoResult}
                  onScrollUp={onScrollUp}
                  keepCalendarOpen={keepCalendarOpen}
                  customInclusiveCalendarEndDate={customInclusiveCalendarEndDate}
                  customInclusiveCalendarStartDate={customInclusiveCalendarStartDate}
                  fullScreenCalendarForMobile={fullScreenCalendarForMobile}
                /> :
                !searchExperienceV3IsEnabled || searchTerm &&
                  <IconButton
                    disabled={!currentRefinement}
                    onClick={() => refine('')}
                  >
                    <CloseIcon
                      color={ enableDarkThemeTiles ? 'primary' : 'default'}/>
                  </IconButton>
              }
            </InputAdornment>
          )
        }}
      >
        {localize('clearAllFilters')}
      </TextField>
    </div>
  );
};

SearchBox.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default connectSearchBox(withStyles(styles)(SearchBox));
