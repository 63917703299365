import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTheme, useMediaQuery } from '@mui/material';
import ModalHeader from './ModalHeader';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const styles = ({ palette, spacing }) => ({
  content: {
    padding: spacing(3),
    minHeight: '600px'
  },
  contentNoSidePadding: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: '600px'
  },
  container: {
    alignItems: 'flex-start'
  },
  footer: {
    borderTop: `solid 1px ${palette.divider}`,
    paddingTop: spacing(2),
    paddingBottom: spacing(1)
  },
  heightAuto: {
    height: 'auto'
  },
  minHeightForFullScreenCalendarForMobile: {
    minHeight: 450
  }
});

const Modal = props => {
  const {
    classes,
    children,
    id,
    isOpen,
    onClose,
    title,
    centerTitle = false,
    fixedTop = true,
    noSidePadding = true,
    maxWidth = 'sm',
    heightFix = false,
    disableBackdropClick,
    dialogActions,
    overrideModalToHighlightColors,
    fullScreenCalendarForMobile,
    ...muiProps
  } = props || {};
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderContentText = () => {
    const { contentText } = props;
    return contentText && (
      <DialogContentText>
        {contentText}
      </DialogContentText>
    );
  };

  const renderDialogActions = () => dialogActions && (
    <DialogActions className={classes.footer}>
      {dialogActions}
    </DialogActions>
  );

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      onClose={onClose}
      aria-labelledby={`${id}-dialog`}
      scroll="body"
      classes={{
        root: 'mobileFiltersModal',
        paperFullScreen: fullScreen && heightFix && classes.heightAuto,
        content: classes.paper,
        container: fixedTop && classes.container
      }}
      disableBackdropClick={disableBackdropClick}
      {...muiProps}
    >
      <ModalHeader id={id} onClose={onClose} centerTitle={centerTitle}
        fullScreenCalendarForMobile={fullScreenCalendarForMobile}
        overrideModalToHighlightColors={overrideModalToHighlightColors}>{title}</ModalHeader>
      <DialogContent className={classNames({
        [classes.content]: noSidePadding,
        [classes.minHeightForFullScreenCalendarForMobile]: fullScreenCalendarForMobile,
        [classes.contentNoSidePadding]: !noSidePadding
      })}>
        {renderContentText()}
        {children}
      </DialogContent>
      {renderDialogActions()}
    </Dialog>
  );
};

Modal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  fullScreen: PropTypes.bool,
  centerTitle: PropTypes.string,
  fixedTop: PropTypes.bool,
  noSidePadding: PropTypes.bool,
  maxWidth: PropTypes.number,
  heightFix: PropTypes.bool,
  disableBackdropClick: PropTypes.bool
};

export default compose(
  withStyles(styles)
)(Modal);
