// InjectedHits.js
import React, { useEffect, useState } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { ConnectInjectedHits } from './ConnectInjectedHits';
import Results from '../SearchExperiences/Results';
import isEqual from 'lodash/isEqual';

export const InjectedHits = connectHits(
  ConnectInjectedHits(({ injectedHits, ...props }) => {
    const [hits, setHits] = useState([]);
    useEffect(() => {
      if (!isEqual(injectedHits, hits)) {
        setHits(injectedHits);
      }
    }, [injectedHits]);
    return <Results
      {...props}
      hits={hits}
    />;
  }
  )
);
