/* eslint-disable max-len, id-length, max-lines */

export default {
  applyLabel: 'Aplicar',
  price: 'Precio',
  alipay: 'AliPay',
  city: {
    INVALID_CITY: 'La información ingresada en este campo no es válida. Por favor, vuelva a ingresar su ciudad.',
    REQUIRED_FIELD: 'Por favor ingrese su ciudad de facturación.'
  },
  achWire: 'ch / Wire',
  transfer: 'Transferir',
  clear: 'Claro',
  date: { REQUIRED_FIELD: 'Por favor seleccione la fecha del evento.' },
  off: 'Apagado',
  cancelled: 'Cancelado',
  noResults: 'No hay resultados',
  country: { REQUIRED_FIELD: 'Por favor seleccione su país de residencia.' },
  cashiersCheck: 'Cheque de caja',
  none: 'Ninguno',
  sendProposal: 'Crear propuesta'
};
