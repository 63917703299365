/* eslint-disable max-len, id-length, max-lines */
export default {
  searchExperiences: '搜索体验',
  searchDestinationOrExperiences: '搜索目的地或体验',
  search: '搜索',
  filters: '过滤器',
  refinements: {
    webCategoriesMain: '类型',
    durationTiming: '期间',
    eventPrice: '价格',
    displayPrice: '价格',
    availableDates: '日期',
    country: '国家',
    promotionTag: '促销活动',
    city: '目的地',
    categoryTheme: '类别',
    audience: '适合',
    priceBetween: '在 %s 和 %s 之间',
    priceBelow: '低于%s',
    priceAbove: '以上 %s',
    dateBetween: '在 %s 和 %s 之间',
    dateBefore: '在 %s 之前',
    dateAfter: '在 %s 之后',
    brandTag: '品牌',
    hierarchical: { lvl0: '类别' }
  },
  from: '从',
  to: '到',
  duration: '期间',
  clearAllFilters: '清除所有过滤器',
  clear: '清除',
  applyLabel: '申请',
  city: {
    REQUIRED_FIELD: '请输入您的账单城市。',
    INVALID_CITY: '在此字段中输入的信息无效。请重新输入您所在的城市。'
  },
  country: { REQUIRED_FIELD: '请选择您的居住国。' },
  date: { REQUIRED_FIELD: '请选择活动日期。' },
  recommended: '推荐的',
  filterByPrice: '按价格过滤',
  lowToHigh: '价格 - 从低到高',
  highToLow: '价格 - 从高到低',
  alipay: '支付宝',
  sendProposal: '创建提案',
  and: ' 和',
  sortBy: '排序方式',
  showLess: '显示较少',
  showMore: '展示更多',
  showResults: '显示结果',
  noResults: '没结果',
  price: '价格',
  none: '没有任何',
  cancelled: '取消',
  creditCardExternal: '%s 用外部信用卡支付',
  cashiersCheck: '支票/银行本票',
  achWire: 'Ach/电线',
  or: '或者',
  off: '离开',
  transfer: '转移',
  pending: '待办的',
  loadMore: '装载更多',
  companyBalance: '公司余额',
  remove: '消除',
  accepted: '公认',
  denied: '拒绝',
  likelyToSellOut: '可能售罄',
  noOfRecentBookings: '%s 最近的预订'
};
