import React, { memo } from 'react';
import Divider from '@mui/material/Divider';

// eslint-disable-next-line react/display-name
const DividerWithSpacing = memo(({ light, spacing = 1 }) => (
  <Divider light={light} component="div" style={{
    marginTop: 2 * spacing,
    marginBottom: 2 * spacing
  }}/>
));

export default (DividerWithSpacing);
