import React, { Fragment } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const styles = ({ spacing }) => ({
  chip: {
    marginRight: spacing(1)
  }
});

const CurrentRefinements = ({
  classes,
  items: _items,
  refine,
  transformLabels = {}
}, { localize }) => {
  const items = Object.values(
    _items.reduce((acc, item) => ({
      ...acc,
      [item.attribute]: item
    }), {})
  );
  return (
    <Grid container spacing={1} key={'currentRefinement'}>
      {items.map(({
        attribute,
        label,
        value,
        items: nestedItems
      }) => (
        <Fragment key={`currentRefinement-${label}`}>
          {Array.isArray(nestedItems) && nestedItems.length > 0 ? (
            nestedItems.map(nested => (
              <Grid item key={`currentRefinement-${label}-${nested.label}`}>
                <Chip
                  color={'primary'}
                  label={`${localize(`refinements.${attribute}`)}: ${nested.label}`}
                  onDelete={() => refine(nested.value)}
                  className={classes.chip}
                />
              </Grid>
            ))
          ) : (
            <Grid item>
              <Chip
                color={'primary'}
                label={`${localize(`refinements.${attribute}`)}: ${transformLabels[attribute] ?
                  transformLabels[attribute]({ label }) : label}`}
                onDelete={() => refine(value)}
                className={classes.chip}
              />
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

CurrentRefinements.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default connectCurrentRefinements(withStyles(styles)(CurrentRefinements));
