/* eslint-disable max-len, id-length, max-lines */
export default {
  searchExperiences: 'تجارب البحث',
  searchDestinationOrExperiences: 'ابحث عن الوجهات أو الخبرات',
  search: 'يبحث',
  filters: 'المرشحات',
  refinements: {
    webCategoriesMain: 'يكتب',
    durationTiming: 'مدة',
    eventPrice: 'السعر',
    displayPrice: 'السعر',
    availableDates: 'تواريخ',
    country: 'دولة',
    promotionTag: 'الترقيات',
    city: 'المكان المقصود',
    categoryTheme: 'فئة',
    audience: 'جيدة ل',
    priceBetween: 'بين %s و %s',
    priceBelow: 'أدناه %s',
    priceAbove: 'أعلى من %s',
    dateBetween: 'بين %s و %s',
    dateBefore: 'قبل %s',
    dateAfter: 'بعد %s',
    brandTag: 'ماركة',
    hierarchical: { lvl0: 'فئات' }
  },
  from: 'من',
  to: 'ل',
  duration: 'مدة',
  clearAllFilters: 'مسح كافة عوامل التصفية',
  clear: 'صافي',
  applyLabel: 'تطبيق',
  city: {
    REQUIRED_FIELD: 'الرجاء إدخال مدينة الفواتير الخاصة بك.',
    INVALID_CITY: 'المعلومات التي تم إدخالها في هذا الحقل غير صالحة. الرجاء إعادة إدخال مدينتك.'
  },
  country: { REQUIRED_FIELD: 'يرجى تحديد بلد إقامتك.' },
  date: { REQUIRED_FIELD: 'الرجاء تحديد تاريخ الحدث.' },
  recommended: 'موصى به',
  filterByPrice: 'تصفية حسب السعر',
  lowToHigh: 'السعر من الارخص للاعلى',
  highToLow: 'السعر الاعلى الى الادنى',
  alipay: 'AliPay',
  sendProposal: 'إنشاء اقتراح',
  and: ' و',
  sortBy: 'ترتيب حسب',
  showLess: 'عرض أقل',
  showMore: 'أظهر المزيد',
  showResults: 'أظهر النتائج',
  noResults: 'لا نتائج',
  price: 'السعر',
  none: 'لا أحد',
  cancelled: 'ألغيت',
  creditCardExternal: '%s مدفوعة ببطاقة ائتمان خارجية',
  cashiersCheck: 'شيك / شيك أمين الصندوق',
  achWire: 'آتش / سلك',
  or: 'أو',
  off: 'إيقاف',
  transfer: 'تحويل',
  pending: 'قيد الانتظار',
  loadMore: 'تحميل المزيد',
  companyBalance: 'رصيد الشركة',
  remove: 'يزيل',
  accepted: 'وافقت',
  denied: 'رفض',
  likelyToSellOut: 'من المرجح أن تبيع',
  noOfRecentBookings: '%s الحجوزات الأخيرة'
};
