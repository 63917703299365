import withStyles from '@mui/styles/withStyles';
import schedulerStyles from './schedulerStyles';
import rheostatSliderStyles from './rheostatSliderStyles';
import CalendarStyles from './CalendarStyles';

const styles = theme => {
  const primary = theme.palette.calendar || theme.palette.primary;
  return ({
    '@global': {
      'body, input, textarea, select, button': {
        fontFamily: '"Roboto", sans-serif',
        lineHeight: 1.4
      },
      '.DayPickerKeyboardShortcuts_show__bottomRight': {
        borderRightColor: `${primary.main} !important`
      },
      '.CalendarDay__default': {
        background: `${primary.main} !important`,
        color: `${primary.contrastText} !important`,
        border: `1px double ${primary.contrastText} !important`,
        '& div': {
          display: 'contents'
        }
      },
      '.CalendarDay__highlighted_calendar': {
        background: `${theme.palette.warning.light} !important`,
        color: `${theme.palette.warning.contrastText} !important`,
        border: `1px double ${theme.palette.warning.contrastText} !important`,
        '& div': {
          display: 'contents'
        }
      },
      '.CalendarDay__selected': {
        color: `${primary.main} !important`,
        background: `${primary.contrastText} !important`,
        border: `1px double ${primary.main} !important`,
        '& div': {
          display: 'contents'
        }
      },
      '.CalendarDay__blocked_calendar': {
        background: `${theme.palette.action.disabled} !important`,
        color: `${primary.contrastText} !important`,
        border: `1px double ${primary.contrastText} !important`,
        '& div': {
          display: 'contents'
        }
      },
      '.CalendarDay__blocked_out_of_range': {
        background: `${theme.palette.action.disabled} !important`,
        '& div': {
          display: 'contents'
        }
      },
      '.CalendarMonth_caption': {
        fontSize: '14px !important',
        paddingTop: '25px !important',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px !important',
          paddingTop: '22px !important'
        }
      },
      ...CalendarStyles(theme),
      ...schedulerStyles(theme),
      ...rheostatSliderStyles(theme)
    }
  });
};

const GlobalStyles = () => false;

export default withStyles(styles, { name: 'GlobalStyles' })(GlobalStyles);
