/* eslint-disable max-len, id-length, max-lines */
export default {
  applyLabel: 'להגיש מועמדות',
  price: 'מחיר',
  alipay: 'AliPay',
  city: {
    INVALID_CITY: 'המידע שהוזן בשדה זה אינו חוקי. אנא הכנס מחדש את עירך.',
    REQUIRED_FIELD: 'אנא הכנס את עיר החיוב שלך.'
  },
  achWire: 'אח / חוט',
  transfer: 'העברה',
  clear: 'ברור',
  date: { REQUIRED_FIELD: 'בחר תאריך אירוע.' },
  off: 'כבוי',
  cancelled: 'מבוטל',
  noResults: 'אין תוצאות',
  country: { REQUIRED_FIELD: 'אנא בחרו במדינת מגוריכם.' },
  cashiersCheck: 'צ\'ק קופאית',
  none: 'אף אחד',
  sendProposal: 'צור הצעה'
};
