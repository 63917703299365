/* eslint-disable max-len, id-length, max-lines */
export default {
  applyLabel: '適用する',
  price: '価格',
  alipay: 'AliPay',
  city: {
    INVALID_CITY: 'このフィールドに入力された情報は無効です。都市を再入力してください。',
    REQUIRED_FIELD: '請求先都市を入力してください。'
  },
  achWire: 'Ach/Wire',
  transfer: '転送',
  clear: 'クリア',
  date: { REQUIRED_FIELD: 'イベントの日付を選択してください。' },
  off: 'オフ',
  cancelled: 'キャンセル',
  noResults: '結果がありません',
  country: { REQUIRED_FIELD: '居住国を選択してください。' },
  cashiersCheck: 'キャッシャーズチェック',
  none: '無し',
  sendProposal: '提案を作成'
};
