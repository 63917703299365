/* eslint-disable max-lines,import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { forbidExtraProps } from 'airbnb-prop-types';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import momentWithLocale from 'moment';
import omit from 'lodash/omit';
import { DayPickerRangeController, isInclusivelyAfterDay } from 'react-dates';
import ScrollableOrientationShape from 'react-dates/lib/shapes/ScrollableOrientationShape';
import { END_DATE, HORIZONTAL_ORIENTATION, START_DATE } from 'react-dates/constants';
import { compose } from 'recompose';
import withStyles from '@mui/styles/withStyles';
import styles from './styles';
import classNames from 'classnames';
import { Text } from 'react-localize';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Typography, Divider } from '@mui/material';

const propTypes = forbidExtraProps({
  // example props for the demo
  autoFocusEndDate: PropTypes.bool,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
  startDateOffset: PropTypes.func,
  endDateOffset: PropTypes.func,
  showInputs: PropTypes.bool,
  minDate: momentPropTypes.momentObj,
  maxDate: momentPropTypes.momentObj,

  keepOpenOnDateSelect: PropTypes.bool,
  minimumNights: PropTypes.number,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,
  daysViolatingMinNightsCanBeClicked: PropTypes.bool,

  // DayPicker props
  enableOutsideDays: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  orientation: ScrollableOrientationShape,
  verticalHeight: PropTypes.number,
  withPortal: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
  renderCalendarInfo: PropTypes.func,
  renderMonthElement: PropTypes.func,
  renderMonthText: PropTypes.func,

  navPrev: PropTypes.node,
  navNext: PropTypes.node,
  renderNavPrevButton: PropTypes.func,
  renderNavNextButton: PropTypes.func,

  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  renderCalendarDay: PropTypes.func,
  renderDayContents: PropTypes.func,
  renderKeyboardShortcutsButton: PropTypes.func,
  renderKeyboardShortcutsPanel: PropTypes.func,

  // i18n
  monthFormat: PropTypes.string,

  isRTL: PropTypes.bool
});

const defaultProps = {
  // example props for the demo
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  startDateOffset: undefined,
  endDateOffset: undefined,
  showInputs: false,
  minDate: null,
  maxDate: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  isDayBlocked: () => false,
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,
  daysViolatingMinNightsCanBeClicked: false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,
  renderMonthText: null,
  renderMonthElement: null,
  renderKeyboardShortcutsButton: undefined,
  renderKeyboardShortcutsPanel: undefined,

  // navigation related props
  navPrev: null,
  navNext: null,
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY'
};

class DateRangePickerController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
  }

  componentDidMount() {
    const { locale } = this.props;
    if (locale !== 'en') {
      momentWithLocale.locale(locale);
    }
  }

  componentWillUnmount() {
    momentWithLocale.locale('en');
  }

  onDatesChange({ startDate, endDate }) {
    const { onDatesChange, onClose } = this.props;
    let _endDate = endDate && endDate.locale('en');

    if (this.state.focusedInput === START_DATE) {
      onDatesChange({
        startDate,
        endDate: undefined
      });
      if (endDate) {
        _endDate = undefined;
      }
    }

    if (this.state.focusedInput === END_DATE) {
      onDatesChange({
        startDate,
        endDate: _endDate
      });
      onClose();
    }

    this.setState({
      startDate,
      endDate: _endDate
    });
  }

  onFocusChange(focusedInput) {
    this.setState({
      focusedInput: !focusedInput ? START_DATE : focusedInput
    });
  }

  onApply = () => {
    const { startDate, endDate } = this.state;
    const { onDatesChange, onClose } = this.props;
    let _endDate = endDate && endDate.locale('en');

    if (!_endDate) {
      _endDate = startDate && startDate.locale('en');
    }

    this.setState({
      endDate: _endDate
    });
    onDatesChange({ startDate, endDate: _endDate });
    this.onFocusChange(null);
    onClose();
  };

  onClear = () => {
    const { onDatesChange, onClose, fullScreenCalendarForMobile } = this.props;
    this.setState({
      startDate: null,
      endDate: null
    });
    onDatesChange({ startDate: null, endDate: null });
    this.onFocusChange(null);

    if (!fullScreenCalendarForMobile) {
      onClose();
    }
  };

  renderCaptionForDatePreset = () => {
    const { classes } = this.props;
    const { startDate, endDate } = this.state;
    return (<>
      <Grid container className={classes.captionContainer}>
        <Grid item xs={12}>
          <Divider orientation="horizontal" variant="middle"/>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.infoDates}>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={12} className={classes.selectedDatesCaption}>
                  <Text message={'from'}/>
                </Grid>
                <Grid item xs={12} className={classes.selectedDates}>
                  <Typography>
                    {startDate ? moment(startDate).format('MMMM D') : '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'center'}>
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={12} className={classes.selectedDatesCaption}>
                  <Text message={'to'}/>
                </Grid>
                <Grid item xs={12} className={classes.selectedDates}>
                  <Typography>
                    {endDate ? moment(endDate).format('MMMM D') : '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>);
  };

  renderDatePresets() {
    const { classes, onApplyRef, onClearRef, fullScreenCalendarForMobile } = this.props;
    const { startDate } = this.state;
    return (
      <>
        {!fullScreenCalendarForMobile && this.renderCaptionForDatePreset()}
        <Grid container justifyContent={'flex-end'} spacing={2}
          className={classNames({
            [classes.infoButton]: true,
            [classes.hideContainer]: fullScreenCalendarForMobile
          })}
        >
          <Grid item>
            <Button
              ref={onClearRef}
              color={'primary'}
              disabled={!startDate}
              onClick={() => this.onClear()}
            >
              <Text message={'clear'}/>
            </Button>
          </Grid>
          <Grid item>
            <Button
              ref={onApplyRef}
              color={'primary'}
              variant={'contained'}
              disabled={!startDate}
              onClick={() => this.onApply()}
            >
              <Text message={'applyLabel'}/>
            </Button>
          </Grid>
        </Grid>
      </>

    );
  }

  isOutsideRange = day => {
    const { customInclusiveCalendarEndDate: endDate, customInclusiveCalendarStartDate: startDate } = this.props;
    const isBeforeStartDate = day.isBefore(startDate ? moment(startDate, 'YYYY/MM/DD') : moment().subtract(1, 'days'));
    const isAfterEndDate = endDate ? !day.isBefore(moment(endDate, 'YYYY/MM/DD').add(1, 'days')) : false;
    return isBeforeStartDate || isAfterEndDate;
  };

  render() {
    const { focusedInput, startDate, endDate } = this.state;
    const { classes, orientation, numberOfMonths, initialStartDate, initialEndDate, overrideCalendarStartMonth,
      customInclusiveCalendarEndDate, disableCalendars, customInclusiveCalendarStartDate,
      blockCalendarChange, fullScreenCalendarForMobile } = this.props;
    const props = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'classes',
      'onDatesChange',
      'onClose'
    ]);
    //eslint-disable-next-line no-unused-vars
    const { ...rest } = props;

    return (
      <div
        ref={this.containerRef}
        className={classNames({
          [classes.root]: true,
          [classes.hideCalendarMonthChange]: blockCalendarChange,
          [classes.fullScreenCalendarForMobile]: fullScreenCalendarForMobile
        })}
        data-bdd="date-range-picker"
        id="date-range-picker"
      >
        <DayPickerRangeController
          {...rest}
          {...overrideCalendarStartMonth && { initialVisibleMonth: () => moment(overrideCalendarStartMonth) }}
          disabled={disableCalendars}
          minimumNights={0}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={initialStartDate || startDate}
          endDate={initialEndDate || endDate}
          orientation={orientation}
          numberOfMonths={numberOfMonths}
          renderCalendarInfo={this.renderDatePresets}
          isOutsideRange={customInclusiveCalendarEndDate || customInclusiveCalendarStartDate ?
            this.isOutsideRange : defaultProps.isOutsideRange}/>
      </div>
    );
  }
}

DateRangePickerController.propTypes = propTypes;
DateRangePickerController.defaultProps = defaultProps;

export default compose(
  // withWidth(),
  withStyles(styles)
)(DateRangePickerController);
