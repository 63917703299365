export default ({ data }) => ({ palette }) => {
  if (!palette || !data) {
    return {};
  }
  return (data || []).reduce((accum, { id, theme }) => ({
    ...accum,
    [`webSalesBannerChip_${id}`]: {
      backgroundColor: palette[theme].main,
      color: palette[theme].contrastText,
      border: `solid 2px ${palette[theme].contrastText}`
    }
  }), {});
};
