import React from 'react';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = ({ spacing }) => ({
  embedSocialReviews: {
    height: spacing(3),
    marginTop: spacing(1) / -2,
    '& iframe': {
      height: `${spacing(3)} !important`,
      border: 'none !important'
    }
  }
});

const EmbedSocialReviews = ({ classes, dataRef }) => (
  <div
    className={classNames('embedsocial-hashtag', [classes.embedSocialReviews])}
    data-ref={dataRef}>
  </div>
);

export default withStyles(styles)(EmbedSocialReviews);
