
const noop = () => {};

const tealiumTrack = payload => {
  const { category, action, label, name, ...rest } = payload || {};

  const data = {
    event_category: category,
    event_action: action,
    event_label: label || name,
    event_name: name,
    ...Object.entries(rest)
      .filter(([key]) => key.indexOf('__') !== 0)
      .reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value
      }), {})
  };
  ((window.utag || {}).link || noop).call(window.utag, data);
  // if (window.analytics && window.analytics.track) {
  //   window.analytics.track(action, data);
  // }
};

export default tealiumTrack;
