// eslint-disable-next-line import/no-anonymous-default-export
export default ({ palette, typography }) => ({
  '.rbc-toolbar > span > button': {
    backgroundColor: palette.secondary.main,
    color: palette.secondary.contrastText,
    margin: 8,
    fontSize: 14,
    border: 'none'
  },
  '.rbc-toolbar > span > button:hover': {
    backgroundColor: palette.secondary.dark,
    color: palette.secondary.contrastText,
    border: 'none'
  },
  '.rbc-toolbar > span > button:focus': {
    backgroundColor: palette.secondary.dark,
    color: palette.secondary.contrastText,
    outline: 0,
    border: 'none'
  },
  '.rbc-toolbar > span > button.rbc-active:hover, .rbc-toolbar > span > button.rbc-active:focus': {
    backgroundColor: palette.secondary.dark,
    color: palette.secondary.contrastText,
    border: 'none'
  },
  '.rbc-toolbar > span > button:first-child:not(:last-child)': {
    borderRadius: 30
  },
  '.rbc-toolbar > span > button:last-child:not(:first-child)': {
    borderRadius: 30
  },
  '.rbc-toolbar > span > button:not(:last-child):not(:first-child)': {
    borderRadius: 30
  },
  '.rbc-toolbar > span > button.rbc-active': {
    backgroundColor: palette.secondary.main
  },
  '.rbc-toolbar .rbc-toolbar-label': {
    fontSize: 20
  },
  '.rbc-event.event-default': {
    padding: 0
  },
  '.rbc-time-view .rbc-event.event-default': {
    backgroundColor: 'transparent',
    border: 'none'
  },
  '.rbc-time-view .rbc-event-label': {
    display: 'none'
  },
  '.rbc-month-view .rbc-event.event-default': {
    backgroundColor: palette.background.default,
    padding: 0
  },
  '.rbc-overlay .rbc-event.event-default': {
    backgroundColor: palette.background.default,
    padding: 0
  },
  '.rbc-event.event-selected': {
    backgroundColor: palette.primary.main
  },
  '.rbc-event.rbc-selected:focus': {
    outline: 0
  },
  '.rbc-event .rbc-event-content': {
    zIndex: 2,
    fontSize: '12px'
  },
  '.rbc-event': {
    padding: '0px !important',
    overflow: 'hidden',
    backgroundColor: 'white !important'
  },
  '.rbc-show-more': {
    color: palette.primary.main,
    zIndex: 4,
    fontWeight: 'bold',
    fontSize: typography.caption.fontSize,
    height: 'auto',
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    fontFamily: 'inherit'
  },
  '.rbc-overlay': {
    maxHeight: '85vh',
    overflow: 'auto'
  }
});
