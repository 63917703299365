import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Text } from 'react-localize';
import classNames from 'classnames';

const styles = ({ typography, breakpoints, palette }) => ({
  buttonUnderline: {
    textDecoration: 'underline',
    fontSize: typography.body1.fontSize
  },
  buttonMobileView: {
    fontSize: '1rem'
  },
  [breakpoints.between(360, 'sm')]: {
    heightFill: { fontSize: '3.5vw' }
  },
  [breakpoints.down(360)]: {
    heightFill: { height: '100%' }
  },
  highlightBackground: {
    background: palette.highlight.highlightBackground,
    color: 'white !important'
  },
  highlightOutline: {
    border: `1px solid ${palette.common.white}`,
    color: palette.common.white
  }
});

const ClearRefinements = ({
  classes,
  items,
  refine,
  size = 'medium',
  variant = 'outlined',
  buttonText = 'clearAllFilters',
  count = null,
  hideOnNoFilters,
  showPriceWithFees,
  setShowPriceWithFees,
  searchExperienceV3IsEnabled,
  enableAlwaysShowClearFilters = false,
  showSpecialOffers,
  setShowSpecialOffers,
  specialCouponFilterOn,
  setSpecialCouponFilterOn,
  handleClick,
  selectedDate,
  setSelectedDate,
  isWidthSmDownView,
  hideRefinements = [],
  setClearSearchTerm,
  hasSearchTerm,
  disabled = false,
  overrideDefaultDisableSettings = false,
  onScrollUp = () => {},
  permanentSearchState,
  overrideModalToHighlightColors,
  resetDefaultState,
  disableCalendars
}) =>

  (!hideOnNoFilters || ((items || []).length > 0 && items.find(({ id }) => !hideRefinements.includes(id)))
|| showPriceWithFees || enableAlwaysShowClearFilters || showSpecialOffers || hasSearchTerm || specialCouponFilterOn
  || ((!!(selectedDate || {}).min) && !!(selectedDate || {}).max) && !disableCalendars) && (
    <div className={classes.heightFill}>
      <Button
        className={classNames({
          [classes.buttonUnderline]: searchExperienceV3IsEnabled,
          [classes.buttonMobileView]: isWidthSmDownView,
          [classes.heightFill]: true,
          [classes.highlightBackground]: overrideModalToHighlightColors && variant === 'contained',
          [classes.highlightOutline]: overrideModalToHighlightColors && variant === 'outlined'
        })}
        size={size}
        disabled={disabled || (!overrideDefaultDisableSettings &&
          !(items || []).length || ((items || []).length && !items.some(({ id }) => !hideRefinements.includes(id)))
        )
          && !showPriceWithFees && !showSpecialOffers && !specialCouponFilterOn
          && (!(selectedDate || {}).min || !(selectedDate || {}).max) && !hasSearchTerm}
        variant={variant}
        color={'primary'}
        fullWidth
        onClick={() => {
          if (handleClick) {
            handleClick();
          } else {
            if (showPriceWithFees) {
              setShowPriceWithFees(false);
            }
            if (showSpecialOffers) {
              setShowSpecialOffers(false);
            }
            if (specialCouponFilterOn) {
              setSpecialCouponFilterOn(false);
            }
            if (setSelectedDate && !disableCalendars) {
              setSelectedDate({});
            }
            setClearSearchTerm(true);
            const { refinementList } = permanentSearchState || {};
            const clearItems = items.filter(({ id }) => !hideRefinements.includes(id));
            refine(clearItems);
            if ((Object.keys(refinementList || {}) || []).length) {
              resetDefaultState();
            }
            onScrollUp();
          }
        }}
      >
        <Text message={buttonText} values={count ? [count] : null} />
      </Button>
    </div>
  )
;

ClearRefinements.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default connectCurrentRefinements(withStyles(styles)(ClearRefinements));
