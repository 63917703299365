import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from './GlobalStyles';

class RootStyle extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { theme, children } = this.props;
    return (
      <ThemeProvider theme={createTheme(theme)}>
        <Fragment>
          <CssBaseline />
          <GlobalStyles/>
          {children}
        </Fragment>
      </ThemeProvider>
    );
  }
}

RootStyle.propTypes = {
  theme: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};

export default RootStyle;
