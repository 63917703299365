export default (str1, str2, desc) => {
  const string1 = desc ? str2 : str1;
  const string2 = desc ? str1 : str2;
  if (string1 < string2) {
    return -1;
  }
  if (string1 > string2) {
    return 1;
  }
  return 0;
};

