import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { connectPagination } from 'react-instantsearch-dom';
import Pagination from '../Pagination';

const styles = () => ({
  root: {
  }
});

const AlgoliaPagination = ({
  selectedPage,
  numberOfPages,
  onScrollUp,
  tealiumTrack,
  setSelectedPage
}) => (numberOfPages ? (
  <div>
    <Pagination
      tealiumTrack={tealiumTrack}
      count={numberOfPages}
      current={selectedPage}
      onScrollUp={onScrollUp}
      setSelectedPage={setSelectedPage}
    />
  </div>
) : (null));

export default connectPagination(withStyles(styles)(AlgoliaPagination));
