import cookie from 'cookiejs';

const COOKIE_NAME = '_achoralgoliainfo';
const hostNameParts = (window.location.hostname || '').split('.');
const updatedHostName = window.location.hostname === 'localhost' ?
  window.location.hostName : (`.${[hostNameParts[1], hostNameParts[2]].join('.')}`);

export const setAlgoliaSearchInfo = ({ index, queryID, position, objectID, expires = 1 }) => {
  if (!index || !queryID || !position) {
    return;
  }
  cookie.set(COOKIE_NAME, JSON.stringify({ index, queryID, position, objectID }),
    { expires, path: '/', domain: updatedHostName });
};

export const getAlgoliaSearchInfo = () => JSON.parse(cookie.get(COOKIE_NAME));
