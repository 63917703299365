const algoliasearch = require('algoliasearch');
const algolia = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_API_KEY
};

const getLatLongString = async city => {
  const apiUrl = 'https://my.hornblower.com/api/nearbyCities?idx=cealgolia&includeCitiesWithNoTours=true';
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    const cityData = (data || []).find(obj => obj.city?.toLowerCase() === city?.toLowerCase());
    if (cityData) {
      return `${cityData?.lat}, ${cityData?.lon}`;
    }
    return null;
  } catch (msg) {
    // eslint-disable-next-line no-console
    console.log(msg);
    return null;
  }
};

const client = algoliasearch(algolia.appId, algolia.apiKey);

const search = async({ searchState, index: searchIndex } = {}) => {
  const {
    refinementList,
    query,
    range
  } = searchState || {};
  const city = refinementList?.city?.[0] || '';
  const searchWithQueryAndRange = !!query && (range?.availableDates?.min || range?.availableDates?.max);
  const minDate = range?.availableDates?.min || '';
  const maxDate = range?.availableDates?.max || '';
  const searchIndexToUse = searchWithQueryAndRange || !city ? 'prodv3_ce_experiences_geo' : searchIndex;
  const index = client.initIndex(searchIndexToUse);

  const minDateFilter = minDate ? `availableDates>=${minDate}` : '';
  const maxDateFilter = maxDate ? `availableDates<=${maxDate}` : '';
  let flag = 0;
  let res;
  if (!city) {
    res = await index
      .search('', {
        filters: [
          'privateEvent:false',
          'hideInWebsiteSearch:false',
          'hasNoAvailableDates:false'
        ].filter(val => val)
          .join(' AND '),
        aroundLatLngViaIP: true,
        aroundRadius: 'all'
      });
  } else {
    res = await index
      .search('', {
        filters: [
          'privateEvent:false',
          'hideInWebsiteSearch:false',
          'hasNoAvailableDates:false',
          ...((Object.keys((refinementList || {})) || []).map(key => (
            Array.isArray((refinementList || {})[key]) && (refinementList || {})[key].length ?
              `(${(refinementList || {})[key].map(item => `${key}:"${item}"`)
                .join(' OR ')})`
              : undefined)
          ))
        ].filter(val => val)
          .join(' AND ')
      });
  }

  if (flag === 0 && res && res?.hits?.length === 0 && !searchWithQueryAndRange && city) {
    const indexSearch = client.initIndex('prodv3_ce_experiences_geo');
    if (minDateFilter !== '' && maxDateFilter !== '') {
      const result = await indexSearch
        .search('', {
          filters: [
            'privateEvent:false',
            'hideInWebsiteSearch:false',
            'hasNoAvailableDates:false',
            minDateFilter,
            maxDateFilter,
            `NOT city:"${city}"`
          ].filter(val => val)
            .join(' AND '),
          aroundLatLng: await getLatLongString(city) || '',
          aroundRadius: 'all',
          getRankingInfo: true
        });
      flag = 1;
      const response = { result, noInventory: true };
      return response;
    }
    const result = await indexSearch
      .search('', {
        filters: [
          'privateEvent:false',
          'hideInWebsiteSearch:false',
          'hasNoAvailableDates:false'
        ].filter(val => val)
          .join(' AND '),
        aroundLatLngViaIP: true,
        aroundRadius: 'all'
      });
    flag = 1;
    const response = { result, noInventory: true };
    return response;
  }
  const response = { result: res, noInventory: false };
  return response;
};

export default search;

