import React, { Fragment } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { isTrue } from '../../lib/booleanCheck';

const ToggleSwitch = ({ input: { value, onChange }, label, disabled = false, required = false, ...rest }) => {
  const isChecked = isTrue(value);
  return (
    <FormControlLabel
      control={
        <Switch
          disabled={disabled}
          checked={isChecked}
          onChange={() => onChange(!isChecked)}
          value="true"
          {...rest}
        />
      }
      label={(
        <Fragment>
          {label}
          {!!required && <span>*</span>}
        </Fragment>
      )}
    />
  );
};

export default ToggleSwitch;
