/* eslint-disable max-len, id-length, max-lines */
export default {
  applyLabel: 'Aplique',
  price: 'Preço',
  alipay: 'AliPay',
  city: {
    INVALID_CITY: 'As informações inseridas neste campo são inválidas. Digite novamente sua cidade.',
    REQUIRED_FIELD: 'Digite sua cidade de cobrança.'
  },
  achWire: 'Ach / Wire',
  transfer: 'Transferir',
  clear: 'Claro',
  date: { REQUIRED_FIELD: 'Por favor, selecione a data do evento.' },
  off: 'Fora',
  cancelled: 'Cancelado',
  noResults: 'Sem resultados',
  country: { REQUIRED_FIELD: 'Por favor, selecione seu país de residência.' },
  cashiersCheck: 'O cheque do caixa',
  none: 'Nenhum',
  sendProposal: 'Criar proposta'
};
