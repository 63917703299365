/* eslint-disable max-lines,max-len */
export default () => ({
  '.rbs .header1-text': {
    fontSize: '25px',
    color: '#90CDF9',
    fontWeight: 500
  },

  '.rbs .anticon': {
    padding: '0 4px'
  },

  '.rbs .header2-text': {
    fontSize: '13px'
  },

  '.rbs .header3-text': {
    fontSize: '12px',
    fontWeight: 500
  },

  '.rbs .base-text': {
    fontSize: '12px'
  },

  '.rbs .help-text': {
    fontSize: '12px',
    color: '#999'
  },

  '.rbs .subresource': {
    fontSize: '12px',
    color: '#999',
    marginLeft: 8
  },

  '.rbs .disabled-text': {
    fontSize: '12px',
    color: '#ccc'
  },

  '.rbs .scheduler': {
    margin: '20px auto',
    borderSpacing: 0
  },

  '.rbs .scheduler td': {
    padding: 0
  },

  '.rbs .expander-space': {
    overflow: 'hidden',
    display: 'inline-block',
    width: '1em',
    height: '1em',
    lineHeight: '1em',
    fontSize: '.9em',
    verticalAlign: 'middle',
    marginTop: '-1%'
  },

  '.rbs .resource-view': {
    border: '1px solid #e9e9e9',
    overflow: 'hidden'
  },

  '.rbs .scheduler-view': {
    border: '1px solid #e9e9e9',
    margin: '0 0 0 -1px',
    padding: 0
  },

  '.rbs .scheduler-content': {
    position: 'relative',
    zIndex: 2
  },

  '.rbs .scheduler-bg': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1
  },

  '.rbs table.resource-table, .rbs table.scheduler-bg-table, .rbs table.scheduler-table': {
    width: '100%',
    margin: '0',
    padding: '0',
    borderSpacing: '0',
    textAlign: 'center'
  },

  '.rbs table.scheduler-table': {
    border: '1px solid #e9e9e9'
  },

  '.rbs table.scheduler-content-table': {
    margin: '0',
    padding: '0',
    border: '0 solid #e9e9e9',
    borderSpacing: '0'
  },

  '.rbs table.resource-table tr, .rbs table.scheduler-bg-table tr, .rbs table.scheduler-table tr': {
    borderBottom: '1px solid #e9e9e9'
  },

  '.rbs table.resource-table th, .rbs table.scheduler-table th, .rbs table.resource-table td, .rbs table.scheduler-bg-table td, .rbs table.scheduler-table td': {
    borderRight: '1px solid #e9e9e9',
    borderBottom: '1px solid #e9e9e9'
  },

  '.rbs table.scheduler-bg-table th': {
    borderRight: '1px solid #e9e9e9'
  },

  '.rbs table.resource-table tr th:last-child, .rbs table.scheduler-table tr th:last-child, .rbs table.resource-table tr td:last-child, .rbs table.scheduler-table tr td:last-child': {
    borderRight: 0
  },
  '.rbs table.scheduler-table tr:last-child td': {
    borderBottom: 0
  },

  '.rbs .timeline-event': {
    position: 'absolute'
  },

  '.rbs .day-event': {
    position: 'relative',
    display: 'inline-block',
    margin: '0px 5px'
  },

  '.rbs .day-event-container': {
    textAlign: 'left',
    padding: '5px 5px 0 5px'
  },

  '.rbs .round-all': {
    borderRadius: 14
  },

  '.rbs .round-head': {
    borderRadius: '14px 0px 0px 14px'
  },

  '.rbs .round-tail': {
    borderRadius: '0px 14px 14px 0px'
  },

  '.rbs .round-none': {
    borderRadius: 0
  },

  '.rbs .event-container': {
    position: 'relative'
  },

  '.rbs .event-container:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)'
  },

  '.rbs .event-item': {
    margin: '1px 0',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#fff',
    paddingRight: '5px !important',
    maxHeight: '45px'
  },

  '.rbs .overflow-text': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: '5px !important'
  },

  '.rbs .status-dot': {
    width: '14px',
    height: '14px',
    borderRadius: '7px'
  },

  '.rbs .ant-radio-button-wrapper-checked': {
    backgroundColor: '#108EE9',
    color: '#FFFFFF'
  },

  '.rbs .icon-nav:hover': {
    color: '#1E90FF !important',
    boxShadow: '0 0 0px !important',
    cursor: 'pointer'
  },

  '.rbs .add-more-popover-overlay': {
    position: 'absolute',
    zIndex: 5,
    border: '1px solid #e5e5e5',
    backgroundColor: '#fff',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.25)',
    padding: '10px'
  },

  '.rbs .popover-calendar': {
    width: '300px'
  },

  '.rbs .popover-calendar .ant-calendar': {
    boxShadow: '0 1px 6px rgba(0,0,0,0) !important'
  },

  '.event-resizer': {
    position: 'absolute',
    zIndex: '4',
    display: 'block',
    width: '7px',
    top: '-1px',
    bottom: '-1px'
  },

  '.event-start-resizer': {
    cursor: 'w-resize',
    left: -1
  },

  '.event-end-resizer': {
    cursor: 'e-resize',
    right: -1
  },

  '.selected-area': {
    position: 'absolute'
  }
});
