import algoliaAnalytics from 'search-insights';
import uuidV4 from 'uuid/v4';

const algolia = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_API_KEY
};

const getCorrelationId = () => {
  let correlationId;
  try {
    correlationId = window.localStorage.getItem('anchor_algolia_id');
  } catch (err) {
    // do nothing - generate new uuid everytime
  }
  if (!correlationId) {
    correlationId = uuidV4();
    try {
      window.localStorage.setItem('anchor_algolia_id', correlationId);
    } catch (err) {
      // do nothing - generate new uuid everytime
    }
  }
  return correlationId;
};

const sendEvent = payload => {
  try {
    const { eventName, index, queryID, objectID, position, eventType } = payload || {};
    const userToken = (getCorrelationId() || '').replace(/[#_.:]/g, '');
    if (!userToken) {
      throw 'no-user-token';
    }
    algoliaAnalytics(eventType, {
      eventName,
      index,
      userToken,
      queryID,
      objectIDs: [objectID],
      ...(position ? { positions: [position] } : {})
    },
    {
      headers: {
        'X-Algolia-Application-Id': algolia.appId,
        'X-Algolia-API-Key': algolia.apiKey
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export { getCorrelationId };
export default sendEvent;
