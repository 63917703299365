import React from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';

const styles = ({ spacing, palette }) => ({
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cruiseFilters: {
    width: '100%',
    borderRadius: spacing(2),
    backgroundColor: `${palette.common.white} !important`,
    color: `${palette.primary.main} !important`,
    display: 'flex',
    alignItems: 'center'
  },
  cruiseFiltersActive: {
    backgroundColor: `${palette.primary.main} !important`,
    color: `${palette.common.white} !important`,
    display: 'flex',
    alignItems: 'center'
  }
});

const handleOptionClick = ({ searchState, setSearchState, cruiseFilter, removeOption }) => {
  const {
    refinementList: {
      categoryTheme = []
    } = {}
  } = searchState || {};
  const updatedCategoryTheme = removeOption ? (categoryTheme || []).filter(opt => opt !== cruiseFilter)
    : [...(categoryTheme || []), cruiseFilter];
  const updatedSearchState = {
    ...searchState,
    refinementList: {
      ...(searchState || {}).refinementList,
      categoryTheme: updatedCategoryTheme
    }
  };
  setSearchState(updatedSearchState);
};

const CruiseQuickFilters = ({ classes, searchState, setSearchState, mobileQuickFilterCruiseTypes }) => {
  const {
    refinementList: {
      categoryTheme = []
    } = {}
  } = searchState || {};
  return (
    <Grid container spacing={1} justifyContent={'center'} alignContent={'center'}>
      {
        (Object.keys(mobileQuickFilterCruiseTypes) || []).map(cruiseFilter => {
          const active = !!(categoryTheme || []).find(cat => cat === mobileQuickFilterCruiseTypes[cruiseFilter]);
          return (<Grid item key={cruiseFilter} xs={6} className={classes.itemContainer}>
            <Button className={classNames({
              [classes.cruiseFilters]: true,
              [classes.cruiseFiltersActive]: active
            })}
            onClick={() => handleOptionClick({ searchState, setSearchState,
              cruiseFilter: mobileQuickFilterCruiseTypes[cruiseFilter], removeOption: active })}>
              {cruiseFilter}
            </Button>
          </Grid>);
        })
      }
    </Grid>
  );
};

export default withStyles(styles)(CruiseQuickFilters);
