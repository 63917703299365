/* eslint-disable import/no-unresolved */
// import { assetsBaseUrl, nonImageAssetsBaseUrl } from 'config';
const imageExts = ['png', 'jpg', 'jpeg', 'webp', 'gif'];

const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
const nonImageAssetsBaseUrl = process.env.REACT_APP_NON_IMAGE_ASSETS_BASE_URL;

const withSize = size => {
  try {
    const parsed = assetsBaseUrl.split('/');
    if (parsed.includes('fit-in')) {
      parsed[parsed.length - 1] = size;
      return parsed.join('/');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('image link with resize failed', err);
  }
  return assetsBaseUrl;
};

export default (path, { size } = {}) => {
  const imgLink = !size ? `${assetsBaseUrl}/filters:format(webp)/${path}` :
    `${withSize(size)}/filters:format(webp)/${path}`;
  if (!nonImageAssetsBaseUrl) {
    return imgLink;
  }
  const [ext] = Array.from((path || '').split('.') || []).reverse();
  if (!imageExts.includes(ext)) {
    return `${nonImageAssetsBaseUrl}/${path}`;
  }

  return imgLink;
};
